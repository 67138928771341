import { Field } from 'formik';
import { FormLabel, FormControl, Icon, Box, Text, Flex } from '@chakra-ui/react';
import { BiLock, BiSolidInfoCircle } from 'react-icons/bi';
import PhoneInputWithCountrySelect, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css'

interface FormInputProps {
    label?: string;
    name: string;
    placeholder?: string;
    onBlur?: (e?: any) => any;
    defaultValue?: any;
    isRequired?: boolean;
    isDisabled?: boolean;
    isRow?: boolean;
    isBlockError?: boolean;
}

export const phoneValidationYup = (telefono: string | undefined | null) => {
    if (!telefono) return {
        isValid: true
    }

    const validate = isValidPhoneNumber(telefono)

    return validate ? {
        isValid: true,
    } : {
        isValid: false,
        errorMessage: 'El teléfono debe tener un formato válido.',
    }
}

export function FormPhoneInput({
    name,
    label,
    placeholder,
    isRow = false,
    isBlockError = false,
    onBlur = () => { },
    isDisabled = false,
    isRequired = false,
}: FormInputProps) {

    return (
        <Field name={name}>
            {({ field, form }: { field: any; form: any }) => (
                <FormControl
                    isInvalid={form.errors[name] && form.touched[name]}
                    display={isRow ? "flex" : "block"}
                    flexDirection={isRow ? "row" : "column"}
                >
                    {label &&
                        <FormLabel
                            htmlFor={name}
                            display="flex"
                            gap="3px"
                            color="font"
                            fontSize="14px"
                            fontWeight="400"
                        >
                            {label}
                        </FormLabel>
                    }

                    <Flex
                        pos="relative"
                        opacity={isDisabled ? 0.6 : 1}
                    >
                        <PhoneInputWithCountrySelect
                            defaultCountry="ES"
                            {...field}
                            value={field.value || ''}
                            placeholder={placeholder}
                            onBlur={onBlur}
                            isDisabled={isDisabled}
                            onChange={(value) => {
                                form.setFieldValue(name, value);
                            }}
                            style={{
                                color: "#3D3F65",
                                fontSize: "14px",
                                fontWeight: "400",
                                border: "1px solid",
                                borderColor: "#EAEAEA",
                                borderRadius: "5px",
                                paddingLeft: "16px",
                                paddingRight: "16px",
                                paddingTop: "8px",
                                paddingBottom: "8px",
                                background: "#FFFFFF",
                            }}
                        />

                        {(isDisabled) && (
                            <Icon
                                as={BiLock}
                                boxSize="24px"
                                cursor="not-allowed"
                                position="absolute"
                                top="9px"
                                bottom="9px"
                                right="10px"
                            />
                        )}

                        {isRequired &&
                            <Icon
                                zIndex="999"
                                as={BiSolidInfoCircle}
                                boxSize="15px"
                                color={(form.errors[name] && form.touched[name]) ? "red" : "font"}
                                pos="absolute"
                                right="-7px"
                                top="-7px"
                            />
                        }
                    </Flex>

                    {!isBlockError &&
                        <Box h="18px" w="100%" mt="2px">
                            <Text
                                fontSize="13px"
                                fontWeight="400"
                                color="#EF4E40"
                            >
                                {form.touched[name] && form.errors[name] ? form.errors[name] : ""}
                            </Text>
                        </Box>
                    }
                </FormControl>
            )}
        </Field>
    );
}
