import { Button, Flex, useToast } from '@chakra-ui/react';
import Form from '@rjsf/chakra-ui';
import validator from '@rjsf/validator-ajv8';
import "../../components/ui/cuestionarioStyles.scss"
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuthContext } from '../../../../shared/context/user.context';
import { MatriculasInt } from '../../../../interfaces/MatriculasInt';
import { toastNotify } from '../../../../shared/utils/functions/toastNotify';
import { StatusEnumTypes } from '../../../../shared/utils/types/StatusEnumTypes';
import { handleErrors } from '../../../../shared/utils/functions/handleErrors';
import { schema } from '../../components/utils/SchemaCuestionario';
import { uiSchema } from '../../components/ui/UiCuestionario';
import { useData } from '../../../../shared/hooks/useData';
import { EndpointTypes } from '../../../../shared/utils/types/EndpointTypes';
import { UserRolEnum } from '../../../../shared/utils/types/UserRolEnum';
import { addCuestionarioMatricula } from '../../../../shared/middlewares/cuestionario.middleware';
import { Topbar } from '../../../../shared/components/Topbar/Topbar';

export const Cuestionario = () => {
    const { user } = useAuthContext();
    const toast = useToast();
    const navigate = useNavigate();
    const [search] = useSearchParams();
    const [loading, setLoading] = useState<boolean>(false)
    const [matricula, setMatricua] = useState<MatriculasInt>()
    const { data: matriculas } = useData({
        endpoint: EndpointTypes.MATRICULAS_ALUMNOS,
        ignoreRequest: !user?.auth || user?.role?.nombre !== UserRolEnum.ALUMNO,
    })

    useEffect(() => {
        if (!search.has("grupo") || !search.has("curso")) return;

        matriculas?.data?.map((matricula: MatriculasInt) => {
            if (
                matricula?.grupo?.slug === search.get("grupo") &&
                matricula?.grupo?.curso?.slug === search.get("curso")
            ) {
                setMatricua(matricula)
            }
        })
    }, [matriculas])

    const handleSubmit = (data: any) => {
        const newData = {
            ...data,
            fechaRealizacion: new Date()
        }

        if (!data || !matricula?.id) return;
        setLoading(true)

        addCuestionarioMatricula({
            id: matricula?.id,
            data: newData
        })
            .then(() => {
                toastNotify(toast, StatusEnumTypes.SUCCESS, "Cuestionario enviado con éxito")

                const curso = search.get("curso") || matricula?.grupo?.curso?.slug
                const grupo = search.get("grupo") || matricula?.grupo?.slug

                navigate(`/aprendizaje/${curso}?grupo=${grupo}`)
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
            .finally(() => setLoading(false))
    }

    return (
        <Topbar
            title='Cuestionario'
            searchBar
        >
            <Flex
                p="5% 15%"
                direction='column'
            >
                <Form
                    className='form-cuestionario'
                    schema={schema}
                    uiSchema={uiSchema}
                    validator={validator}
                    onSubmit={(e: any) => handleSubmit(e?.formData)}
                >
                    <Flex
                        mb="20px"
                    >
                        <Button
                            isLoading={loading}
                            m="auto"
                            w="fit-content"
                            h="fit-content"
                            bg="main"
                            p="10px 25px"
                            color="purewhite"
                            fontSize="16px"
                            fontWeight="500"
                            _hover={{ bg: "variant" }}
                            type='submit'
                        >
                            Enviar cuestionario
                        </Button>
                    </Flex>
                </Form>
            </Flex>
        </Topbar>
    )
}