import { Box, Button, Flex, Icon, Text } from "@chakra-ui/react";
import { MODE } from "../Quiz";
import { BiLeftArrowAlt, BiTime } from "react-icons/bi";

interface Props {
    examen: any;
    estado: MODE;
    preguntaActualIndex: number;
    minutes: string;
    seconds: string;
    outExamen: () => void;
    onOpenAlert: () => void;
}

export const HeaderQuiz = ({
    examen,
    estado,
    preguntaActualIndex,
    minutes,
    seconds,
    outExamen,
    onOpenAlert
}: Props) => {
    return (
        <Flex
            w="100%"
            alignItems="center"
            py="18px"
            px="40px"
            gap="24px"
            h="76px"
        >
            <Flex
                direction="column"
            >
                <Button
                    color="pureblack"
                    fontSize="18px"
                    fontWeight="600"
                    lineHeight="28px"
                    bg="#E6E6EA"
                    py="10px"
                    px="16px"
                    cursor="pointer"
                    rounded="14px"
                    w="fit-content"
                    h="fit-content"
                    _hover={{ borderColor: "secondary", bg: "secondary", color: "white" }}
                    onClick={() => {
                        estado === MODE.PROGRESS
                            ? onOpenAlert()
                            : outExamen();
                    }}
                    leftIcon={
                        <Icon
                            as={BiLeftArrowAlt}
                            boxSize={"24px"}
                        />
                    }
                >
                    Volver
                </Button>
            </Flex>

            <Flex
                flex="1"
                direction="column"
                w="100%"
                alignItems="center"
            >
                {(estado === MODE.START || estado === MODE.END) ?
                    <Text
                        fontSize="18px"
                        fontWeight="400"
                    >
                        Certificación - <Text fontWeight="700" as={"span"}>{examen?.nombre}</Text>
                    </Text>
                    :
                    <Flex
                        gap="5px"
                        w="100%"
                        ml="40px"
                    >
                        {Array.from({ length: examen?.preguntas?.length }, (_, i) => i).map((_, index: number) => (
                            <Flex
                                w="100%"
                                alignItems="center"
                                pos="relative"
                            >
                                <Box
                                    roundedTopLeft={index === 0 ? "5px" : "0"}
                                    roundedBottomLeft={index === 0 ? "5px" : "0"}
                                    h="8px"
                                    w="100%"
                                    bg={index > (preguntaActualIndex - 1) ? "#E6E6EA" : "main"}
                                />

                                <Box
                                    display={index === (preguntaActualIndex - 1) ? "block" : "none"}
                                    pos="absolute"
                                    h="24px"
                                    w="24px"
                                    bg="main"
                                    rounded="100%"
                                    right="-10px"
                                    zIndex="99"
                                />
                            </Flex>
                        ))}
                    </Flex>
                }
            </Flex>

            <Flex
                gap="7px"
                alignItems="center"
                justifyContent="end"
                w="120px"
                opacity={estado === MODE.START ? 0 : 1}
            >
                <Icon
                    boxSize="24px"
                    color="font"
                    as={BiTime}
                />

                <Text
                    fontSize="18px"
                    fontWeight="700"
                    color="font"
                >
                    {minutes}:{seconds}
                </Text>
            </Flex>
        </Flex>
    )
}