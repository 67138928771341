import { Box, Flex, Progress, Skeleton, Text } from "@chakra-ui/react"
import { MatriculasInt } from "../../../interfaces/MatriculasInt"
import { format } from "../../../shared/utils/functions/formatDate";

interface Props {
    matricula: MatriculasInt | undefined;
}

export const CardMatriculaProxima = ({
    matricula
}: Props) => {

    return (
        (matricula) ?
            <Flex
                w="50%"
                p="20px"
                border="1px solid"
                borderColor="main"
                rounded="16px"
                bg="purewhite"
                direction="column"
                gap="24px"
                h="255px"
            >
                <Text
                    fontSize="16px"
                    fontWeight="500"
                    color="font"
                >
                    Curso próximo
                </Text>

                <Flex
                    direction="column"
                    gap="14px"
                >
                    <Text
                        color="font"
                        fontSize="20px"
                        fontWeight="600"
                    >
                        La matrícula no ha comenzado
                    </Text>

                    <Text
                        color="#666D80"
                        fontSize="14px"
                        fontWeight="400"
                    >
                        Fecha de inicio:{" "}
                        <Text
                            as="span"
                            color="main"
                        >
                            {format({ date: matricula?.fechaInicio, customFormat: "dd/MM/yyyy" })}
                        </Text>
                    </Text>

                    <Box
                        mt="10px"
                        w="100%"
                        h="1px"
                        border="1px dashed"
                        borderColor="#DFE1E6"
                    />

                    <Text
                        color="#808897"
                        fontSize="14px"
                        fontWeight="500"
                        w="100%"
                        alignItems="center"
                        h="fit-content"
                        py="10px"
                    >
                        ¡Prepárate! La matrícula comenzará pronto. Estás a un paso de iniciar tu nuevo aprendizaje.
                    </Text>
                </Flex>
            </Flex>
            :
            <Skeleton
                w="50%"
                h="255px"
                rounded="16px"
            />
    )
}