import { NotasInt } from "../../interfaces/NotasInt";
import _axios from "../services/http.service";
import tenantInstance from "../services/tenantInstance.service";

const { tenant, client } = await tenantInstance();

export const addNota = async (data: NotasInt) => {

    return await _axios.post(
        `${tenant}/${client}/notas`,
        data,
        {}
    );
};

export const updateNota = async ({ id, data }: { id: string | number, data: NotasInt }) => {

    return await _axios.put(
        `${tenant}/${client}/notas/${id}`,
        data,
        {}
    );
};