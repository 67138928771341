import { Box, Flex, Button, Text, Image, useDisclosure, Link } from '@chakra-ui/react'
import logoProcademy from '../../../assets/img/LogoProcademyBlack.png'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useToast } from '@chakra-ui/react'
import { AxiosResponse } from 'axios'
import { useAuthContext } from '../../../shared/context/user.context'
import { UserRolEnum } from '../../../shared/utils/types/UserRolEnum'
import { Formik, Form as FormikForm } from 'formik';
import * as Yup from "yup"
import { FormInput } from '@imaginagroup/bit-components.ui.form-input';
import tenantInstance from '../../../shared/services/tenantInstance.service'
import { iconoTenant } from '../../../shared/middlewares/tenant.middleware'
import { getToken } from '../../../shared/middlewares/token.middleware'
import { useTenantInfo } from '../../../shared/hooks/useTenantInfo'
import { isRoleAllowed } from '../../../shared/utils/functions/validateRol'
import { toastNotify } from '../../../shared/utils/functions/toastNotify'
import { StatusEnumTypes } from '../../../shared/utils/types/StatusEnumTypes'

export const FormLogin = () => {
    const { login } = useAuthContext();
    const { handleSetInfo, handleGetInfo } = useTenantInfo();
    const navigate = useNavigate();
    const toast = useToast();
    const icono = handleGetInfo({ key: "icono" });
    const nombre = handleGetInfo({ key: "nombre" });
    const colores = handleGetInfo({ key: "colores" });

    const [loading, setLoading] = useState<boolean>(true)
    const [loadingWidth, setLoadingWidth] = useState("0%")

    useEffect(() => {
        setLoading(true)

        iconoTenant()
            .then(async (response) => {
                if (response?.icono && response?.icono !== icono)
                    handleSetInfo({ key: "icono", value: response?.icono })
                if (response?.nombre && response?.nombre !== nombre)
                    handleSetInfo({ key: "nombre", value: response?.nombre })
                if (response?.colores && response?.colores !== colores)
                    handleSetInfo({ key: "colores", value: response?.colores })
            })
            .catch((error) => console.error(error))
            .finally(() => setLoading(false))
    }, [])

    useEffect(() => {
        if (!loading) return;

        let width = 0;
        const interval = setInterval(() => {
            width += 1;
            setLoadingWidth(`${width}%`);

            if (width >= 90) {
                clearInterval(interval);
            }
        }, 50);

        return () => clearInterval(interval);
    }, [loading]);

    useEffect(() => {
        if (loading) return;

        setLoadingWidth("100%");

        setTimeout(() => {
            setLoadingWidth("0%");
        }, 500);
    }, [loading]);

    const initialValues = {
        userId: null,
        password: null
    };

    const validationSchema = Yup.object().shape({
        userId: Yup.string()
            .required("El campo es obligatorio")
            .typeError('El campo es obligatorio'),
        password: Yup.string()
            .required('El campo password es obligatorio')
            .typeError('El campo password es obligatorio'),
    });

    const submitForm = async (values: any) => {
        const { tenant } = await tenantInstance();

        const userLogin = {
            userId: values.userId,
            password: values.password
        }

        getToken(userLogin)
            .then((response: AxiosResponse) => {
                const token = response?.data?.token?.token

                if (isRoleAllowed([UserRolEnum.ADMIN, UserRolEnum.TUTOR, UserRolEnum.ALUMNO], response?.data?.data?.role?.nombre)) {
                    login(token, response?.data?.data, navigate)
                } else if (isRoleAllowed([UserRolEnum.SUPERVISOR], response?.data?.data?.role?.nombre)) {
                    if (tenant === "imagina") return window.location.href = `${import.meta.env.VITE_BASE_ADMIN}/login?loginByCampus=true&token=${token}`;
                    else
                        return window.location.href = `https://${tenant}${import.meta.env.VITE_BASE_ADMIN}/login?loginByCampus=true&token=${token}`;
                } else {
                    toastNotify(toast, StatusEnumTypes.WARNING, "No tiene autorizacion para ingresar!")
                }
            })
            .catch((error) => {
                if (error?.response?.data?.data?.responseText)
                    toastNotify(toast, StatusEnumTypes.ERROR, "El usuario/email o contraseña no son válidos, verifique sus credenciales")
                else toastNotify(toast, StatusEnumTypes.ERROR, "Se ha producido un error en el acceso. Contacte con soporte")
            })
    };

    return (
        <Flex
            mt="auto"
            direction="column"
            alignItems="center"
            gap="30px"
        >
            <Box
                pos={"fixed"}
                top="0"
                bg="#8694FF"
                h="3px"
                w={loadingWidth}
            />

            {loading ?
                <Flex
                    h="65px"
                    rounded="6px"
                />
                :
                (
                    (icono && nombre) ?
                        <Flex
                            alignItems="center"
                            gap="5px"
                        >
                            <Image
                                src={icono}
                                alt='img/logo'
                                objectFit="contain"
                                w="65px"
                                h="65px"
                            />

                            <Text
                                textTransform="capitalize"
                                fontFamily="Poppins"
                                fontSize="22px"
                                color="font"
                                fontWeight="800"
                            >
                                {nombre}
                            </Text>
                        </Flex>
                        :
                        <Image
                            src={logoProcademy}
                            alt='img/logo'
                            objectFit="contain"
                            w="200px"
                            p="0 3%"
                        />
                )
            }

            <Flex
                direction="column"
                alignItems="center"
            >
                <Text
                    fontFamily="Poppins"
                    fontWeight="800"
                    fontSize="44px"
                    color="font"
                >
                    Conectarse a {nombre}
                </Text>

                <Text
                    mt="20px"
                    fontWeight="400"
                    lineHeight="normal"
                    fontSize="14px"
                    color="font"
                >
                    Introduce tus datos para acceder a {nombre}
                </Text>
            </Flex>

            {!loading &&
                <Formik
                    onSubmit={(values) => submitForm(values)}
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                >
                    {(formik) => {
                        const { handleSubmit } = formik;

                        return (
                            <FormikForm
                                onSubmit={handleSubmit}
                                style={{ minWidth: "420px" }}
                            >
                                <Flex
                                    direction="column"
                                    alignItems="center"
                                    gap="5px"
                                >
                                    <FormInput
                                        name='userId'
                                        placeholder="Usuario / Email"
                                    />

                                    <FormInput
                                        type="password"
                                        name='password'
                                        placeholder='Contraseña'
                                    />

                                    <Button
                                        mt="10px"
                                        type='submit'
                                        background='main'
                                        color="#FFFFFF"
                                        fontSize="18px"
                                        fontWeight="800"
                                        rounded="12px"
                                        w="100%"
                                        h="fit-content"
                                        py="11px"
                                        px="25px"

                                    >
                                        Conectarse
                                    </Button>

                                    <Link
                                        mt="25px"
                                        as={RouterLink}
                                        to="/recovery/solicitud"
                                        cursor="pointer"
                                        color="616061"
                                        fontSize="15px"
                                        fontWeight="400"
                                    >
                                        ¿Has olvidado la contraseña?
                                    </Link>
                                </Flex>
                            </FormikForm>
                        );
                    }}
                </Formik>
            }
        </Flex>
    );
};