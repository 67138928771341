import { Button, Flex, Icon, Img, Text, Tooltip, useDisclosure } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { BsDisplay } from "react-icons/bs";
import portadaSinCurso from "../../../../assets/img/portadaSinCurso.png"
import { ExamenInt } from "../../../../interfaces/ExamenInt";
import { Quiz } from "../../../Evaluaciones/views/Quiz/Quiz";
import { useDataId } from "../../../../shared/hooks/useDataId";
import { EndpointTypes } from "../../../../shared/utils/types/EndpointTypes";
import { LiaAwardSolid } from "react-icons/lia";

interface Props {
    examen: ExamenInt;
    disabled: boolean;
    type?: "modulo" | "curso";
    nombre?: string;
    matriculaId: string | undefined;
    aprobado?: boolean;
}

export default function CardExamen({ examen, disabled, type, nombre, matriculaId, aprobado = false }: Props) {
    const { onOpen: onOpenExamen, onClose: onCloseExamen, isOpen: isOpenExamen } = useDisclosure()

    const { data: examenData, loading } = useDataId({
        endpoint: EndpointTypes.EXAMENES,
        id: examen?.slug
    })

    return (
        <>
            <Tooltip
                label={
                    type === "curso" ?
                        "Debes tener al menos un 75% de avance en el curso para rendir el examen."
                        :
                        "Debes completar el módulo para rendir el examen."
                }
                placement="bottom"
                hasArrow
                isDisabled={!disabled}
                py="5px"
                px="10px"
                bg="pureblack"
                color="purewhite"
                fontSize="14px"
                fontWeight="500"
                rounded="6px"
                textAlign="center"
            >
                <Flex
                    direction="column"
                    display="flex"
                    rounded="12px"
                    border="1px solid"
                    borderColor={type === "curso" ? "#E6E6EA" : "main"}
                    onClick={(e: React.MouseEvent) => {
                        e.stopPropagation()

                        if (!disabled && !aprobado) {
                            onOpenExamen()
                        }
                    }}
                    cursor={disabled ? "not-allowed" : "pointer"}
                    transition="all 0.5s"
                    _hover={
                        disabled || aprobado
                            ? {}
                            : { transform: "scale(1.01)" }
                    }
                    bg="purewhite"
                    py={"15px"}
                    px="20px"
                    gap={"15px"}
                    opacity={disabled ? 0.5 : 1}
                    position="relative"
                >
                    <Flex
                        gap="20px"
                    >
                        <Img
                            display={type === "modulo" ? "none" : "block"}
                            boxSize="60px"
                            objectFit="cover"
                            rounded="12px"
                            src={examen?.imagen?.url || examen?.curso?.imagen?.url ? examen?.imagen?.url || examen?.curso?.imagen?.url : portadaSinCurso}
                        />

                        <Flex
                            direction="column"
                            gap={"10px"}
                            w="100%"
                        >
                            <Text
                                fontSize={"14px"}
                                fontWeight="600"
                                color="font"
                            >
                                {examenData?.nombre}
                            </Text>

                            <Flex
                                alignItems="center"
                                gap="8px"
                            >
                                <Icon
                                    as={BsDisplay}
                                    color="font"
                                    boxSize={"16px"}
                                />

                                <Text
                                    color="#959393"
                                    fontSize={"12px"}
                                    fontWeight="400"
                                >
                                    {nombre}
                                </Text>
                            </Flex>
                        </Flex>
                    </Flex>

                    <Tooltip
                        label={"Examen aprobado"}
                        placement="right"
                        hasArrow
                        isDisabled={!aprobado}
                        py="5px"
                        px="10px"
                        bg="main"
                        color="purewhite"
                        fontSize="14px"
                        fontWeight="500"
                        rounded="6px"
                        textAlign="center"
                    >
                        <Flex
                            display={aprobado ? "flex" : "none"}
                            boxSize="40px"
                            bg="main"
                            rounded="full"
                            justifyContent="center"
                            alignItems="center"
                            position="absolute"
                            top="15px"
                            right="15px"
                        >
                            <Icon
                                as={LiaAwardSolid}
                                color="purewhite"
                                boxSize="28px"
                            />
                        </Flex>
                    </Tooltip>
                </Flex>
            </Tooltip >

            {
                isOpenExamen &&
                <Quiz
                    examen={examenData}
                    matriculaId={matriculaId}
                    isOpen={isOpenExamen}
                    onClose={onCloseExamen}
                />
            }
        </>
    )
}
