import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Flex, Icon, Text, Tooltip } from "@chakra-ui/react";
import { CursosInt, LeccionInt } from "../../../interfaces/CursosInt"
import React, { useState } from "react";
import { sortByOrder } from "../../../shared/utils/functions/sortBy";
import { truncate } from "lodash";
import { BiArrowFromRight, BiCertification, BiCheck, BiLockAlt, BiPlay } from "react-icons/bi";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { checkLessonComplete, checkLessonStart, validateProgressModule } from "../../../shared/utils/functions/validateProgress";
import { useProgressContext } from "../../../shared/context/progress.context";
import { UserRolEnum } from "../../../shared/utils/types/UserRolEnum";
import { useAuthContext } from "../../../shared/context/user.context";
import { PiCertificateDuotone } from "react-icons/pi";

interface Props {
    curso: CursosInt;
}

export const AccordionContenido = ({
    curso,
}: Props) => {
    const params = useParams()
    const { user } = useAuthContext()
    const { cursoProgress } = useProgressContext()
    const [inTop, setInTop] = useState<boolean>(false);
    const [contenidoOpen, setContenidoOpen] = useState<boolean>(true)
    const regexNombre = /Tema \d+.*-\s*/;
    const navigate = useNavigate()
    const [search] = useSearchParams()
    const addRedirect = search.has("grupo") ? `?grupo=${search.get("grupo")}` : ""

    const handleScroll = (e: any) => {
        const element = e.target.scrollTop;

        if (element > 0) setInTop(true);
        else setInTop(false);
    }

    return (
        <Flex
            direction="column"
            w={contenidoOpen ? "400px" : "15px"}
            bg="purewhite"
            borderRight="1px solid"
            borderColor="#E6E6EA"
            transition="all 0.5s"
            transform={contenidoOpen ? "translateX(0)" : "translateX(-130px)"}
        >
            <Flex
                px="30px"
                h="74px"
                alignItems="center"
                justifyContent="space-between"
                transition="box-shadow 0.3s"
                boxShadow={inTop ? '0px 4px 18px rgba(18, 22, 37, 0.1)' : 'none'}
                gap="40px"
            >
                <Text
                    fontSize={"14px"}
                    fontWeight="700"
                    color={"font"}
                    transition="all 0.2s"
                    opacity={contenidoOpen ? 1 : 0}
                >
                    Contenido
                </Text>

                <Icon
                    color={contenidoOpen ? "#A5A8B3" : "secondary"}
                    as={BiArrowFromRight}
                    boxSize="28px"
                    cursor="pointer"
                    transition="all 0.5s"
                    transform={contenidoOpen ? 'rotate(0deg)' : 'rotate(-180deg)'}
                    onClick={(e: React.MouseEvent) => {
                        e.stopPropagation()

                        setContenidoOpen((prev: boolean) => !prev)
                    }}
                />
            </Flex>

            <Accordion
                h={"calc(100vh - 74px)"}
                overflow="auto"
                css={{
                    '&::-webkit-scrollbar': {
                        width: '0px',
                    },
                    scrollBehavior: 'smooth',
                }}
                onScroll={handleScroll}
                allowToggle
                w="100%"
                transition="all 0.2s"
                opacity={contenidoOpen ? 1 : 0}
            >
                {sortByOrder(curso?.modulos || []).map((modulo, index) => (
                    <AccordionItem
                        key={index}
                        borderColor="#E6E6EA"
                        isDisabled={!open}
                        w="100%"
                    >
                        {({ isExpanded }) => (
                            <>
                                <AccordionButton
                                    _disabled={{ opacity: "1", cursor: "not-allowed" }}
                                    display="flex"
                                    p="20px 30px"
                                    justifyContent="space-between"
                                    borderBottom={isExpanded ? "1px solid" : "none"}
                                    borderColor="#E6E6EA"
                                >
                                    <Flex
                                        alignItems="center"
                                        gap="10px"
                                        justifyContent="space-between"
                                        w="100%"
                                    >
                                        <Flex
                                            direction="column"
                                            alignItems="start"
                                        >
                                            <Text
                                                color="font"
                                                fontSize="14px"
                                                fontWeight="700"
                                                align="start"
                                            >
                                                {index + 1}. {truncate(modulo?.nombre?.replace(regexNombre, ""), { length: 50 })}
                                            </Text>

                                            <Text
                                                color="#8C909C"
                                                fontSize={"13px"}
                                                fontWeight="400"
                                            >
                                                {modulo?.lecciones?.length} lecciones
                                            </Text>
                                        </Flex>

                                        {user?.role?.nombre === UserRolEnum.ALUMNO && (
                                            validateProgressModule(cursoProgress, modulo) > 99 ?
                                                <Icon
                                                    color="main"
                                                    boxSize="24px"
                                                    as={BiCheck}
                                                />
                                                :
                                                <Icon
                                                    as={BiLockAlt}
                                                    color="#A5A8B3"
                                                    boxSize="22px"
                                                />
                                        )}
                                    </Flex>

                                    <AccordionIcon />
                                </AccordionButton>

                                <AccordionPanel
                                    p="0"
                                >
                                    {sortByOrder(modulo?.lecciones)?.map((leccion: LeccionInt, index: number) => (
                                        <Flex
                                            w="100%"
                                            key={index}
                                            mb="5px"
                                            cursor="pointer"
                                            _hover={{ bg: "main_variant" }}
                                            bgColor={leccion?.slug === params["*"] ? "main_variant" : "inherit"}
                                            onClick={() => navigate(`${leccion?.slug}${addRedirect}`)}
                                        >
                                            <Box
                                                opacity={leccion?.slug === params["*"] ? 1 : 0}
                                                bg="main"
                                                w="4px"
                                            />

                                            <Flex
                                                alignItems="center"
                                                justifyContent="space-between"
                                                w="100%"
                                                pr="20px"
                                            >
                                                <Text
                                                    py="12px"
                                                    px="20px"
                                                    color="font"
                                                    fontSize="14px"
                                                    fontWeight="400"
                                                >
                                                    {leccion?.nombre}
                                                </Text>

                                                {user?.role?.nombre === UserRolEnum.ALUMNO &&
                                                    <Icon
                                                        display={(checkLessonComplete(cursoProgress?.data, leccion?.id) || checkLessonStart(cursoProgress?.data, leccion?.id)) ? "block" : "none"}
                                                        as={checkLessonComplete(cursoProgress?.data, leccion?.id) ? BiCheck : BiPlay}
                                                        boxSize="20px"
                                                        color="main"
                                                    />
                                                }
                                            </Flex>
                                        </Flex>
                                    ))}

                                    {/* {modulo?.examen && user?.role?.nombre === UserRolEnum.ALUMNO &&
                                        <Tooltip
                                            label={"Debes completar el módulo para rendir el examen."}
                                            placement="right"
                                            hasArrow
                                            isDisabled={validateProgressModule(cursoProgress, modulo) > 99}
                                            py="5px"
                                            px="10px"
                                            bg="pureblack"
                                            color="purewhite"
                                            fontSize="14px"
                                            fontWeight="500"
                                            rounded="6px"
                                            textAlign="center"
                                        >
                                            <Flex
                                                w="100%"
                                                key={index}
                                                mb="5px"
                                                cursor={validateProgressModule(cursoProgress, modulo) > 99 ? "pointer" : "not-allowed"}
                                                _hover={{ bg: "main_variant" }}
                                                opacity={validateProgressModule(cursoProgress, modulo) > 99 ? 1 : 0.5}
                                                onClick={(e: React.MouseEvent) => {
                                                    e.stopPropagation()

                                                    if (validateProgressModule(cursoProgress, modulo) > 99) {
                                                        navigate(`examenes/${modulo?.examen?.slug}`)
                                                    }
                                                }}
                                            >
                                                <Box
                                                    opacity="0"
                                                    bg="main"
                                                    w="4px"
                                                />

                                                <Flex
                                                    alignItems="center"
                                                    justifyContent="space-between"
                                                    w="100%"
                                                    pr="20px"
                                                >
                                                    <Text
                                                        py="12px"
                                                        px="20px"
                                                        color="font"
                                                        fontSize="14px"
                                                        fontWeight="400"
                                                    >
                                                        {modulo?.examen?.nombre || "Examen del módulo"}
                                                    </Text>

                                                    {user?.role?.nombre === UserRolEnum.ALUMNO &&
                                                        <Icon
                                                            as={PiCertificateDuotone}
                                                            boxSize="20px"
                                                            color="main"
                                                        />
                                                    }
                                                </Flex>
                                            </Flex>
                                        </Tooltip>
                                    } */}
                                </AccordionPanel>
                            </>
                        )}
                    </AccordionItem>
                ))}
            </Accordion>
        </Flex>
    )
}