import { Box, Flex, Text } from "@chakra-ui/react"
import { HorarioLaboralSelect } from "../../../../shared/components/Customs/HorarioLaboral/HorarioLaboralSelect"
import { useState } from "react";
import { JornadaLaboral as JornadaLaboralType } from "../../../../shared/utils/types/TipoJornadaLaboralEnum";

interface Props {
    value: JornadaLaboralType | null | undefined;
    setValue: (value?: JornadaLaboralType) => void;
}

export const JornadaLaboral = ({
    value,
    setValue
}: Props) => {
    const [isChecked, setIsChecked] = useState<boolean>(false);

    return (
        <Flex
            direction="column"
            gap="24px"
            w="100%"
        >
            <Text
                color="font"
                fontSize="18px"
                fontWeight="700"
            >
                Información jornada laboral
            </Text>

            <Box>
                <HorarioLaboralSelect
                    onChangeSelect={setValue}
                    defaultValueSelect={value}
                    setIsChecked={setIsChecked}
                    isChecked={isChecked}
                />
            </Box>
        </Flex>
    )
}