import { Route, Routes } from 'react-router-dom'
import Matriculas from './views/Table/Index'

export default function MatriculasRoutes() {
    return (
        <Routes>
            <Route
                index
                element={<Matriculas />}
            />
        </Routes>
    )
}
