import { UpdateProgressInt } from "../../interfaces/ProgressInt";
import _axios from "../services/http.service";
import tenantInstance from "../services/tenantInstance.service";

export const uploadProgress = async (data: UpdateProgressInt) => {
    const { tenant, client } = await tenantInstance();

    return await _axios.post(
        `${tenant}/${client}/progresos`,
        data,
        {}
    );
};