import { Route, Routes } from "react-router-dom";
import Aprendizaje from "./views/Index/Index";
import { InformationCurso } from "./views/Information/Information";


export default function AprendizajeRoutes() {
    return (
        <Routes>
            <Route
                index
                element={<Aprendizaje />}
            />

            <Route
                path='/:slug'
                element={<InformationCurso />}
            />
        </Routes>
    )
}
