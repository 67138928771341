import { useEffect, useState } from "react";
import { ProgressContext } from "../context/progress.context";
import { UserRolEnum } from "../utils/types/UserRolEnum";
import { useAuthContext } from "../context/user.context";
import { isRoleAllowed } from "../utils/functions/validateRol";
import { useData } from "../hooks/useData";
import { EndpointTypes } from "../utils/types/EndpointTypes";

export const ProgressController = ({ children, ...props }: any) => {
  const { user } = useAuthContext();

  const [cursoProgress, setCursoProgress] = useState<any>({
    matriculaId: null,
    cursoId: null,
    cursoNombre: null,
    porcentajeCurso: null,
    totalLecciones: null,
    data: null,
  })

  const { data: progreso, Refresh } = useData({
    endpoint: EndpointTypes.PROGRESOS,
    query: {
      curso: cursoProgress?.cursoId,
      matricula: cursoProgress?.matriculaId
    },
    ignoreRequest: !cursoProgress?.cursoId || !cursoProgress?.matriculaId
  })

  const refreshProgress = async () => {
    if (!cursoProgress?.cursoId || !cursoProgress?.matriculaId) return

    return await Refresh()
  };

  useEffect(() => {

    if (isRoleAllowed([UserRolEnum.ADMIN, UserRolEnum.ALUMNO], user?.role?.nombre))
      if (progreso?.data && progreso?.data?.length !== 0) {
        setCursoProgress((prev: any) => ({
          ...prev,
          porcentajeCurso: (progreso?.data[0]?.leccionesCompletadas / prev?.totalLecciones) * 100,
          data: progreso?.data[0]?.progresoLecciones,
        }))
      } else {
        setCursoProgress((prev: any) => ({
          ...prev,
          porcentajeCurso: 0,
        }))
      }

  }, [progreso?.data])

  return (
    <ProgressContext.Provider value={{ cursoProgress, setCursoProgress, refreshProgress }} >
      {children}
    </ProgressContext.Provider>
  );
}