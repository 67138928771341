import { useEffect, useState } from "react";
import { CursosInt, LeccionInt } from "../../interfaces/CursosInt"
import { UserRolEnum } from "../utils/types/UserRolEnum";
import { useProgressContext } from "../context/progress.context";
import { useAuthContext } from "../context/user.context";
import { useNavigate, useSearchParams } from "react-router-dom";
import { LeccionModeEnum, LeccionTipoEnum } from "../utils/types/LeccionTipoEnum";
import { toastNotify } from "../utils/functions/toastNotify";
import { StatusEnumTypes } from "../utils/types/StatusEnumTypes";
import { useToast } from "@chakra-ui/react";
import { EntregableEstadoEnum } from "../utils/types/EntregableEstadoEnum";
import { MatriculasInt } from "../../interfaces/MatriculasInt";
import { uploadProgress } from "../middlewares/progress.middleware";
import { checkLessonComplete } from "../utils/functions/validateProgress";

interface Props {
    leccion: LeccionInt;
    curso: CursosInt;
    matriculaActual: MatriculasInt | undefined;
}

export const useLeccionActions = ({ leccion, curso, matriculaActual }: Props) => {
    const navigate = useNavigate();
    const toast = useToast()
    const { user } = useAuthContext();
    const { cursoProgress, refreshProgress } = useProgressContext();
    const [isCompleted, setIsCompleted] = useState<boolean | null>(false);
    const [nextIsBlocked, setNextIsBlocked] = useState<boolean>(false);
    const [prevIsBlocked, setPrevIsBlocked] = useState<boolean>(false);
    const [mode, setMode] = useState<LeccionModeEnum>(LeccionModeEnum.CARGANDO);
    const [estadoEntregable, setEstadoEntregable] = useState<EntregableEstadoEnum>(EntregableEstadoEnum.PENDIENTE_ENTREGA);
    const [endPrev, setEndPrev] = useState<boolean>(true);
    const [search] = useSearchParams()
    const addRedirect = search.has("grupo") ? `?grupo=${search.get("grupo")}` : ""

    // Si es la primer leccion del curso bloqueamos el boton de leccion anterior
    useEffect(() => {
        onPrevLeccion(false, curso)
    }, [leccion?.id, curso])

    // Cada vez que cambie el estado del entregable desde una lección entregable,
    useEffect(() => {
        checkBlockedByEstadoEntregable();
    }, [estadoEntregable, leccion?.tipo]);

    //Detectamos si la lección está completada 
    useEffect(() => {
        if (!leccion) return;

        if (checkLessonComplete(cursoProgress?.data, leccion?.id)) {
            setIsCompleted(true)
        } else {
            setIsCompleted(false)
        }

    }, [leccion, cursoProgress]);

    useEffect(() => {
        if (matriculaActual && matriculaActual?.grupo?.fundae === false) return setEndPrev(true)

        if (!leccion || !curso) return setEndPrev(false);

        if (checkPrevLessonComplete(leccion, curso)) {
            setEndPrev(true)
        } else {
            setEndPrev(false)
        }

    }, [cursoProgress, curso, matriculaActual, leccion]);

    useEffect(() => {
        const nextLeccion: LeccionInt | undefined | 'end' = getNextLeccion(curso);

        nextLeccion === 'end'
            ? setMode(LeccionModeEnum.END)
            : isCompleted
                ? setMode(LeccionModeEnum.SIGUIENTE)
                : setMode(LeccionModeEnum.BLOQUEADA);

    }, [isCompleted, curso]);

    const checkPrevLessonComplete = (leccion: LeccionInt, curso: CursosInt) => {
        let prevLeccion: any;

        curso?.modulos?.find((modulo: any, index: number) => {
            if (leccion?.moduloId === modulo?.id) {
                if (leccion?.id === modulo?.lecciones[0]?.id) {
                    const prevModulo = curso?.modulos ? curso?.modulos[index - 1] : undefined;

                    if (prevModulo)
                        prevLeccion = prevModulo?.lecciones ? prevModulo?.lecciones[prevModulo.lecciones.length - 1] : undefined;

                } else {
                    const leccionIndex = modulo.lecciones?.findIndex((l: any) => l?.id === leccion?.id);

                    if (leccionIndex > -1) prevLeccion = modulo.lecciones[leccionIndex - 1];
                }
            }
        });

        if (!prevLeccion) {
            return true
        }

        if (prevLeccion) {
            return checkLessonComplete(cursoProgress?.data, prevLeccion?.id)
        }
    };

    const onLeccionStarted = async () => {
        if (user?.role?.nombre === UserRolEnum.TUTOR || user?.role?.nombre === UserRolEnum.ADMIN) return

        if (user?.id && leccion?.modulo?.cursoId && leccion?.id && leccion?.moduloId)
            if (!cursoProgress?.data[leccion?.id] && cursoProgress?.matriculaId) {
                await uploadProgress({
                    matriculaId: cursoProgress?.matriculaId,
                    cursoId: leccion?.modulo?.cursoId,
                    moduloId: leccion?.moduloId,
                    leccion: {
                        id: leccion?.id,
                        estado: "acceso",
                        segundos: 0,
                    }
                }).then(() => refreshProgress())
                    .catch((error) => console.log(error))
            }
    };

    const onLeccionCompleted = async (leccion: LeccionInt) => {
        if (user?.role?.nombre === UserRolEnum.TUTOR || user?.role?.nombre === UserRolEnum.ADMIN) return

        if (user?.id && leccion?.modulo?.cursoId && leccion?.id && leccion?.moduloId)
            if (cursoProgress?.matriculaId) {
                await uploadProgress({
                    matriculaId: cursoProgress?.matriculaId,
                    cursoId: leccion?.modulo?.cursoId,
                    moduloId: leccion?.moduloId,
                    leccion: {
                        id: leccion?.id,
                        estado: "finalizacion",
                        segundos: 0,
                    }
                }).then(() => {
                    setIsCompleted(true);
                    refreshProgress();

                }).catch((error) => console.log(error))
            }
    };

    // Obtenemos la leccion anterior
    const onPrevLeccion = (redirec = true, curso: CursosInt) => {
        if (!leccion || !curso) return;

        let prevLeccion: LeccionInt | undefined;

        curso?.modulos?.some((modulo: any, index: number) => {
            // Buscamos el modulo de la leccion
            if (leccion?.moduloId === modulo.id) {

                if (leccion?.id === modulo.lecciones[0].id) {
                    // Si es la primera leccion del modulo seleccionamos el anterior
                    const prevModulo = curso?.modulos ? curso?.modulos[index - 1] : undefined;

                    if (prevModulo) {
                        prevLeccion = prevModulo?.lecciones ? prevModulo?.lecciones[prevModulo.lecciones.length - 1] : undefined;
                    }
                } else {
                    // Si no es la primera seleccionamos la anterior
                    const leccionIndex = modulo.lecciones?.findIndex((l: LeccionInt) => l.id === leccion.id);

                    if (leccionIndex > -1) {
                        prevLeccion = modulo.lecciones[leccionIndex - 1];
                    }
                }
                return true; // Terminamos la búsqueda una vez que encontramos el módulo correcto
            }
            return false;
        });

        // Actualizamos el estado después de buscar la lección anterior
        if (!prevLeccion) {
            setPrevIsBlocked(true);
        } else {
            setPrevIsBlocked(false);
            if (nextIsBlocked) setNextIsBlocked(false);
        }

        if (prevLeccion && redirec) {
            navigate(`/aprendizaje/${curso?.slug}/lecciones/${prevLeccion?.slug}${addRedirect}`);
        }
    };

    const alertExamen = ({
        curso,
        onAlert
    }: {
        curso: CursosInt;
        onAlert: (type: 'modulo' | 'curso') => void;
    }): void => {
        if (!leccion) return;

        curso?.modulos?.find((modulo: any, index: number) => {
            if (leccion?.moduloId === modulo?.id) {
                if (leccion?.id === modulo.lecciones[modulo.lecciones?.length - 1].id) {
                    const nextModulo = curso?.modulos && index + 1 < curso?.modulos?.length ? curso?.modulos[index + 1] : undefined;

                    if (nextModulo) {
                        // Si es la última lección del modulo y hay un examen, mostramos la alerta del modulo
                        if (modulo?.examen) onAlert('modulo');

                        return;
                    } else {
                        // Si hay un examen en el modulo y en el curso mostramos la alerta de ambos
                        // if (curso?.examen && modulo?.examen) onAlert('both');
                        // Si solo hay un examen en el curso mostramos la alerta del curso
                        if (curso?.examen) onAlert('curso');

                        return;
                    }
                }
            }
        });
    }


    //Devuelve la siguiente lección o 'end' si no hay más lecciones.
    const getNextLeccion = (curso: CursosInt): LeccionInt | undefined | 'end' => {
        if (!leccion) return undefined;

        let nextLeccion: LeccionInt | undefined | 'end';

        curso?.modulos?.find((modulo: any, index: number) => {
            //Buecamos el modulo de la leccion
            if (leccion?.moduloId === modulo?.id) {

                // Si es la última lección del módulo, pasamos al siguiente módulo.
                if (leccion?.id === modulo.lecciones[modulo.lecciones?.length - 1].id) {

                    // Si es la última lección del modulo, volvemos a setear el feedback a leccion para el siguiente modulo
                    const nextModulo = curso?.modulos && index + 1 < curso?.modulos?.length ? curso?.modulos[index + 1] : undefined;

                    if (nextModulo) {

                        nextLeccion = nextModulo?.lecciones ? nextModulo?.lecciones[0] : undefined;
                        return;
                    }
                    else {
                        // Si es la última lección del curso, lo damos por terminado.
                        nextLeccion = 'end'
                        return;
                    }
                } else {
                    // Pasamos a la siguiente.
                    const indexFind = modulo.lecciones?.findIndex((l: any) => l.id === leccion?.id);

                    if (indexFind > -1) nextLeccion = modulo.lecciones[indexFind + 1];
                }
            }

            if (nextLeccion === 'end') return true;
            if (prevIsBlocked && nextLeccion) setPrevIsBlocked(false);

            return nextLeccion !== undefined;
        });

        return nextLeccion;
    };

    // Navegamos A La Siguiente Leccion 
    const onNextLeccion = async (curso: CursosInt) => {
        setMode(LeccionModeEnum.CARGANDO);

        const nextLeccion: LeccionInt | undefined | 'end' = getNextLeccion(curso);

        if (nextLeccion === 'end') {
            if (leccion?.id) {
                toastNotify(toast, StatusEnumTypes.SUCCESS, '¡Enhorabuena, Has terminado el curso!');
                setMode(LeccionModeEnum.END);

                onLeccionCompleted(leccion);
            }
        } else if (isCompleted) {
            setMode(LeccionModeEnum.SIGUIENTE);

            navigate(`/aprendizaje/${curso?.slug}/lecciones/${nextLeccion?.slug}${addRedirect}`);
        } else if (!isCompleted && !!nextLeccion) {
            if (leccion?.id) {
                onLeccionCompleted(leccion);
                setNextIsBlocked(false)
            }
        } else {
            setMode(LeccionModeEnum.SIGUIENTE);
        }
    };

    // Terminamos el curso navegamos al inicio y marcamos la ultima leccion como completada
    const onEndCurso = async (curso: CursosInt) => {
        if (!isCompleted) {
            onLeccionCompleted(leccion);
        }

        navigate(`/aprendizaje/${curso?.slug}${addRedirect}`);
    }

    // Devolvemos TRUE si bloqueamos la siguiente lección hasta completar ejercicio.
    const checkBlockedByEstadoEntregable = () => {
        // Si la lección es del tipo Entregable y no la ha completado, no pasamos a la siguiente.
        const testEntregable = leccion?.tipo === LeccionTipoEnum.ENTREGABLE && estadoEntregable === EntregableEstadoEnum.PENDIENTE_ENTREGA;


        if (testEntregable) {
            // Bloqueamos el botón para que no puedan seguir.
            setNextIsBlocked(true);

            return true;
        } else {
            // Desbloqueamos el botón
            setNextIsBlocked(false);

            return false;
        }
    };

    return {
        mode,
        setEstadoEntregable,
        endPrev,
        onLeccionStarted,
        onNextLeccion,
        onEndCurso,
        prevIsBlocked,
        nextIsBlocked,
        onPrevLeccion,
        alertExamen
    }
}
