import { CircularProgress, CircularProgressLabel, Flex, SkeletonCircle, Text } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { useTenantInfo } from '../../../../shared/hooks/useTenantInfo'
import { MatriculasInt } from "../../../../interfaces/MatriculasInt";
import { DateTime } from "luxon";


interface Props {
    stats: MatriculasInt;
}

export const CardContador = ({ stats }: Props) => {
    const { handleGetInfo } = useTenantInfo();
    const colores = handleGetInfo({ key: "colores" });

    const [time, setTime] = useState<{
        time: any
        months: any
        days: any
        hours: any
        minutes: any
        seconds: any
    }>()
    const diasTotalesGrupo = stats?.fechaInicio && stats?.fechaFin
        ? DateTime.fromJSDate(new Date(stats?.fechaFin)).diff(DateTime.fromJSDate(new Date(stats?.fechaInicio)), 'days').as('days')
        : 0;

    const diasAvanzadosGrupo = stats?.fechaInicio
        ? DateTime.now().diff(DateTime.fromJSDate(new Date(stats?.fechaInicio)), 'days').as('days')
        : 0;

    useEffect(() => {
        if (!stats?.fechaFin) return
        const t = getCount(stats.fechaFin);

        setTime(t);

    }, [stats])

    const getCount = (fechaFin: any) => {
        if (!fechaFin) return;

        const inicio: any = new Date();
        const fin: any = new Date(fechaFin);
        const time: any = (fin - inicio + 1000) / 1000;

        const months: number = Math.floor(time / (30 * 24 * 60 * 60));
        const days: number = Math.floor((time % (30 * 24 * 60 * 60)) / (24 * 60 * 60));
        const hours: number = Math.floor((time % (24 * 60 * 60)) / (60 * 60));
        const minutes: number = Math.floor((time % (60 * 60)) / 60);
        const seconds: number = Math.floor(time % 60);

        return {
            time,
            months,
            days,
            hours,
            minutes,
            seconds
        };
    };

    return (
        <Flex
            direction="column"
            gap="20px"
            bg="purewhite"
            p="20px"
            borderRadius="20px"
            border="1px solid"
            borderColor="border"
            boxShadow="4.417px 8.835px 22.308px 0px rgba(192, 192, 192, 0.35)"
        >
            <Text
                color="font"
                fontSize="14px"
                fontWeight="600"
            >
                Tu curso finalizará en:
            </Text>

            <Flex
                gap="20px"
            >

                {time ?
                    <CircularProgress capIsRound value={(diasAvanzadosGrupo / diasTotalesGrupo) * 100} color={colores?.main ? colores?.main : "#8694FF"} trackColor="#EAECFF" size="75px">
                        <CircularProgressLabel
                            fontSize="20px"
                            fontWeight="600"
                            lineHeight="90%"
                        >
                            {time?.months > 0 ? time?.months : 0}

                            <Text
                                fontSize="10px"
                                fontWeight="400"
                            >
                                {time?.months == "1" ? "MES" : "MESES"}

                            </Text>
                        </CircularProgressLabel>
                    </CircularProgress>
                    :

                    <SkeletonCircle size="75px" />}

                {time ?
                    <CircularProgress capIsRound value={(diasAvanzadosGrupo / diasTotalesGrupo) * 100} color={colores?.main ? colores?.main : "#8694FF"} trackColor="#EAECFF" size="75px">
                        <CircularProgressLabel
                            fontSize="20px"
                            fontWeight="600"
                            lineHeight="90%"
                        >
                            {time?.days > 0 ? time?.days : 0}

                            <Text
                                fontSize="10px"
                                fontWeight="400"
                            >
                                {time?.days == "1" ? "DÍA" : "DÍAS"}

                            </Text>
                        </CircularProgressLabel>
                    </CircularProgress>
                    : <SkeletonCircle size="75px" />}

                {time ?
                    <CircularProgress capIsRound value={(diasAvanzadosGrupo / diasTotalesGrupo) * 100} color={colores?.main ? colores?.main : "#8694FF"} trackColor="#EAECFF" size="75px">
                        <CircularProgressLabel
                            fontSize="20px"
                            fontWeight="600"
                            lineHeight="90%"
                        >
                            {time?.hours > 0 ? time?.hours : 0}

                            <Text
                                fontSize="10px"
                                fontWeight="400"
                            >
                                {time?.hours == "1" ? "HORA" : "HORAS"}

                            </Text>
                        </CircularProgressLabel>
                    </CircularProgress>
                    : <SkeletonCircle size="75px" />}
            </Flex>
        </Flex >
    )
}