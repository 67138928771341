import { Box, Text, VStack, Spinner } from "@chakra-ui/react";

export const LoadingOverlay = () => {
  return (
    <Box
      position="fixed"
      top="0"
      left="0"
      right="0"
      bottom="0"
      display="flex"
      alignItems="center"
      justifyContent="center"
      bg="rgba(255, 255, 255, 0.8)"
    >
      <VStack spacing={4}>
        <Spinner size="xl" color="teal.500" thickness="4px" />
        <Text fontSize="xl" fontWeight="bold">Cargando...</Text>
      </VStack>
    </Box>
  );
};