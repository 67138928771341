import { useDisclosure } from "@chakra-ui/react";
import { useEffect } from "react";

export const useOutExamen = () => {
    const { isOpen: outWindow, onOpen: onOpenWindow, onClose: onCloseWindow } = useDisclosure();
    const { isOpen: alert, onOpen: onOpenAlert, onClose: onCloseAlert } = useDisclosure();

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.hidden) {
                onOpenWindow();
            }
        };

        const handleWindowBlur = () => onOpenWindow();

        const handleWindowLeave = (e: any) => {
            const mouseY = e.clientY;
            const topValue = 0;

            if (mouseY < topValue && !outWindow) onOpenAlert();
        };

        window.addEventListener('mouseout', handleWindowLeave, false);
        window.addEventListener('blur', handleWindowBlur, false);
        window.addEventListener('visibilitychange', handleVisibilityChange, false);

        return () => {
            window.removeEventListener('mouseout', handleWindowLeave);
            window.removeEventListener('blur', handleWindowBlur);
            window.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);

    return { outWindow, alert, onCloseWindow, onCloseAlert }
}; 