import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from "@fullcalendar/interaction"
import { Drawer, DrawerBody, DrawerContent, DrawerOverlay } from '@chakra-ui/react'
import esLocale from '@fullcalendar/core/locales/es';
import "./CalendarioDrawer.scss"
import { useData } from '../../hooks/useData';
import { EndpointTypes } from '../../utils/types/EndpointTypes';
import { UserRolEnum } from '../../utils/types/UserRolEnum';
import { useAuthContext } from '../../context/user.context';
import { useNavigate } from 'react-router-dom';

interface Props {
    isOpen: boolean;
    onClose: () => void;
}

export default function CalendarioDrawer({ isOpen, onClose }: Props) {
    const { user } = useAuthContext()
    const navigate = useNavigate()
    const handleDateClick = (arg: any) => {
        alert(arg.dateStr)
    }

    const getColorForIndex = (index: number) => {
        const colors = ['#A8D5E2', '#A8E2D0', '#C7CDFF', '#D0E2A8', '#E2D0A8'];
        return colors[index % colors.length];
    };

    const { data: matriculas } = useData({
        endpoint: EndpointTypes.MATRICULAS_ALUMNOS,
        ignoreRequest: !user?.auth || user?.role?.nombre !== UserRolEnum.ALUMNO,
    });

    const eventosMatriculas = matriculas?.data?.map((matricula: any, index: number) => ({
        title: `${matricula?.grupo?.curso?.nombre}`,
        start: matricula.fechaInicio,
        end: matricula.fechaFin,
        color: getColorForIndex(index),
        extendedProps: {
            grupo: {
                nombre: matricula?.grupo?.nombre,
                curso: {
                    slug: matricula?.grupo?.curso?.slug
                }
            }
        }
    })) || [];

    const handleEventClick = (info: any) => {
        const grupo = info?.event?.extendedProps?.grupo;

        if (grupo && grupo?.curso && grupo?.curso?.slug) {
            const courseSlug = grupo?.curso?.slug;
            const courseUrl = `/aprendizaje/${courseSlug}?grupo=${grupo?.nombre}`;
            navigate(courseUrl)
        } else {
            console.error("La información del curso no está disponible.");
        }
    };

    return (
        <Drawer
            isOpen={isOpen}
            placement='right'
            onClose={onClose}
            size="lg"
        >
            <DrawerOverlay />
            <DrawerContent>
                <DrawerBody
                    p="20px"
                >
                    <FullCalendar
                        plugins={[dayGridPlugin, interactionPlugin]}
                        initialView="dayGridMonth"
                        weekends={false}
                        events={[
                            ...eventosMatriculas
                        ]}
                        dateClick={handleDateClick}
                        eventClick={handleEventClick}
                        locale={esLocale}
                        displayEventTime={false}
                    />
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    )
}
