import _axios from "../services/http.service";
import tenantInstance from "../services/tenantInstance.service";
import { EndpointTypes } from "../utils/types/EndpointTypes";

export const getData = async (
    endpoint: string,
    track: {
        track: boolean,
        matricula: string | undefined
    } = {
            track: false,
            matricula: undefined
        }
) => await _axios.get(
    endpoint,
    (track?.track && track?.matricula !== undefined)
        ? { headers: { "track": true, "matricula": track?.matricula } }
        : {}
).then((response: any) => response)

export const loadData = async ({
    value,
    endpoint,
    query,
    specificQuery = null,
    limit = 100,
}: {
    value: string;
    endpoint: EndpointTypes;
    query: string;
    specificQuery?: string | null;
    limit?: number;
}) => {
    const { tenant, client } = await tenantInstance();

    let newQuery = ""

    if (specificQuery) newQuery = `?${specificQuery}`
    if ((value && value !== "") && specificQuery) newQuery = newQuery + `&${query}` + value
    else if ((value && value !== "") && !specificQuery) newQuery = `?${query}` + value

    if (newQuery === "") newQuery = `?limit=${limit}`
    else newQuery = newQuery + `&limit=${limit}`

    const { data } = await getData(
        tenant + "/" + client + endpoint + newQuery
    )

    return data?.data?.data.map((item: any) => ({
        value: item.id,
        label: item.nombre,
        data: item
    })) || [];
}
