// React 
import { Navigate, Outlet } from "react-router-dom";
// Hook
import { useAuthContext } from "../../context/user.context";
import { useUrl } from "../../context/url.context";
import { UserRolEnum } from "../../utils/types/UserRolEnum";

interface Props {
    isRoleAllowed: boolean;
}

export const RequireAuth = ({ isRoleAllowed = true }: Props) => {
    const { user } = useAuthContext();
    const url = useUrl();

    return (
        !user?.auth
            ? <Navigate to={"/login"} />
            : (user?.auth && isRoleAllowed)
                ?
                <Outlet />
                :
                <Navigate
                    to={user?.role?.nombre === UserRolEnum.TUTOR ? "/correcciones" : "/dashboard"}
                />
    )
};