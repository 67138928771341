import { createContext, useContext } from 'react';

export const OutWindowContext = createContext<{
  outWindow: boolean;
  onClose: () => void;
  setTimeModal: (time: number) => void;
}>({
  outWindow: false,
  onClose: () => {},
  setTimeModal: () => {},
});

export const useOutWindowContext = () => {
  return useContext(OutWindowContext);
}