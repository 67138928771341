import { Flex, Icon, Text } from "@chakra-ui/react"
import SelectorCurso from "./SelectorCurso"
import { MatriculasInt } from "../../../interfaces/MatriculasInt";
import { RiBookMarkLine } from "react-icons/ri";
import { BiTime } from "react-icons/bi";

interface Props {
    onSelectMatricula: (matricula: MatriculasInt) => void;
    matriculas: MatriculasInt[];
    selectedMatricula: MatriculasInt | undefined;
    totalEntregables: number;
    entregablesAprobados: number;
}
export const HeaderEntregables = ({
    onSelectMatricula,
    matriculas,
    selectedMatricula,
    totalEntregables,
    entregablesAprobados
}: Props) => {
    return (
        <Flex
            h="fit-content"
            w="100%"
            alignItems="start"
            justifyContent="space-between"
            gap="10px"
        >
            <SelectorCurso
                onSelectMatricula={onSelectMatricula}
                matriculas={matriculas}
                selectedMatricula={selectedMatricula}
            />

            <Flex
                alignItems="center"
                gap="12px"
            >
                <Flex
                    h="fit-content"
                    bg="purewhite"
                    border="1px solid"
                    borderColor="#E6E6EA"
                    rounded="20px"
                    py="12px"
                    px="18px"
                    gap="20px"
                >
                    <Flex
                        p="10px"
                        bg="variant"
                        rounded="10px"
                        h="fit-content"
                    >
                        <Icon
                            as={RiBookMarkLine}
                            boxSize="32px"
                            color="main"
                        />
                    </Flex>

                    <Flex
                        gap="5px"
                        justifyContent="flex-start"
                        direction="column"
                    >
                        <Text
                            color="#8C909C"
                            fontWeight="700"
                            fontSize="14px"
                        >
                            Nota Media General
                        </Text>

                        <Text
                            fontSize="18px"
                            fontWeight="700"
                            color="font"
                        >
                            {
                                selectedMatricula?.puntuacionMedia || selectedMatricula?.puntuacionMedia === 0
                                    ? selectedMatricula?.puntuacionMedia
                                    : "-"
                            }
                        </Text>
                    </Flex>
                </Flex>

                <Flex
                    h="fit-content"
                    bg="purewhite"
                    border="1px solid"
                    borderColor="#E6E6EA"
                    rounded="20px"
                    py="12px"
                    px="18px"
                    gap="20px"
                >
                    <Flex
                        p="10px"
                        bg="variant"
                        rounded="10px"
                        h="fit-content"
                    >
                        <Icon
                            as={BiTime}
                            boxSize="32px"
                            color="main"
                        />
                    </Flex>

                    <Flex
                        gap="5px"
                        justifyContent="flex-start"
                        direction="column"
                    >
                        <Text
                            color="#8C909C"
                            fontWeight="700"
                            fontSize="14px"
                        >
                            Entregables Aprobados
                        </Text>

                        <Text
                            fontSize="18px"
                            fontWeight="700"
                            color="font"
                        >
                            {entregablesAprobados}/{totalEntregables}
                        </Text>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    )
}