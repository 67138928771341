import { Button, Heading, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text } from "@chakra-ui/react";

interface Props {
    onClose: () => void;
    isOpen: boolean;
}

export const OutWindowModal = (props: Props) => {
    const { isOpen, onClose } = props;
    
    return (
        <Modal onClose={onClose} isOpen={isOpen} isCentered closeOnOverlayClick={false}>
            <ModalOverlay />

            <ModalContent>
                <ModalHeader>
                    <Heading fontSize="25px" textAlign="center">
                        ¿Sigues en el campus?
                    </Heading>
                </ModalHeader>

                <ModalBody p="20px 30px">
                    <Text fontSize="17px" textAlign="center">
                        Llevas 10 minutos sin interactuar con el campus, responde para evitar que se cierre la sesión.
                    </Text>
                </ModalBody>

                <ModalFooter>
                    <Button
                        w="80%"
                        m="auto"
                        bg="#000"
                        color="#FFF"
                        p="15px 25px"
                        rounded="12px"
                        fontSize="15px"
                        fontWeight="500px"
                        lineHeight="18px"  
                        _hover={{
                            opacity: 0.8,
                        }}
                        onClick={onClose}             
                        >
                        Estoy aquí
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}