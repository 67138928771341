import { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalOverlay, ModalContent, Text, ModalBody, Icon, ModalCloseButton, Flex, Box, Skeleton } from "@chakra-ui/react";
import { BsPersonCheckFill } from "react-icons/bs";
import { useData } from "../../../../shared/hooks/useData";
import { EndpointTypes } from "../../../../shared/utils/types/EndpointTypes";
import { MatriculasInt } from "../../../../interfaces/MatriculasInt";
import { addAsistencia, updateAsistencia } from "../../../../shared/middlewares/asistencias.middleware";
import { LeccionInt } from "../../../../interfaces/CursosInt";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    leccion: LeccionInt | null;
}

export const ModalAsistencias = ({ isOpen, onClose, leccion }: Props) => {
    const [inProgress, setInProgress] = useState(false);

    const { data: matriculas, loadingM } = useData({
        endpoint: EndpointTypes.MATRICULAS_TUTOR,
        query: {
            curso: leccion?.modulo?.cursoId!
        },
        ignoreRequest: !leccion?.modulo?.cursoId
    })

    const { data, loadingA, Refresh: refreshAsistencias } = useData({
        endpoint: EndpointTypes.ASISTENCIAS,
        query: {
            leccionId: leccion?.id!
        },
        ignoreRequest: !leccion?.id
    })

    const [asistencias, setAsistencias] = useState<{ [key: string]: boolean }>({});

    useEffect(() => {
        const newAsistencias: { [key: string]: boolean } = {};

        if (data && matriculas) {
            matriculas?.data?.forEach((m: MatriculasInt) => {
                data?.data?.forEach((a: any) => {
                    if (a?.asistentes) {
                        Object.entries(a?.asistentes).forEach(([key, value]) => {
                            if (key === m?.userId && m?.grupoId === a?.grupoId) {
                                newAsistencias[key] = value as boolean;
                            }
                        })
                    }
                })
            })
        }

        setAsistencias(newAsistencias);
    }, [data, matriculas])

    const handleAsistencia = async (id: string, asistencia: boolean) => {
        setAsistencias((prev) => {
            const newAsistencias = { ...prev };

            if (prev[id] === asistencia) delete newAsistencias[id];
            else newAsistencias[id] = asistencia;

            return newAsistencias;
        });

        const grupoId = matriculas?.data?.find((m: MatriculasInt) => m?.userId === id)?.grupoId;

        let asistenciaFind: any | null = null;

        if (data && data?.meta?.total !== 0) {
            const asistencia = data?.data?.find((a: any) => a?.grupoId === grupoId);

            if (asistencia) {
                asistenciaFind = asistencia;
            }
        }

        setInProgress(true);

        if (asistenciaFind) {
            await updateAsistencia({
                asistenciaId: asistenciaFind?.id,
                leccionId: leccion?.id!,
                grupoId: grupoId,
                asistencias: {
                    ...asistenciaFind?.asistentes,
                    [id]: asistencia
                }
            })
                .then(() => refreshAsistencias())
                .catch((error: any) => console.log(error))
                .finally(() => setInProgress(false));
        } else {
            await addAsistencia({
                leccionId: leccion?.id!,
                grupoId: grupoId,
                asistencias: {
                    [id]: asistencia
                }
            })
                .then(() => refreshAsistencias())
                .catch((error: any) => console.log(error))
                .finally(() => setInProgress(false));
        }
    };

    const handleClose = () => {
        onClose();
    }

    return (
        <Modal
            isOpen={isOpen}
            onClose={handleClose}
            isCentered
            size="2xl"
            closeOnOverlayClick={false}
        >
            <ModalOverlay />

            <ModalContent
                rounded="14px"
                maxH="95vh"
            >
                <ModalHeader
                    roundedTop="14px"
                    bg="pureblack"
                    px="20px"
                    py="12px"
                    display="flex"
                    alignItems="center"
                    gap="12px"
                >
                    <Icon
                        as={BsPersonCheckFill}
                        color="purewhite"
                        boxSize="24px"
                    />

                    <Text
                        fontSize="14px"
                        fontWeight="700"
                        color="purewhite"
                        letterSpacing="-0.14px"
                    >
                        Asistencias de alumnos
                    </Text>

                    <ModalCloseButton
                        color="purewhite"
                    />
                </ModalHeader>

                <ModalBody
                    overflowY="auto"
                    overflowX="hidden"
                    css={{
                        '&::-webkit-scrollbar': {
                            w: '7px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            borderRadius: '20px',
                            bg: `light_grey`,
                        },
                    }}
                    p="0"
                    mb="20px"
                    display="flex"
                    flexDirection="column"
                >
                    <Flex
                        px="30px"
                        py="15px"
                        fontSize="13px"
                        fontWeight="700"
                        lineHeight="16px"
                        letterSpacing="-0.078px"
                        color="font"
                        gap="20px"
                    >
                        <Text
                            w="350px"
                        >
                            Alumno
                        </Text>

                        <Text
                            w="140px"
                        >
                            Presente
                        </Text>

                        <Text
                            w="140px"
                        >
                            Ausente
                        </Text>
                    </Flex>

                    {matriculas?.data?.map((m: MatriculasInt) => (
                        <Flex
                            key={m?.userId}
                            px="30px"
                            py="10px"
                            gap="20px"
                        >
                            <Flex
                                w="350px"
                                direction="column"
                            >
                                <Text
                                    fontSize="16px"
                                    fontWeight="600"
                                    lineHeight="125%"
                                    letterSpacing="-0.16px"
                                    color="font"
                                >
                                    {m?.user?.nombreCompleto}
                                </Text>

                                <Text
                                    fontSize="13px"
                                    fontWeight="500"
                                    lineHeight="16px"
                                    letterSpacing="-0.078px"
                                    color="#8C909C"
                                >
                                    {m?.user?.email}
                                </Text>
                            </Flex>

                            <Flex
                                w="140px"
                                alignItems="center"
                            >
                                {(loadingA && loadingM || inProgress) ?
                                    <Skeleton
                                        rounded="50%"
                                        boxSize="24px"
                                        ml="15px"
                                    />
                                    :
                                    <Flex
                                        ml="15px"
                                        as="button"
                                        w="24px"
                                        h="24px"
                                        borderRadius="50%"
                                        border="3px solid"
                                        cursor="pointer"
                                        alignItems="center"
                                        justifyContent="center"
                                        borderColor={asistencias[m?.userId!] === true ? "success" : "#A5A8B3"}
                                        onClick={() => handleAsistencia(m?.userId!, true)}
                                    >
                                        {asistencias[m?.userId!] === true && (
                                            <Box
                                                w="12px"
                                                h="12px"
                                                borderRadius="50%"
                                                bg="success"
                                            />
                                        )}
                                    </Flex>
                                }
                            </Flex>

                            <Flex
                                w="140px"
                                alignItems="center"
                            >
                                {(loadingA && loadingM || inProgress) ?
                                    <Skeleton
                                        rounded="50%"
                                        boxSize="24px"
                                        ml="14px"
                                    />
                                    :
                                    <Flex
                                        ml="14px"
                                        as="button"
                                        w="24px"
                                        h="24px"
                                        borderRadius="50%"
                                        border="3px solid"
                                        cursor="pointer"
                                        alignItems="center"
                                        justifyContent="center"
                                        borderColor={asistencias[m?.userId!] === false ? "fail" : "#A5A8B3"}
                                        onClick={() => handleAsistencia(m?.userId!, false)}
                                    >
                                        {asistencias[m?.userId!] === false && (
                                            <Box
                                                w="12px"
                                                h="12px"
                                                borderRadius="50%"
                                                bg="fail"
                                            />
                                        )}
                                    </Flex>
                                }
                            </Flex>
                        </Flex>
                    ))}
                </ModalBody>
            </ModalContent >
        </Modal >
    )
}
