import { As, Flex, Icon, Text } from "@chakra-ui/react";
import { PiGraduationCap, PiCertificate } from "react-icons/pi";
import { BiBookAlt } from "react-icons/bi";

export enum TypeProgresoEnum {
    ACTIVIDADES,
    EJERCICIOS,
    NOTA,
}

interface Props {
    typeProgreso: TypeProgresoEnum;
}

export const CardProgresosSinMatricula = ({
    typeProgreso,
}: Props) => {

    const data: Record<TypeProgresoEnum, {
        label: string;
        icon: As;
    }> = {
        [TypeProgresoEnum.ACTIVIDADES]: {
            label: "Actividades completas",
            icon: PiGraduationCap
        },
        [TypeProgresoEnum.EJERCICIOS]: {
            label: "Ejercicios completados",
            icon: PiCertificate
        },
        [TypeProgresoEnum.NOTA]: {
            label: "Nota media",
            icon: BiBookAlt
        },
    }

    return (
        <Flex
            p="12px"
            bg={"#F8F9FB"}
            rounded="12px"
            gap="12px"
            alignItems="center"
            flex="1"
        >
            <Flex
                h="fit-content"
                w="fit-content"
                p="8px"
                bg="main"
                rounded="8px"
            >
                <Icon
                    as={data[typeProgreso]?.icon}
                    boxSize="25px"
                    color="purewhite"
                />
            </Flex>

            <Flex
                direction="column"
            >
                <Text
                    color="font"
                    fontSize="20px"
                    fontWeight={"600"}
                >
                    --
                </Text>

                <Text
                    color="#666D80"
                    fontSize="14px"
                    fontWeight="400"
                    letterSpacing="-0.5px"
                >
                    {data[typeProgreso]?.label}
                </Text>
            </Flex>
        </Flex>
    )
}