import { Flex, Text } from "@chakra-ui/react";
import { MatriculasInt } from "../../../interfaces/MatriculasInt"
import { CardTiempo, TypeTimeEnum } from "./utils/CardTiempo";

interface Props {
    matricula: MatriculasInt | undefined;
}

export const CardContador = ({
    matricula,
}: Props) => {
    return (
        <Flex
            p="20px"
            border="1px solid"
            borderColor="#DFE1E6"
            rounded="16px"
            bg="purewhite"
            gap="24px"
            direction="column"
            h="356px"
        >
            <Text
                color="font"
                fontSize="16px"
                fontWeight="500"
            >
                Fecha de finalización
            </Text>

            <Flex
                direction="column"
                gap="10px"
            >
                <CardTiempo
                    matricula={matricula}
                    typeTime={TypeTimeEnum.MONTHS}
                />

                <CardTiempo
                    matricula={matricula}
                    typeTime={TypeTimeEnum.DAYS}
                />

                <CardTiempo
                    matricula={matricula}
                    typeTime={TypeTimeEnum.HOURS}
                />
            </Flex>
        </Flex>
    )
}