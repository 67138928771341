import { Route, Routes } from "react-router-dom"
import { Cuestionario } from "./views/Index/Index"

export const CuestionarioRoutes = () => {
    return (
        <Routes>
            <Route
                index
                element={
                    <Cuestionario />
                }
            />
        </Routes>
    )
}