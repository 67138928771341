import { Flex, Image, Text } from "@chakra-ui/react"
import cardContadorFinalizado from "../../../assets/img/cardContadorFinalizado.png"

export const CardCursoProximo = () => {
    return (
        <Flex
            p="20px"
            border="1px solid"
            borderColor="#DFE1E6"
            rounded="16px"
            bg="purewhite"
            gap="24px"
            direction="column"
            alignItems="center"
            justifyContent="center"
            h="356px"
        >
            <Text
                color="font"
                fontSize="21px"
                fontWeight="600"
                textAlign="center"
            >
                ¡Curso aún no ha comenzado!
            </Text>

            <Image
                src={cardContadorFinalizado}
                w="160px"
                h="175px"
            />
        </Flex>
    )
}