export enum CarpetaMensajeria {
    ENTRADA = "bandeja_entrada",
    ARCHIVO = "archivo",
    PAPELERA = "papelera",
    FAVORITO = "favorito"
}

export enum EstadoMensaje {
    LEIDO = "leido",
    NO_LEIDO = "no_leido"
}