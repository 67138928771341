import Select from 'react-select';
import { FormLabel, Box, Icon, Flex } from '@chakra-ui/react';
import { formSelectStyles } from '../../../ui/Select/formSelectStyles';
import { BiInfoCircle } from 'react-icons/bi';

interface Props {
    value: any;
    setValue: any;
    label?: string;
    placeholder?: string;
    options?: any[];
    isMulti?: boolean;
    isSearchable?: boolean;
    isRequired?: boolean;
    isDisabled?: boolean;
    isClearable?: boolean;
    isOptionDisabled?: any;
    labelStyle?: React.CSSProperties;
    noHead?: boolean;
}

export const CustomSelect = ({
    value, 
    setValue,
    label,
    options,
    placeholder,
    isMulti = false,
    isRequired = false,
    isSearchable = true,
    isDisabled = false,
    isClearable = false,
    isOptionDisabled,
    labelStyle = {
        fontSize: "14px",
        fontWeight: "400"
    },
    noHead = false
}: Props) => {
    return (
        <Flex direction="column" w="100%">
            {!noHead &&
                <FormLabel
                    display="flex"
                    gap="3px"
                    color="dark_blue"
                    style={labelStyle}
                >
                    {label}
                </FormLabel>
            }

            <Box
                pos="relative"
                w="100%"
            >
                <Select
                    isMulti={isMulti}
                    options={options}
                    styles={formSelectStyles}
                    isDisabled={isDisabled}
                    isClearable={isClearable}
                    placeholder={placeholder}
                    isSearchable={isSearchable}
                    closeMenuOnSelect={!isMulti}
                    isOptionDisabled={isOptionDisabled}
                    onChange={setValue}
                    value={value}
                />

                {isRequired &&
                    <Icon
                        zIndex="999"
                        as={BiInfoCircle}
                        boxSize="15px"
                        color="dark_blue"
                        pos="absolute"
                        right="-7px"
                        top="-7px"
                    />
                }
            </Box>
        </Flex>
    );
};