import { Flex, Image, Text } from '@chakra-ui/react'
import avatarDefault from "../../../../assets/img/Avatar.png"
import { TutorInt } from '../../../../interfaces/TutorInt'

interface Props {
    tutor: TutorInt | undefined;
}

export default function CardTutor({ tutor }: Props) {
    return (
        <Flex
            direction="column"
            gap="16px"
        >
            <Text
                fontSize="14px"
                fontWeight="700"
                color="font"
            >
                Tutor del curso
            </Text>

            <Flex
                gap="12px"
            >
                <Flex
                    rounded="full"
                    w="45px"
                    h="45px"
                >
                    <Image
                        src={avatarDefault}
                        alt="avatar"
                        objectFit="cover"
                    />
                </Flex>

                <Flex
                    direction="column"
                    gap="5px"
                >
                    <Text
                        fontSize="16px"
                        fontWeight="700"
                        color="font"
                    >
                        {tutor?.nombre}
                    </Text>

                    <Text
                        fontSize="14px"
                        fontWeight="400"
                        color="font_grey"
                    >
                        {tutor?.apellidos}
                    </Text>
                </Flex>
            </Flex>
        </Flex>
    )
}
