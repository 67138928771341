import { Button, Flex, Icon, Image, Spinner, Text, useToast } from '@chakra-ui/react'
import { BiDownload, BiMenu } from 'react-icons/bi'
import { HiOutlineClock } from 'react-icons/hi'
import { CursosInt } from '../../../../interfaces/CursosInt'
import MarkdownPreview from '@uiw/react-markdown-preview';
import { MatriculasInt } from '../../../../interfaces/MatriculasInt';
import { handleErrors } from '../../../../shared/utils/functions/handleErrors';
import { toastNotify } from '../../../../shared/utils/functions/toastNotify';
import { StatusEnumTypes } from '../../../../shared/utils/types/StatusEnumTypes';
import { getGuiaDidactica } from '../../../../shared/middlewares/users.middleware';

interface Props {
    curso: CursosInt;
}

export default function InfoCursoTutor({ curso }: Props) {

    return (
        <Flex
            direction="column"
            gap="25px"
        >
            <Flex
                direction="column"
                gap="20px"
            >
                <Flex
                    alignItems="center"
                    gap="20px"
                >
                    <Flex
                        p="12px"
                        bg="purewhite"
                        rounded="20px"
                        border="3px solid"
                        borderColor="#E6E6EA"
                        w="100px"
                        h="100px"
                    >
                        {curso?.icono?.url ?
                            <Image
                                w="full"
                                objectFit="cover"
                                src={curso?.icono?.url ? curso?.icono?.url : ""}
                            />
                            :
                            <Flex
                                bg="grey"
                                w="full"
                                h="70px"
                                rounded="10px"
                            />
                        }

                    </Flex>

                    <Flex
                        direction="column"
                        gap="10px"
                    >
                        <Text
                            fontSize="14px"
                            fontWeight="600"
                            color="font"
                        >
                            CURSO
                        </Text>

                        <Text
                            fontSize="24px"
                            fontWeight="700"
                            color="font"
                        >
                            {curso?.nombre}
                        </Text>
                    </Flex>
                </Flex>

                <Flex
                    gap="12px"
                    align="center"
                >
                    <Flex
                        gap="5px"
                        align="center"
                    >
                        <Icon
                            as={BiMenu}
                            boxSize="20px"
                            color="font"
                        />

                        <Text
                            fontSize="14px"
                            fontWeight="400"
                            color="font_grey"
                        >
                            {curso?.modulos?.length} módulos
                        </Text>
                    </Flex>

                    <Flex
                        gap="5px"
                        align="center"
                    >
                        <Icon
                            as={HiOutlineClock}
                            boxSize="20px"
                            color="font"
                        />

                        <Text
                            fontSize="14px"
                            fontWeight="400"
                            color="font_grey"
                        >
                            {curso?.duracion} h
                        </Text>
                    </Flex>
                </Flex>
            </Flex>

            <MarkdownPreview
                source={curso?.descripcion || ''}
                style={{
                    fontSize: '15px',
                    fontWeight: "400",
                    color: "#121625",
                    lineHeight: "140%",
                    width: '100%',
                    maxWidth: '100%',
                    backgroundColor: "transparent"
                }}
                wrapperElement={{ "data-color-mode": "light" }}
            />
        </Flex>
    )
}
