import { Route, Routes } from "react-router-dom";
import Foro from "./views/Index/Index";
import Pregunta from "./views/InformationPregunta/Pregunta";


export default function ForoRoutes() {
  return (
    <Routes>
      <Route
        index
        element={<Foro />}
      />

      <Route
        path="/:preguntaId"
        element={<Pregunta />}
      />
    </Routes>
  )
}
