import { Flex, Icon, Image, Text } from "@chakra-ui/react";
import { ProgressBar } from "../../../../shared/components/Progress/ProgressBar";
import { BiTrophy } from "react-icons/bi";
import { CursosInt } from "../../../../interfaces/CursosInt";

interface Props {
    curso: CursosInt;
    progreso: number | null;
}

export default function CardProgresoCurso({ curso, progreso }: Props) {

    return (
        <Flex
            w="fit"
        >
            <Flex
                p="12px"
                bg="purewhite"
                rounded="20px"
                border="3px solid"
                borderColor="#E6E6EA"
                mr="-12px"
                zIndex="10"
                w="100px"
                h="100px"
            >
                {curso?.icono?.url ?
                    <Image
                        w="full"
                        objectFit="cover"
                        src={curso?.icono?.url ? curso?.icono?.url : ""}
                        rounded="8px"
                    />
                    :
                    <Flex
                        bg="grey"
                        w="full"
                        rounded="10px"
                    />
                }

            </Flex>

            <Flex
                bg="purewhite"
                p="20px"
                rounded="20px"
                border="1px solid"
                borderColor="border"
                my="5px"
            >
                <Flex
                    direction="column"
                    gap="5px"
                >
                    <Flex
                        w="full"
                        justify="space-between"
                        align="center"
                        gap="20px"
                    >
                        <Text
                            fontSize="21px"
                            fontWeight="700"
                            color="font"
                        >
                            {(progreso || 0).toFixed(0)}% <Text as="span" color="font_grey" fontSize="13px" fontWeight="700">DEL CURSO COMPLETADO</Text>
                        </Text>

                        <Icon
                            boxSize="20px"
                            color="dark_grey"
                            as={BiTrophy}
                        />
                    </Flex>

                    <ProgressBar
                        bg="#F0F0F3"
                        bgProgress="main"
                        height="8px"
                        progress={parseInt((progreso || 0).toFixed(0))}
                    />
                </Flex>
            </Flex>
        </Flex>
    )
}
