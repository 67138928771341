import { Route, Routes } from "react-router-dom"
import { Perfil } from "./views/Index/Index"

export const PerfilRoutes = () => {
    return (
        <Routes>
            <Route
                path="/*"
                element={
                    <Perfil />
                }
            />
        </Routes>
    )
}