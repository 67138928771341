import { Route, Routes } from "react-router-dom"
import { CursosPublicos } from "./views/Index/Index"
import { InformationCursoPublico } from "./views/InformationCursoPublico/InformationCursoPublico"

export const CursosPublicosRouter = () => {
    return (
        <Routes>
            <Route
                index
                element={<CursosPublicos />}
            />

            <Route
                path="/:slug"
                element={<InformationCursoPublico />}
            />
        </Routes>
    )
}