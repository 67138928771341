import { Flex, Button, Icon, Tooltip, useDisclosure } from "@chakra-ui/react";
import { BiLeftArrowAlt, BiRightArrowAlt, BiCheck } from "react-icons/bi";
import { CursosInt, LeccionInt } from "../../../interfaces/CursosInt";
import { LeccionModeEnum, LeccionTipoEnum } from "../../../shared/utils/types/LeccionTipoEnum";
import { ModalAlertModulo } from "../../../shared/components/Modals/ModalAlertModulo";
import { useEffect } from "react";
import { ModalAlertCurso } from "../../../shared/components/Modals/ModalAlertCurso";

interface Props {
    onPrevLeccion: (redirec: boolean, curso: CursosInt) => void;
    onNextLeccion: (curso: CursosInt) => void;
    onEndCurso: (curso: CursosInt) => void;
    prevIsBlocked: boolean;
    nextIsBlocked: boolean;
    mode: LeccionModeEnum;
    dataCurso: CursosInt;
    loading: boolean;
    endPrev: boolean;
    leccion: LeccionInt;
    alertExamen: ({
        curso,
        onAlert
    }: {
        curso: CursosInt;
        onAlert: (type: 'modulo' | 'curso') => void;
    }) => void;
}

export const FooterLeccion = ({
    onPrevLeccion,
    onNextLeccion,
    onEndCurso,
    prevIsBlocked,
    nextIsBlocked,
    mode,
    dataCurso,
    endPrev,
    loading,
    leccion,
    alertExamen
}: Props) => {
    const {
        onOpen: onOpenAlertModulo,
        onClose: onCloseAlertModulo,
        isOpen: isOpenAlertModulo
    } = useDisclosure();

    const {
        onOpen: onOpenAlertCurso,
        onClose: onCloseAlertCurso,
        isOpen: isOpenAlertCurso
    } = useDisclosure();

    const onAlert = (type: 'modulo' | 'curso') => {
        type === 'modulo' ? onOpenAlertModulo() : onOpenAlertCurso()
    }

    return (
        <Flex
            w="100%"
            gap="20px"
            align="center"
            justify="space-between"
        >
            <Button
                h="fit-content"
                w="fit-content"
                py="15px"
                px="24px"
                bg="#E6E6EA"
                rounded="14px"
                color="font"
                fontSize="18px"
                fontWeight="700"
                onClick={() => onPrevLeccion(true, dataCurso)}
                isDisabled={prevIsBlocked}
                leftIcon={<Icon as={BiLeftArrowAlt} boxSize="24px" />}
            >
                Lección anterior
            </Button>

            <Tooltip
                hasArrow
                rounded="5px"
                padding="10px"
                textAlign="center"
                fontSize="16px"
                label={
                    leccion?.tipo === LeccionTipoEnum.ENTREGABLE
                        ? "Debes subir el ejercicio para continuar"
                        : "No puedes completar esta lección sin antes completar las anteriores"
                }
                isDisabled={!nextIsBlocked && endPrev}
            >
                <Button
                    h="fit-content"
                    w="fit-content"
                    py="15px"
                    px="24px"
                    rounded="14px"
                    onClick={(e: React.MouseEvent) => {
                        e.stopPropagation()

                        mode === LeccionModeEnum.END
                            ? onEndCurso(dataCurso)
                            : onNextLeccion(dataCurso)

                        mode !== LeccionModeEnum.BLOQUEADA && alertExamen({ curso: dataCurso, onAlert })
                    }}
                    isDisabled={loading || nextIsBlocked || !endPrev}
                    _hover={{ opacity: '0.8' }}
                    isLoading={mode === LeccionModeEnum.CARGANDO}
                    bg="secondary"
                    color="purewhite"
                    fontSize="18px"
                    fontWeight="700"
                >
                    {mode === LeccionModeEnum.BLOQUEADA ?
                        <Flex
                            align="center" gap="13px">
                            Marcar como completada
                            <Icon as={BiCheck} boxSize="24px" />
                        </Flex>
                        : mode === LeccionModeEnum.SIGUIENTE ?
                            <Flex align="center" gap="13px">
                                Siguiente Lección
                                <Icon as={BiRightArrowAlt} boxSize="24px" />
                            </Flex>
                            :
                            <Flex align="center" gap="13px">
                                Finalizar
                                <Icon as={BiCheck} boxSize="24px" />
                            </Flex>
                    }
                </Button>
            </Tooltip>

            {isOpenAlertModulo && <ModalAlertModulo onClose={onCloseAlertModulo} isOpen={isOpenAlertModulo} />}
            {isOpenAlertCurso && <ModalAlertCurso onClose={onCloseAlertCurso} isOpen={isOpenAlertCurso} />}
        </Flex >
    );
};