import { useEffect, useState } from "react";

declare global {
    interface Window {
        loadliveworksheet?: (id: number, code: string, lang: number, domain: string, mode: string) => void;
    }
}

interface WorksheetData {
    id: number;
    code: string;
    lang: number;
    domain: string;
    mode: string;
}

interface Props {
    url: string;
}

export const LiveWorksheet = ({ url }: Props) => {
    const [worksheetData, setWorksheetData] = useState<WorksheetData | null>(null);

    useEffect(() => {
        const extractDataFromUrl = (url: string): WorksheetData | null => {
            const regex = /loadliveworksheet\((\d+),\s*'(\w+)',\s*(\d+),\s*'(\w+)',\s*'(\w+)'\)/;
            const match = url.match(regex);
            if (match) {
                const [, id, code, lang, domain, mode] = match;
                return { id: parseInt(id), code, lang: parseInt(lang), domain, mode };
            }
            return null;
        };

        setWorksheetData(extractDataFromUrl(url));
    }, [url]);

    useEffect(() => {
        if (!worksheetData) return;

        const loadScript = () => {
            const script = document.createElement('script');
            script.src = "https://www.liveworksheets.com/embed/embed.js?v=1";
            script.async = true;
            document.body.appendChild(script);

            script.onload = () => {
                if (window.loadliveworksheet) {
                    const { id, code, lang, domain, mode } = worksheetData;
                    window.loadliveworksheet(id, code, lang, domain, mode);
                }
            };

            const lwsOuterDivId = `lwsouterdiv${worksheetData?.id}`;
            const outerDiv = document.getElementById(lwsOuterDivId);
            if (outerDiv) {
                outerDiv.style.setProperty('z-index', 'auto');
            }

            return () => {
                document.body.removeChild(script);
            };
        };

        const cleanup = loadScript();
        return cleanup;
    }, [worksheetData]);

    if (!worksheetData) return null;

    return (
        <div id={`liveworksheet${worksheetData?.id}`} style={{ width: '90%' }}>
            <span id={`lwslink${worksheetData?.id}`} />
        </div>
    );
};
