import { Flex, Skeleton, useToast } from "@chakra-ui/react";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { EntregablesInt } from "../../../interfaces/Entregables";
import { MatriculasInt } from "../../../interfaces/MatriculasInt";
import { LeccionInt } from "../../../interfaces/CursosInt";
import { EntregableEstadoEnum, ModeEnumEntregable } from "../../../shared/utils/types/EntregableEstadoEnum";
import { useData } from "../../../shared/hooks/useData";
import { EndpointTypes } from "../../../shared/utils/types/EndpointTypes";
import { EnunciadoItem } from "./EntregableItems/Enunciado";
import { addEntregable } from "../../../shared/middlewares/entregables.middleware";
import { toastNotify } from "../../../shared/utils/functions/toastNotify";
import { StatusEnumTypes } from "../../../shared/utils/types/StatusEnumTypes";
import { EntregadoItem } from "./EntregableItems/Entregado";
import { ComponenteLeccionEnum, LeccionTipoEnum } from "../../../shared/utils/types/LeccionTipoEnum";
import { UserRolEnum } from "../../../shared/utils/types/UserRolEnum";
import { useAuthContext } from "../../../shared/context/user.context";
import { EnunciadoTutor } from "./EntregableItems/EnunciadoTutor";
import { handleErrors } from "../../../shared/utils/functions/handleErrors";

interface Props {
  leccion: LeccionInt;
  setEstadoEntregable: (estado: EntregableEstadoEnum) => void;
  endPrev: boolean;
  matriculaActual: MatriculasInt | undefined;
  componentSubtype: ComponenteLeccionEnum | undefined;
  onNextLeccion: () => void;
}

export const Entregable = ({ leccion, setEstadoEntregable, endPrev, matriculaActual, componentSubtype, onNextLeccion }: Props) => {
  const { user } = useAuthContext()
  const toast = useToast();
  const [loadingEntrega, setLoadingEntrega] = useState<boolean>(false)

  const { data: entregables, loading } = useData({
    endpoint: EndpointTypes.ENTREGABLES,
    query: {
      leccionId: leccion?.id!,
      matriculaId: matriculaActual?.id!,
      sortBy: "createdAt",
      order: "desc"
    },
    ignoreRequest: !leccion?.id || !matriculaActual?.id
  })
  const [entregable, setEntregable] = useState<EntregablesInt | null>(null)
  const [mode, setMode] = useState<ModeEnumEntregable>();

  useEffect(() => {
    if (loading || !entregables?.data || leccion?.tipo !== LeccionTipoEnum.ENTREGABLE) return

    if (entregables?.data?.length !== 0) {
      const _entregable = entregables?.data[0];

      setEntregable(_entregable)
      setEstadoEntregable(_entregable?.estado)
    } else {
      setEntregable(null)
      setMode(ModeEnumEntregable.ENTREGA);
      setEstadoEntregable(EntregableEstadoEnum.PENDIENTE_ENTREGA)
    }

  }, [leccion, entregables])

  useEffect(() => {
    if (entregable) {
      if (entregable?.estado === EntregableEstadoEnum.PENDIENTE_ENTREGA) setMode(ModeEnumEntregable.ENTREGA);
      if (entregable?.estado === EntregableEstadoEnum.PENDIENTE_CORRECCION) setMode(ModeEnumEntregable.CORRECCION);
      if (entregable?.estado === EntregableEstadoEnum.ERROR || entregable?.estado === EntregableEstadoEnum.CORRECTO) setMode(ModeEnumEntregable.FINALIZADO);
    }

  }, [entregable]);

  const realizarEntrega = async ({
    file,
    setFile,
    value,
    setValue
  }: {
    file: File | null,
    setFile: Dispatch<SetStateAction<File | null>>,
    value: string,
    setValue: Dispatch<SetStateAction<string>>
  }) => {
    if (!matriculaActual) return
    setLoadingEntrega(true)

    const entregableData = {
      matriculaId: matriculaActual?.id,
      leccionId: leccion?.id,
      moduloId: leccion?.moduloId,
      cursoId: leccion?.modulo?.cursoId,
      adjunto: file || undefined,
      texto: value
    }

    addEntregable({
      data: entregableData
    })
      .then((response: any) => {
        setEntregable(response?.data?.data);
        setFile(null);
        setValue("");
        mode === ModeEnumEntregable.ENTREGA && onNextLeccion()

        setEstadoEntregable(EntregableEstadoEnum.PENDIENTE_CORRECCION)
        setMode(ModeEnumEntregable.CORRECCION);
      })
      .catch((error) => {
        const errors = handleErrors(
          error?.response?.data?.errors,
          error?.response?.status
        )

        errors?.map((error) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))

        toast.closeAll()
      })
      .finally(() => setLoadingEntrega(false))
  }

  return (
    user?.role?.nombre === UserRolEnum.ALUMNO &&
      (mode === undefined || mode === null) ?
      <Skeleton
        w="100%"
        h="calc(100vh - 193px)"
        rounded="20px"
      />
      :
      <Flex
        boxSize="100%"
        direction="column"
      >
        {(user?.role?.nombre === UserRolEnum.TUTOR || user?.role?.nombre === UserRolEnum.ADMIN) &&
          <EnunciadoTutor
            leccion={leccion}
            componentSubtype={componentSubtype}
          />
        }

        {(mode === ModeEnumEntregable.ENTREGA) && user?.role?.nombre === UserRolEnum.ALUMNO &&
          <EnunciadoItem
            leccion={leccion}
            endPrev={endPrev}
            realizarEntrega={realizarEntrega}
            componentSubtype={componentSubtype}
            loading={loadingEntrega}
          />
        }


        {(mode === ModeEnumEntregable.CORRECCION || mode === ModeEnumEntregable.FINALIZADO) && user?.role?.nombre === UserRolEnum.ALUMNO &&
          <EntregadoItem
            leccion={leccion}
            entregable={entregable}
            entregables={entregables?.data}
            endPrev={endPrev}
            realizarEntrega={realizarEntrega}
            componentSubtype={componentSubtype}
            loading={loadingEntrega}
          />
        }
      </Flex>
  );
};