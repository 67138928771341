import { Button, Flex, Icon, Text } from '@chakra-ui/react'
import { BiPlus, BiSolidPlusCircle } from 'react-icons/bi'

interface Props {
    description: string;
    buttonText: string;
    onOpen: () => void;
    noBorder?: boolean
}

export default function CardCreacion({ description, buttonText, onOpen, noBorder }: Props) {
    return (
        <Flex
            h="fit-content"
            border={noBorder ? "" : "1px dashed"}
            borderColor="#E6E6EA"
            bg="purewhite"
            py="24px"
            px="20px"
            gap="20px"
            alignItems="center"
            justifyContent="center"
            rounded="10px"
            direction="column"
            w="315px"
        >
            <Flex
                bg="#F8F8F9"
                rounded="13px"
                w="100%"
                py="14px"
                justifyContent="center"
                onClick={onOpen}
                cursor="pointer"
            >
                <Icon
                    as={BiSolidPlusCircle}
                    boxSize="28px"
                    color="main"
                />
            </Flex>

            <Text
                textAlign="center"
                color="#8C909C"
                fontSize="15px"
                fontWeight="400"
                lineHeight="150%"
            >
                {description}
            </Text>

            <Button
                leftIcon={
                    <Icon
                        as={BiPlus}
                        boxSize="22px"
                    />
                }
                color="font"
                fontSize="13px"
                fontWeight="700"
                lineHeight="16px"
                letterSpacing="-0.078"
                h="fit-content"
                w="fit-content"
                px="10px"
                py="10px"
                bg="transparent"
                border="1px solid"
                borderColor={"#F0F0F3"}
                rounded="10px"
                onClick={onOpen}
            >
                {buttonText}
            </Button>
        </Flex>
    )
}
