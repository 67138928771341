import _axios from "../services/http.service";
import tenantInstance from "../services/tenantInstance.service";

export const addCuestionarioMatricula = async ({
    id,
    data
}: {
    id: string;
    data: any;
}) => {
    const { tenant, client } = await tenantInstance();

    return await _axios.put(
        `${tenant}/${client}/matriculas/${id}`,
        { cuestionario: data },
        {}
    ).then((response: any) => response);
};