import { Flex, Image, Skeleton, Text } from "@chakra-ui/react"
import bgImage from "../../../assets/img/cardFundae.png"
import { useData } from "../../../shared/hooks/useData"
import { EndpointTypes } from "../../../shared/utils/types/EndpointTypes"
import { MatriculasInt } from "../../../interfaces/MatriculasInt"

interface Props {
    matricula: MatriculasInt | undefined;
}

export const CardForo = ({ matricula }: Props) => {
    const { data: preguntas, loading } = useData({
        endpoint: EndpointTypes.PREGUNTAS,
        query: {
            grupo: matricula?.grupoId!
        },
        ignoreRequest: !matricula
    })

    return (
        (matricula && !loading) ?
            <Flex
                rounded="16px"
                bg="#F4F0FF"
                px="24px"
                py="24px"
                direction="column"
                alignItems="start"
                justifyContent="space-between"
                h="190px"
                overflow="auto"
                position="relative"
            >
                <Text
                    color="font"
                    fontSize="12px"
                    fontWeight="400"
                    textTransform="uppercase"
                    zIndex="99"
                >
                    Foro
                </Text>

                <Flex
                    direction="column"
                    alignItems="start"
                    gap="8px"
                    zIndex="99"
                >
                    <Text
                        color="font"
                        fontSize="24px"
                        fontWeight={"600"}
                    >
                        {preguntas?.meta?.total === 0
                            ? "No hay actividad nueva"
                            : (preguntas?.meta?.total === 1 ? `${preguntas?.meta?.total} pregunta` : `${preguntas?.meta?.total} preguntas`)
                        }
                    </Text>

                    <Text
                        color="font"
                        fontSize="12px"
                        fontWeight="400">
                        Contribuye al debate
                    </Text>
                </Flex>

                <Image
                    src={bgImage}
                    bottom="0"
                    right="0"
                    position="absolute"
                />
            </Flex>
            :
            <Skeleton
                h="190px"
                w="100%"
                rounded="16px"
            />
    )
}