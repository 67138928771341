import { Flex, Icon, Image, Text, useToast } from "@chakra-ui/react";
import { BiChevronDown, BiUserCircle } from "react-icons/bi"
import { AiOutlinePoweroff } from "react-icons/ai"
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../../context/user.context";
import userProfile from "../../../../assets/img/Avatar.png";


export const UserActions = () => {
    const { logout, user } = useAuthContext();
    const navigate = useNavigate();
    const toast = useToast();
    const [modal, setModal] = useState<boolean>(false)

    const closeModal = (event: any, isButton = false) => {
        if (modal !== false) {
            setModal(false)
        }

        if (modal !== true && isButton) {
            setModal(true)
        }

        event.stopPropagation();
    }

    useEffect(() => {
        document.body.addEventListener('click', closeModal);

        return () => document.body.removeEventListener('click', closeModal);
    }, [modal])

    return (
        <Flex>
            <Flex
                gap="10px"
                alignItems="center"
                pos="relative"
                rounded="15px"
            >
                <Flex
                    alignItems="center"
                    gap="12px"
                    onClick={(e: React.MouseEvent) => {
                        closeModal(e, true)
                    }}
                    cursor="pointer"
                >
                    <Flex
                        w="40px"
                        h="40px"
                    >
                        <Image
                            alt={`${user?.username}`}
                            src={`${user?.avatar?.url}` || userProfile}
                            width="full"
                            objectFit="cover"
                            rounded="16px"
                        />
                    </Flex>
                    <Text
                        fontSize="14px"
                        fontWeight="400"
                        color="font"
                    >
                        {user?.username}
                    </Text>

                    <Icon
                        color="font"
                        as={BiChevronDown}
                        transition="all 0.2s ease 0s"
                        transform={modal ? "rotate(180deg)" : "rotate(0deg)"}
                        boxSize="24px"
                    />
                </Flex>


                <Flex
                    pos="absolute"
                    top="45px"
                    right="0px"
                    borderRadius="14px"
                    boxShadow="rgba(0, 0, 0, 0.25) 0px 4px 29px 0px"
                    zIndex="999"
                    opacity={modal ? "1" : "0"}
                    visibility={modal ? "visible" : "hidden"}
                    flexDirection="column"
                    bg="purewhite"
                    w="200px"
                >
                    <Flex
                        flex="1"
                        flexDirection="column"
                        p="22px 18px"
                        gap="15px"
                    >
                        <Flex alignItems="center" gap="10px" cursor="pointer" onClick={() => navigate("/perfil/cuenta")}>
                            <Icon as={BiUserCircle} color="variant_font" w="16px" h="16px" />
                            <Text fontWeight="normal" fontSize="16px">Perfil</Text>
                        </Flex>

                        <Flex alignItems="center" gap="10px" cursor="pointer" onClick={() => logout("Sesión cerrada correctamente", navigate, toast)}>
                            <Icon as={AiOutlinePoweroff} color="variant_font" w="16px" h="16px" />
                            <Text fontWeight="normal" fontSize="16px">Cerrar sesión</Text>
                        </Flex>

                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
};