import { Button, Flex, Icon, Skeleton, Text, useToast } from '@chakra-ui/react'
import ChatConversationItem from './ChatConversationItem';
import { Formik, Form as FormikForm } from "formik";
import { FormTextEditor } from '../../../shared/components/FormElements/FormTextEditor';
import { BiSend } from 'react-icons/bi';
import { createMessage, readMessage } from '../../../shared/middlewares/messages.middleware';
import { useEffect, useState, useRef } from 'react';
import { useAuthContext } from '../../../shared/context/user.context';
import { EstadoMensaje } from '../../../shared/utils/types/MensajeriaEnum';
import { UserRolEnum } from '../../../shared/utils/types/UserRolEnum';
import { toastNotify } from '../../../shared/utils/functions/toastNotify';
import { StatusEnumTypes } from '../../../shared/utils/types/StatusEnumTypes';
import { MensajesInt } from '../../../interfaces/MessagesInt';
import { useDataId } from '../../../shared/hooks/useDataId';
import { EndpointTypes } from '../../../shared/utils/types/EndpointTypes';
import { useParams } from 'react-router-dom';

export default function ChatConversation() {
    const { id } = useParams()
    const toast = useToast();
    const { user } = useAuthContext();
    const [loading, setLoading] = useState<boolean>(false)

    const { data: conversacion, Refresh: refreshMensaje } = useDataId({
        endpoint: EndpointTypes.MENSAJERIA_CONVERSACION,
        id: id!,
    });

    const initialValues = {
        texto: null
    };

    const submitForm = async (values: { texto: string | null }) => {
        setLoading(true)

        const receptor = conversacion?.receptorId !== user?.id ? conversacion?.receptorId : conversacion?.emisorId;

        const newMessage = {
            conversacionId: conversacion?.id,
            emisorId: user?.id,
            receptorId: receptor,
            texto: values?.texto,
            estado: EstadoMensaje.NO_LEIDO
        }

        createMessage(newMessage)
            .then(() => {
                user?.role?.nombre === UserRolEnum.TUTOR && onReadMessage()
                refreshMensaje();
            })
            .catch(() => toastNotify(toast, StatusEnumTypes.ERROR, "Error al enviar mensaje"))
            .finally(() => setLoading(false))

    };

    const onReadMessage = () => {
        conversacion?.mensajes?.filter((mensaje: any) => mensaje?.receptorId === user?.id).map(async (mensaje: any) => {
            if (mensaje?.estado === EstadoMensaje.NO_LEIDO) {
                await readMessage(mensaje?.id).catch((error) => console.log(error))
            }
        })
    }

    useEffect(() => {
        UserRolEnum.ALUMNO === user?.role?.nombre &&
            onReadMessage()
        refreshMensaje()
    }, [])

    const mensajesEndRef = useRef<HTMLDivElement | null>(null);

    const scrollToBottom = () => {
        mensajesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        if (conversacion?.mensajes) {
            scrollToBottom();
        }
    }, [conversacion?.mensajes]);

    return (
        <Flex
            direction="column"
            borderRightRadius="20px"
            bg="purewhite"
            border="1px solid"
            borderColor="border"
            borderLeft="0"
            w="100%"
        >

            <Flex
                direction="column"
                gap="7px"
                p="24px"
                borderBottom="1px solid"
                borderBottomColor="border"
            >
                {!conversacion?.mensajes ?
                    <Skeleton
                        w="200px"
                        h="55px"
                        rounded="8px"
                    />
                    :
                    <>
                        <Text
                            fontSize="20px"
                            fontWeight="600"
                            color="font"
                        >
                            {conversacion?.asunto}
                        </Text>

                        <Text
                            fontSize="12px"
                            fontWeight="400"
                            color="font"
                        >
                            {conversacion?.leccion?.nombre}
                        </Text>
                    </>
                }

            </Flex>

            <Flex
                direction="column"
                overflowY="auto"
            >
                {!conversacion?.mensajes ?
                    Array.from({ length: 4 }, (_, i) => i).map((_, index: number) => (
                        <Skeleton
                            key={index}
                            h="120px"
                            w="full"
                            mb="5px"
                        />
                    ))
                    :
                    conversacion?.mensajes?.map((mensaje: MensajesInt, index: number) => {
                        return (
                            <ChatConversationItem
                                key={index}
                                mensaje={mensaje}
                            />
                        );
                    })
                }
                <div ref={mensajesEndRef} />
            </Flex>

            <Flex
                p="24px"
                direction="column"
                mt="auto"
                w="100%"
            >
                <Formik
                    onSubmit={(values, { resetForm }) => {
                        submitForm(values);
                        resetForm();
                    }}
                    initialValues={initialValues}
                >
                    {(formik) => {
                        const { handleSubmit } = formik;

                        return (
                            <Flex
                                direction="column"
                                align="start"
                                w="full"
                            >
                                <FormikForm
                                    onSubmit={handleSubmit}
                                    style={{
                                        width: "100%"
                                    }}
                                >
                                    <FormTextEditor
                                        height="200px"
                                        name='texto'
                                        onChange={formik.setFieldValue}
                                        placeholder="Escribe una respuesta"
                                        styles={{
                                            border: "1px solid",
                                            borderColor: "#E2E8F0",
                                            borderRadius: "8px",
                                            padding: "0",
                                            fontSize: "15px",
                                            fontWeight: "400",
                                            lineHeight: "150%",
                                            height: "100%",
                                            width: "100%",
                                            background: "#FFF",
                                        }}
                                    />

                                    <Flex
                                        w="100%"
                                    >
                                        <Button
                                            bg="main"
                                            color="purewhite"
                                            fontSize="16px"
                                            fontWeight="700"
                                            rightIcon={<Icon as={BiSend} />}
                                            p="12px 16px"
                                            w="fit-content"
                                            ml="auto"
                                            type='submit'
                                            isLoading={loading}
                                        >
                                            Enviar Mensaje
                                        </Button>
                                    </Flex>
                                </FormikForm>
                            </Flex>
                        );
                    }}
                </Formik>
            </Flex>
        </Flex>
    )
}
