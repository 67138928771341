import axios from "axios";
import tenantInstance from "../services/tenantInstance.service";

export const getToken = async (user: { userId: string; password: string; }) => {
    const { tenant } = await tenantInstance();

    return axios.post(
        `${import.meta.env.VITE_URL_CAMPUS_API}${tenant}/auth/login`,
        user
    );
};

export const setPassword = async (data: { hashString: string, password: string, passwordRepeat: string }) => {
    const { tenant } = await tenantInstance();

    return axios.post(
        `${import.meta.env.VITE_URL_CAMPUS_API}${tenant}/auth/setPassword`,
        data
    );
};

export const recoveryPassword = async (email: string) => {
    const { tenant } = await tenantInstance();

    return axios.post(
        `${import.meta.env.VITE_URL_CAMPUS_API}${tenant}/auth/recoverPassword`,
        {
            email: email
        }
    );
};

export const checkHashExists = async (hashString: string) => {
    const { tenant } = await tenantInstance();

    return axios.post(
        `${import.meta.env.VITE_URL_CAMPUS_API}${tenant}/auth/checkHash`,
        {
            hashString: hashString
        }
    );
};

export const loadContext = async (token: string) => {
    const { tenant, client } = await tenantInstance();

    return axios.get(
        `${import.meta.env.VITE_URL_CAMPUS_API}${tenant}/${client}/loadContextForLoginViaId`,
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
    ).then((response: any) => response);
}

export const createMagicLink = async ({ email }: { email: string }) => {
    const { tenant } = await tenantInstance();

    return axios.post(
        `${import.meta.env.VITE_URL_CAMPUS_API}${tenant}/auth/createMagicLink`,
        { email }
    );
};

export const checkMagicLink = async ({ codigo }: { codigo: string }) => {
    const { tenant } = await tenantInstance();

    return axios.post(
        `${import.meta.env.VITE_URL_CAMPUS_API}${tenant}/auth/checkMagicLink`,
        { codigo }
    );
};