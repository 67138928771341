import { Box, VStack, Flex, Skeleton } from "@chakra-ui/react";

export const TenantHeaderSkeleton = () => (
    <Box
        bg="white"
        p={8}
        borderRadius="xl"
        boxShadow="lg"
        w="full"
    >
        <VStack spacing={6}>
            <Flex
                align="center"
                justify="center"
                gap="5px"
            >
                <Skeleton
                    boxSize="80px"
                    borderRadius="md"
                />

                <Skeleton
                    height="48px"
                    width="250px"
                />
            </Flex>

            <VStack
                spacing={4}
                width="100%"
                align="center"
            >
                <Skeleton
                    height="36px"
                    width="60%"
                    maxWidth="400px"
                />

                <Skeleton
                    height="20px"
                    width="80%"
                    maxWidth="600px"
                />

                <Skeleton
                    height="20px"
                    width="70%"
                    maxWidth="500px"
                />
            </VStack>
        </VStack>
    </Box>
);