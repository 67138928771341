import { LeccionInt } from "../../../interfaces/CursosInt"
import { ComponenteLeccionEnum, LeccionTipoEnum, SubtipoLeccion } from "../types/LeccionTipoEnum"

export const categorizarTipoLeccion = (leccion: LeccionInt | undefined) => {
    if (leccion?.tipo === LeccionTipoEnum.FICHERO) {
        if (leccion?.contenido?.includes(".pdf") || leccion?.contenido?.includes(".xlsx") || leccion?.contenido?.includes(".pptx") || leccion?.contenido?.includes(".docx"))
            return ComponenteLeccionEnum.PDF_VIEWER
        else if (leccion?.contenido?.includes("image") && !leccion?.contenido?.includes(".pdf"))
            return ComponenteLeccionEnum.IMAGEN
        else if (leccion?.contenido?.includes("video"))
            return ComponenteLeccionEnum.VIDEO
    }
    else if (leccion?.tipo === LeccionTipoEnum.EMBEBIDO) {
        if (leccion?.contenido?.includes("youtube") || leccion?.contenido?.includes("vimeo"))
            return ComponenteLeccionEnum.VIDEO
        else return ComponenteLeccionEnum.IFRAME
    }
    else if (leccion?.tipo === LeccionTipoEnum.TEXTO)
        return ComponenteLeccionEnum.MARKDOWN_PREVIEW
    else if (leccion?.tipo === LeccionTipoEnum.URL)
        return ComponenteLeccionEnum.LINK
    else if (leccion?.tipo === LeccionTipoEnum.SESION_VIVO)
        return ComponenteLeccionEnum.SESION_VIVO
    else if (leccion?.tipo === LeccionTipoEnum.ENTREGABLE)
        return ComponenteLeccionEnum.ENTREGABLE

}

export const categorizarSubtipoLeccion = (leccion: LeccionInt | undefined) => {
    if (leccion?.tipo === LeccionTipoEnum.ENTREGABLE) {
        if (leccion?.subtipo === SubtipoLeccion.FICHERO) {
            if (leccion?.contenido?.includes(".pdf") || leccion?.contenido?.includes(".xlsx") || leccion?.contenido?.includes(".pptx") || leccion?.contenido?.includes(".docx"))
                return ComponenteLeccionEnum.PDF_VIEWER
            else if (leccion?.contenido?.includes("image") && !leccion?.contenido?.includes(".pdf"))
                return ComponenteLeccionEnum.IMAGEN
            else if (leccion?.contenido?.includes("video"))
                return ComponenteLeccionEnum.VIDEO
        }
        else if (leccion?.subtipo === SubtipoLeccion.EMBEBIDO) {
            if (leccion?.contenido?.includes("youtube") || leccion?.contenido?.includes("vimeo"))
                return ComponenteLeccionEnum.VIDEO
            else return ComponenteLeccionEnum.IFRAME
        }
        else if (leccion?.subtipo === SubtipoLeccion.TEXTO)
            return ComponenteLeccionEnum.MARKDOWN_PREVIEW
    }
}