import { JSXElementConstructor, ReactElement, ReactPortal } from "react";
import { ToastPosition } from "@chakra-ui/react";
import { StatusEnumTypes } from "../types/StatusEnumTypes";

type ONLY_FOR_FORMAT = string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | ReactPortal

export const toastNotify = (toast: any, status: any, title: any) => {

    toast({
        title: title,
        status: status,
        duration: 3000,
        isClosable: true,
    })
};

export const customToast = ({
    toast,
    title,
    status,
    description = undefined,
    duration = null,
    isClosable = true,
    position = "bottom"
}: {
    toast: any,
    title: ONLY_FOR_FORMAT,
    status: StatusEnumTypes,
    duration?: number | null,
    isClosable?: boolean,
    position?: ToastPosition
    description?: ONLY_FOR_FORMAT | undefined
}) => {
    const descriptionData = description ? { description: description } : {}

    const toastData = {
        title: title,
        status: status,
        duration: duration,
        isClosable: isClosable,
        position: position,
        ...descriptionData
    }

    toast(toastData)
};
