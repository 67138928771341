import { Flex, Image, Skeleton, Text } from "@chakra-ui/react"
import bgImage from "../../../assets/img/cardMensajes.png"
import { useData } from "../../../shared/hooks/useData"
import { EndpointTypes } from "../../../shared/utils/types/EndpointTypes"

export const CardMensajes = () => {
    const { data: conversaciones, loading: loadingConversaciones } = useData({
        endpoint: EndpointTypes.MENSAJERIA_CONVERSACION
    })

    const { data: mensajes, loading: loadingMensajes } = useData({
        endpoint: EndpointTypes.MENSAJERIA
    })

    return (
        (!loadingConversaciones && !loadingMensajes) ?
            <Flex
                rounded="16px"
                bg="#FFF1ED"
                px="24px"
                py="24px"
                direction="column"
                alignItems="start"
                justifyContent="space-between"
                h="190px"
                overflow="auto"
                position="relative"
            >
                <Text
                    color="font"
                    fontSize="12px"
                    fontWeight="400"
                    textTransform="uppercase"
                    zIndex="99"
                >
                    Mensajería
                </Text>

                <Flex
                    direction="column"
                    alignItems="start"
                    gap="8px"
                    zIndex="99"
                >
                    <Text
                        color="font"
                        fontSize="24px"
                        fontWeight={"600"}
                    >
                        {conversaciones?.meta?.total} chats · {mensajes?.meta?.total} mensajes
                    </Text>

                    <Text
                        color="font"
                        fontSize="12px"
                        fontWeight="400">
                        Interactúa con tus tutores
                    </Text>
                </Flex>

                <Image
                    src={bgImage}
                    bottom="0"
                    right="0"
                    position="absolute"
                />
            </Flex>
            :
            <Skeleton
                h="190px"
                w="100%"
                rounded="16px"
            />
    )
}