import { Flex, Image, Text } from "@chakra-ui/react"
import bgImage from "../../../assets/img/cardFundae.png"

export const CardFundae = () => {
    return (
        <Flex
            rounded="16px"
            bg="#F4F0FF"
            px="24px"
            py="24px"
            direction="column"
            alignItems="start"
            justifyContent="space-between"
            h="190px"
            overflow="auto"
            position="relative"
        >
            <Text
                color="font"
                fontSize="12px"
                fontWeight="400"
                textTransform="uppercase"
                zIndex="99"
            >
                Anuncio
            </Text>

            <Flex
                direction="column"
                alignItems="start"
                gap="8px"
                zIndex="99"
            >
                <Text
                    color="font"
                    fontSize="24px"
                    fontWeight={"600"}
                >
                    Curso Fundae
                </Text>

                <Text
                    color="font"
                    fontSize="12px"
                    fontWeight="400">
                    Si tu curso se realiza a través de Fundae, recuerda descargar y revisar la guía didáctica antes de comenzar.
                </Text>
            </Flex>

            <Image
                src={bgImage}
                bottom="0"
                right="0"
                position="absolute"
            />
        </Flex>
    )
}