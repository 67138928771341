import { Button, Flex, Icon, Skeleton, SkeletonText, Text } from "@chakra-ui/react";
import { BiDownload, BiMenu, BiPlay } from "react-icons/bi";
import { HiOutlineClock } from "react-icons/hi";

export const SkeletonInformationTutor = () => {
    return (
        <Flex
            w="full"
        >
            <Flex
                direction="column"
                p="40px"
                maxW="500px"
                w="100%"
                gap="30px"
            >
                <Flex
                    direction="column"
                    gap="25px"
                >
                    <Flex
                        direction="column"
                        gap="20px"
                    >
                        <Flex
                            alignItems="center"
                            gap="20px"
                        >
                            <Skeleton
                                w="100px"
                                h="100px"
                                rounded="20px"
                                mr="-12px"
                                zIndex="10"
                            />

                            <Flex
                                direction="column"
                                gap="10px"
                            >
                                <Text
                                    fontSize="14px"
                                    fontWeight="600"
                                    color="font"
                                >
                                    CURSO
                                </Text>

                                <Skeleton height="32px" width="80%" />
                            </Flex>
                        </Flex>

                        <Flex
                            gap="12px"
                            align="center"
                        >
                            <Flex
                                gap="5px"
                                align="center"
                            >
                                <Icon
                                    as={BiMenu}
                                    boxSize="20px"
                                    color="font"
                                />

                                <Skeleton height="20px" width="80px" />
                            </Flex>

                            <Flex
                                gap="5px"
                                align="center"
                            >
                                <Icon
                                    as={HiOutlineClock}
                                    boxSize="20px"
                                    color="font"
                                />

                                <Skeleton height="20px" width="60px" />
                            </Flex>
                        </Flex>
                    </Flex>

                    <Skeleton height="200px" width="100%" />
                </Flex>
            </Flex>

            <Flex
                w="1px"
                h="100%"
                bg="border"
                py="40px"
            />

            <Flex
                p="40px"
                w="full"
            >
                <Flex
                    direction="column"
                    gap="16px"
                    w="100%"
                >
                    <Flex
                        justify="space-between"
                        align="center"
                        w="full"
                    >
                        <Text
                            fontSize="18px"
                            fontWeight="700"
                            color="font"
                        >
                            Contenido del curso
                        </Text>
                    </Flex>

                    <Flex
                        direction="column"
                        gap="14px"
                        w="full"
                    >
                        {Array.from({ length: 5 }, (_, i) => i)?.map((item: number) => (
                            <Skeleton
                                key={item}
                                h="87px"
                                w="100%"
                                rounded="20px"
                            />
                        ))}
                    </Flex>

                </Flex>
            </Flex>
        </Flex>
    );
}