import { Flex, Grid, Text, useToast } from "@chakra-ui/react";
import { MatriculasInt } from "../../../../../interfaces/MatriculasInt";
import { useAuthContext } from "../../../../../shared/context/user.context";
import { EstadoTypeEnum } from "../../../../../shared/utils/types/EstadoTypeEnum";
import { useEffect } from "react";
import { useData } from "../../../../../shared/hooks/useData";
import { EndpointTypes } from "../../../../../shared/utils/types/EndpointTypes";
import { SkeletonAprendizajeMatriculas } from "../../../components/skeletons/SkeletonAprendizajeMatriculas";
import CardCurso from "../../../components/Aprendizaje/CardCurso";
import { CardSinInformacion, TypeCardSinInformacion } from "../../../../../shared/components/Cards/CardSinInformacion";
import CardProgreso from "../../../components/Aprendizaje/CardProgresos";
import CardActividad from "../../../components/Aprendizaje/CardActividad";
import { handleUnauthorized } from "../../../../../shared/utils/functions/handleErrors";
import { useNavigate } from "react-router-dom";
import { UserRolEnum } from "../../../../../shared/utils/types/UserRolEnum";
import CardCursoProximos from "../../../components/Aprendizaje/CardCursoProximo";

export const AprendizajeMatriculas = () => {
    const { logout, user } = useAuthContext();
    const navigate = useNavigate()
    const toast = useToast()

    const { data: _matriculas, loading, error, errorsList, status } = useData({
        endpoint: EndpointTypes.MATRICULAS_ALUMNOS,
        ignoreRequest: !user?.auth || user?.role?.nombre !== UserRolEnum.ALUMNO,
    })

    const { data: stats } = useData({
        endpoint: EndpointTypes.DASHBOARD,
    })

    const { data: preguntas } = useData({
        endpoint: EndpointTypes.PREGUNTAS,
        query:
        {
            sortBy: 'createdAt',
            order: 'desc',
            limit: 3
        }
    });

    const matriculas = _matriculas?.data?.filter((matricula: MatriculasInt) => matricula?.estado === EstadoTypeEnum.ACTIVO)
    const matriculasProximas = _matriculas?.data?.filter((matricula: MatriculasInt) => matricula?.estado === EstadoTypeEnum.PROXIMO)
    const matriculasInactivas = _matriculas?.data?.filter((matricula: MatriculasInt) => matricula?.estado === EstadoTypeEnum.INACTIVO)

    useEffect(() => {
        if (error) {
            handleUnauthorized({
                errors: errorsList,
                status: status,
                logout: logout,
                navigate: navigate,
                toast: toast
            })
        }
    }, [error])

    return (
        loading ?
            <SkeletonAprendizajeMatriculas />
            :

            <Flex
                direction="column"
                px="10px"
                w="full"
            >
                {_matriculas?.meta?.total !== 0 ?
                    <Flex
                        gap="40px"
                    >
                        <Flex
                            direction="column"
                            gap="40px"
                            w="72%"
                        >
                            {matriculas?.length > 0 &&
                                <Flex
                                    direction="column"
                                    w="full"
                                    gap="20px"
                                >

                                    <Text
                                        fontSize="18px"
                                        fontWeight="700"
                                        color="font"
                                    >
                                        Cursos Activos
                                    </Text>

                                    <Flex
                                        w="full"
                                    >
                                        <Flex
                                            w="100%"
                                            direction="column"
                                            gap="20px"
                                        >

                                            {matriculas?.map((matricula: MatriculasInt, index: number) => (
                                                <CardCurso
                                                    key={index}
                                                    curso={matricula?.grupo?.curso}
                                                    grupoSlug={matricula?.grupo?.slug}
                                                    matricula={matricula}
                                                />
                                            ))

                                            }
                                        </Flex>
                                    </Flex>
                                </Flex>
                            }

                            {matriculasInactivas?.length > 0 &&
                                <Flex
                                    direction="column"
                                    w="full"
                                    gap="20px"
                                >
                                    <Text
                                        fontSize="18px"
                                        fontWeight="700"
                                        color="font"

                                    >
                                        Cursos finalizados
                                    </Text>

                                    <Flex
                                        w="full"
                                    >
                                        <Flex
                                            w="100%"
                                            direction="column"
                                            gap="20px"
                                        >

                                            {matriculasInactivas?.map((matricula: MatriculasInt, index: number) => (
                                                <CardCurso
                                                    key={index}
                                                    curso={matricula?.grupo?.curso}
                                                    grupoSlug={matricula?.grupo?.slug}
                                                    matricula={matricula}
                                                />
                                            ))

                                            }
                                        </Flex>
                                    </Flex>
                                </Flex>
                            }

                            {matriculasProximas?.length > 0 &&
                                <Flex
                                    direction="column"
                                    gap="20px"
                                >
                                    <Text
                                        fontSize="18px"
                                        fontWeight="700"
                                        color="font"
                                    >
                                        Próximos cursos
                                    </Text>

                                    <Flex
                                        w="100%"
                                        direction="column"
                                        gap="20px"
                                    >
                                        {matriculasProximas?.map((matricula: MatriculasInt, index: number) => (
                                            <CardCursoProximos
                                                key={index}
                                                curso={matricula?.grupo?.curso}
                                                matricula={matricula}
                                            />
                                        ))
                                        }
                                    </Flex>
                                </Flex>
                            }
                        </Flex>

                        <Flex
                            mt="47px"
                            direction="column"
                            gap="20px"
                            mb="30px"
                            w="28%"
                        >
                            <CardProgreso
                                stats={stats}
                            />

                            <CardActividad
                                preguntas={preguntas}
                            />
                        </Flex>
                    </Flex>
                    :
                    <CardSinInformacion type={TypeCardSinInformacion.CURSOS} />
                }
            </Flex>
    );
}