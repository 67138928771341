import axios from "axios";
import tenantInstance from "../services/tenantInstance.service";

export const iconoTenant = async () => {
    const { tenant } = await tenantInstance();

    return axios.get(
        `${import.meta.env.VITE_URL_CAMPUS_API}${tenant}/auth/tenant/icono`,
        {
            headers: {
                pass: import.meta.env.VITE_ICONO_PASS
            }
        }
    ).then((response: any) => response?.data?.data);
}