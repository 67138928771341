import { Flex, Text } from "@chakra-ui/react";
import { HiOutlineBookOpen, HiOutlineBriefcase, HiOutlineChartSquareBar, HiOutlineClock } from "react-icons/hi";
import RowProgreso from "./RowProgreso";

interface Props {
    stats: any;
}

export default function CardProgreso({ stats }: Props) {

    return (
        <Flex
            direction="column"
            gap="20px"
        >
            <Text
                color="font"
                fontSize="14px"
                fontWeight="600"
            >
                Tu progreso
            </Text>

            <Flex
                direction="column"
                gap="10px"
                bg="purewhite"
                p="20px"
                rounded="20px"
                border="1px solid"
                borderColor="border"
                h="242px"
            >
                <RowProgreso
                    label="Cursos Activos"
                    value={stats?.length || 0}
                    icon={HiOutlineBookOpen}
                />

                <Flex
                    h="1px"
                    bg="border"
                />

                <RowProgreso
                    label="Cursos Terminados"
                    value={stats ? stats?.filter((matricula: any) => {

                        if (matricula.progresoLecciones === 100) {
                            return true
                        } else {
                            return false
                        }

                    }).length : "0"}
                    icon={HiOutlineBriefcase}
                />

                <Flex
                    h="1px"
                    bg="border"
                />

                <RowProgreso
                    label="Horas Dedicadas"
                    value={stats ? (stats?.reduce((horas: any, matriculaActual: any) => horas + matriculaActual?.tiempoAcceso, 0) / 3600).toFixed(0) : "0"}
                    icon={HiOutlineClock}
                />

                <Flex
                    h="1px"
                    bg="border"
                />

                <RowProgreso
                    label="Nota Media general"
                    value={stats ? (stats?.reduce((notas: any, matriculaActual: any) => notas + matriculaActual?.puntuacionMedia, 0) / stats?.length).toFixed(0) : "0"}
                    icon={HiOutlineChartSquareBar}
                />
            </Flex>
        </Flex>
    )
}
