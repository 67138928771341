import { Flex, Skeleton } from "@chakra-ui/react"
import { Topbar } from "../../../../shared/components/Topbar/Topbar"
import { useData } from "../../../../shared/hooks/useData"
import { EndpointTypes } from "../../../../shared/utils/types/EndpointTypes"
import { useState } from "react"
import { Table } from "../../../../shared/components/Table/Table"
import { TypeCardSinInformacion } from "../../../../shared/components/Cards/CardSinInformacion"
import { Filter } from "../../components/Filter"
import { AsignacionesColumns } from "./Columns/AsignacionesColumns"

export default function Matriculas() {
  const [query, setQuery] = useState<{
    estado?: string;
    curso?: string;
    fechaInicioSup?: string;
    fechaFinInf?: string;
  }>({})
  const { data: asignaciones, loading } = useData({
    endpoint: EndpointTypes.MATRICULAS_TUTOR,
    query: {
      ...query
    }
  })

  return (
    <Topbar
      title="Matrículas"
      searchBar
    >
      < Flex
        px="50px"
        py="20px"
        w="100%"
        direction="column"
        gap="20px"
      >

        <Filter
          query={query}
          setQuery={setQuery}
          data={asignaciones?.data || []}
        />

        <Table
          columns={AsignacionesColumns()}
          data={asignaciones?.data}
          loading={loading}
          total={asignaciones?.meta?.total}
          typeSinInformacion={TypeCardSinInformacion.MATRICULAS}
        />
      </Flex>
    </Topbar >
  )
}
