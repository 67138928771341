import { Flex, Text } from "@chakra-ui/react"

export const CardFinalizacionSinMatricula = () => {

    return (
        <Flex
            w="50%"
            p="20px"
            border="1px solid"
            borderColor="#DFE1E6"
            rounded="16px"
            bg="purewhite"
            direction="column"
            gap="30px"
            h="255px"
            alignItems="center"
        >
            <Text
                fontSize="20px"
                fontWeight="700"
                color="font"
            >
                Aún no estás matriculado en ningún curso
            </Text>

            <Text
                fontSize="14px"
                fontWeight="400"
                color="font"
            >
                Si no ves ningún curso asignado, por favor <Text as="strong" color="main">contacta con soporte</Text> para resolver esta situación. Estamos aquí para ayudarte a comenzar tu viaje de aprendizaje.
            </Text>
        </Flex>
    )
}