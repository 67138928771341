import { useDisclosure } from "@chakra-ui/react";
import { useEffect, useState } from "react";

export const useOutWindow = (login: boolean | undefined) => {
    const [timeModal, setTimeModal] = useState<number>(600000);
    const { isOpen: outWindow, onOpen, onClose } = useDisclosure();

    useEffect(() => {
        if (login) {
            let inactivityTimeout: NodeJS.Timeout | null = null;

            // FUNCION PARA MANEJAR LA INACTIVIDAD
            const handleInactivity = () => {
                onOpen();
            };

            // REINICIAR EL TIEMPO DE INACTIVIDAD CUANDO HAY INTERACCIÓN
            const resetInactivityTimeout = () => {
                if (inactivityTimeout) {
                    clearTimeout(inactivityTimeout);
                }

                // ACTIVA EL TIEMPO DE INACTIVIDAD PARA DARLE AVISO AL USUARIO
                inactivityTimeout = setTimeout(handleInactivity, timeModal);
            };

            // LLAMADOS A LAS FUNCIONES CON LOS EVENTOS
            window.addEventListener("mousemove", resetInactivityTimeout, false);
            window.addEventListener("keydown", resetInactivityTimeout, false);

            // INICIAR EL TIEMPO DE INACTIVIDAD
            resetInactivityTimeout();

            return () => {
                // LIMPIAMOS LOS EVENTOS Y EL TIMEOUT
                window.removeEventListener("mousemove", resetInactivityTimeout);
                window.removeEventListener("keydown", resetInactivityTimeout);

                if (inactivityTimeout) clearTimeout(inactivityTimeout);
            };
        }

        return
    }, [login, timeModal]);

    return { outWindow, onClose, setTimeModal }
}; 