import { useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Heading,
    Flex,
    useToast
} from '@chakra-ui/react';
import { Formik, Form as FormikForm } from 'formik';
import * as Yup from "yup"
import { FormInput } from '@imaginagroup/bit-components.ui.form-input';
import { addInscripcion } from '../../../shared/middlewares/public.middleware';
import { handleErrors } from '../../../shared/utils/functions/handleErrors';
import { toastNotify } from '../../../shared/utils/functions/toastNotify';
import { StatusEnumTypes } from '../../../shared/utils/types/StatusEnumTypes';
import { FormPhoneInput, phoneValidationYup } from '../../../shared/components/FormElements/FormPhoneInput';
import { validateDNI } from '../../../shared/utils/functions/ValidateDNI';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    slug: string;
    onSubmitSuccess: () => void;
}

export const InscripcionModal = ({ isOpen, onClose, slug, onSubmitSuccess }: Props) => {
    const toast = useToast();
    const [loading, setLoading] = useState(false);

    const initialValues = {
        nombre: null,
        apellidos: null,
        email: null,
        telefono: null,
        dni: null,
        direccion: {
            direccion: null,
            codigoPostal: null,
            localidad: null,
        }
    };

    const validationSchema = Yup.object({
        nombre: Yup.string()
            .required('El nombre es obligatorio')
            .trim(),
        apellidos: Yup.string()
            .required('Los apellidos son obligatorios')
            .trim(),
        email: Yup.string()
            .email('Correo electrónico inválido')
            .required('El correo electrónico es obligatorio')
            .trim(),
        telefono: Yup.string()
            .test('validator-custom-phone', function (value) {
                const validation = phoneValidationYup(value);
                if (!validation.isValid) {
                    return this.createError({
                        path: this.path,
                        message: validation.errorMessage,
                    });
                }
                else {
                    return true;
                }
            }).nullable().notRequired(),
        dni: Yup.string()
            .required('El DNI es obligatorio.')
            .test('dni-valido', 'El DNI/NIF ingresado no es válido.', validateDNI)
            .typeError('El DNI es obligatorio.'),
        direccion: Yup.object({
            direccion: Yup.string()
                .required('La dirección es obligatoria')
                .trim(),
            codigoPostal: Yup.string()
                .required('El código postal es obligatorio')
                .trim(),
            localidad: Yup.string()
                .required('La localidad es obligatoria')
                .trim(),
        })
    });

    const onSubmit = async (
        values: {
            nombre: string | null;
            apellidos: string | null;
            email: string | null;
            telefono: string | null;
            dni: string | null;
            direccion: {
                direccion: string | null;
                codigoPostal: string | null;
                localidad: string | null;
            }
        }
    ) => {
        setLoading(true)

        addInscripcion({
            data: values,
            slug: slug
        })
            .then(() => onSubmitSuccess())
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
            .finally(() => setLoading(false))
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
            <ModalOverlay />
            <ModalContent
                bgColor="purewhite"
                borderWidth="1px"
                borderColor="light_grey"
            >
                <ModalHeader>
                    <Heading as="h2" size="lg" textAlign="center" color="font">
                        Formulario de Inscripción
                    </Heading>
                </ModalHeader>

                <ModalCloseButton />

                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {({ handleSubmit }) => (
                        <FormikForm onSubmit={handleSubmit} style={{ height: "100%" }}>
                            <ModalBody p="20px 30px">
                                <Flex
                                    direction="column"
                                >

                                    <Flex
                                        gap="20px"
                                    >
                                        <FormInput
                                            label='Nombre'
                                            name="nombre"
                                            isRequired
                                        />
                                        <FormInput
                                            label='Apellidos'
                                            name="apellidos"
                                            isRequired
                                        />
                                    </Flex>

                                    <Flex
                                        gap="20px"
                                    >
                                        <FormPhoneInput
                                            label='Teléfono'
                                            name="telefono"
                                            isRequired
                                        />

                                        <FormInput
                                            label='DNI'
                                            name="dni"
                                            isRequired
                                        />
                                    </Flex>

                                    <FormInput
                                        label='Email'
                                        name="email"
                                        type="email"
                                        isRequired
                                    />

                                    <FormInput
                                        label='Dirección'
                                        name="direccion.direccion"
                                        isRequired
                                    />

                                    <Flex
                                        gap="20px"
                                    >
                                        <FormInput
                                            label='Código Postal'
                                            name="direccion.codigoPostal"
                                            type="tel"
                                            isRequired
                                        />
                                        <FormInput
                                            label='Localidad'
                                            name="direccion.localidad"
                                            isRequired
                                        />
                                    </Flex>
                                </Flex>
                            </ModalBody>
                            <ModalFooter gap="20px">
                                <Button
                                    color="font"
                                    bgColor="purewhite"
                                    rounded="10px"
                                    fontSize="15px"
                                    fontWeight="400"
                                    lineHeight="normal"
                                    onClick={onClose}
                                >
                                    Cancelar
                                </Button>

                                <Button
                                    type='submit'
                                    color="purewhite"
                                    bgColor="main"
                                    rounded="10px"
                                    fontSize="15px"
                                    fontWeight="400"
                                    lineHeight="normal"
                                    _hover={{ opacity: 0.8 }}
                                    isLoading={loading}
                                >
                                    Enviar Inscripción
                                </Button>
                            </ModalFooter>
                        </FormikForm>
                    )}
                </Formik>
            </ModalContent>
        </Modal>
    );
};