import { Button, Flex, Icon, Text, Tooltip } from "@chakra-ui/react"
import React from "react";
import { BiDownload } from "react-icons/bi"

interface Props {
    isDisabled?: boolean;
    tooltip: string;
    isFundae: boolean;
    loading?: boolean;
    handleDiploma: () => void;
}

export const CardDiploma = ({
    isDisabled = false,
    tooltip,
    isFundae,
    loading = false,
    handleDiploma
}: Props) => {
    return (
        <Tooltip
            hasArrow
            label={tooltip}
            color="purewhite"
            fontSize="14px"
            fontWeight="500px"
            rounded="8px"
            py="10px"
            px="10px"
            bg="secondary"
            placement={isFundae ? "top" : "bottom"}
            isDisabled={!isDisabled}
        >
            <Flex
                py="16px"
                px="20px"
                rounded="10px"
                border="1px solid"
                borderColor="#EEEFF2"
                boxShadow="0px 1px 2px 0px rgba(228, 229, 231, 0.24)"
                gap={isFundae ? "8px" : "30px"}
                alignItems="center"
                opacity={isDisabled ? 0.5 : 1}
                cursor={isDisabled ? "not-allowed" : "default"}
                direction={isFundae ? "row" : "column"}
            >
                <Flex
                    gap="8px"
                    alignItems="center"
                >
                    <Icon
                        as={BiDownload}
                        boxSize="20px"
                        color="font"
                    />

                    <Text
                        color="font"
                        fontSize="15px"
                        fontWeight="400"
                    >
                        Descargar diploma
                    </Text>
                </Flex>

                <Flex
                    ml={isFundae ? "auto" : "0"}
                >
                    <Button
                        color="purewhite"
                        fontSize="12px"
                        fontWeight="500"
                        py="10px"
                        px="16px"
                        bg="main"
                        rounded="6px"
                        h="fit-content"
                        w="80px"
                        isDisabled={isDisabled}
                        isLoading={loading}
                        onClick={(e: React.MouseEvent) => {
                            e.stopPropagation()

                            handleDiploma()
                        }}
                    >
                        Descargar
                    </Button>
                </Flex>
            </Flex>
        </Tooltip>
    )
}