import { Flex, Text } from '@chakra-ui/react'
import CardRequisito from './CardRequisito'
import { MatriculasInt } from '../../../../interfaces/MatriculasInt'

interface Props {
    matriculaActual?: MatriculasInt;
}

export default function RequisitosCurso({ matriculaActual }: Props) {
    return (
        <Flex
            direction="column"
            gap="16px"
        >
            <Text
                fontSize="14px"
                fontWeight="700"
                color="font"
            >
                Requisitos del curso
            </Text>

            <Flex
                direction="column"
                gap="16px"
            >
                <CardRequisito
                    stat={parseInt((matriculaActual?.puntuacionMedia || 0)?.toFixed(0))}
                    isPuntuacion
                />
                <CardRequisito
                    stat={parseInt((matriculaActual?.progresoLecciones || 0)?.toFixed(0))}
                    isActividades
                />
                <CardRequisito
                    stat={parseInt((matriculaActual?.progresoEntregables || 0)?.toFixed(0))}
                    isEjercicios
                />
            </Flex>
        </Flex>
    )
}
