import { Box } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { compareTimes } from "../../../utils/functions/compare";

export const TimePicker_TimeItem = ({
    min,
    max,
    time,
    scrollOnOpen,
    isPopoverOpen,
    onPopoverClose,
    onChange = () => { },
}: {
    min: string | undefined;
    max: string | undefined;
    time: string;
    scrollOnOpen?: boolean;
    isPopoverOpen: boolean;
    onPopoverClose: () => void;
    onChange: (time: string) => void;
}) => {
    const timeRef = useRef<any>();

    const [isDisabled, setIsDisabled] = useState<boolean>(true);

    useEffect(() => {
        try {
            /** Si el tiempo a mostrar es menor al tiempoMin, deshabilitamos */
            const isDisabledIfMin = min ? compareTimes(time, min) === -1 : false;
            /** Si el tiempo a mostrar es mayor al tiempoMax, deshabilitamos */
            const isDisabledIfMax = max ? compareTimes(time, max) === 1 : false;

            setIsDisabled(isDisabledIfMin || isDisabledIfMax);
        } catch (err) {
            console.error(err);
        }
    }, []);

    useEffect(() => {
        if (isPopoverOpen && scrollOnOpen) timeRef.current.scrollIntoView({ block: 'nearest', inline: 'nearest' });
    }, [isPopoverOpen, scrollOnOpen]);

    const handleOnChange = () => {
        if (!isDisabled) {
            onChange(time);
            onPopoverClose();
        }
    };

    return (
        <Box
            ref={timeRef}
            p="4px 12px"
            textAlign="center"
            onClick={handleOnChange}
            bg={isDisabled ? 'gray_1' : 'unset'}
            cursor={isDisabled ? 'not-allowed' : 'pointer'}
            borderBottom="1px solid var(--chakra-colors-gray_3)"
            _hover={isDisabled ? {} : { bg: 'gray_1' }}
            _last={{ borderBottom: 'none' }}
        >
            {time}
        </Box>
    );
};