import { Button, Flex, Icon, Text } from '@chakra-ui/react'
import { BiPlay } from 'react-icons/bi'
import AccordionContenido from './AccordionContenido';
import { onContinueLeccion } from '../../../../shared/utils/functions/validateProgress';
import { CursosInt, LeccionInt } from '../../../../interfaces/CursosInt';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useData } from '../../../../shared/hooks/useData';
import { EndpointTypes } from '../../../../shared/utils/types/EndpointTypes';
import { MatriculasInt } from '../../../../interfaces/MatriculasInt';
import { UserRolEnum } from '../../../../shared/utils/types/UserRolEnum';
import { useAuthContext } from '../../../../shared/context/user.context';
import { useProgressContext } from '../../../../shared/context/progress.context';

interface Props {
    curso: CursosInt;
    matricula: MatriculasInt | undefined;
}

export default function ContenidoCurso({ curso, matricula }: Props) {
    const { user } = useAuthContext()
    const { cursoProgress } = useProgressContext()
    const navigate = useNavigate()
    const [search] = useSearchParams()
    const addRedirect = search.has("grupo") ? `?grupo=${search.get("grupo")}` : ""

    const { data: progreso } = useData({
        endpoint: EndpointTypes.PROGRESOS,
        query: {
            curso: matricula?.grupo?.cursoId!,
            matricula: matricula?.id!
        },
        ignoreRequest: !matricula
    })

    const modulo = onContinueLeccion(progreso?.data[0]?.progresoLecciones, curso)?.modulo;
    const leccion = modulo?.lecciones?.find((leccion: LeccionInt) =>
        leccion.slug === onContinueLeccion(progreso?.data[0]?.progresoLecciones, curso).leccion?.slug
    );

    const isAllLessonsCompleted = () => {
        if (!cursoProgress?.data || !cursoProgress?.totalLecciones) return false;
        
        return Object.keys(cursoProgress.data).length === Number(cursoProgress.totalLecciones);
    }

    const allCompleted = isAllLessonsCompleted();


    return (
        <Flex
            direction="column"
            gap="16px"
            w="100%"
        >
            <Flex
                justify="space-between"
                align="center"
                w="full"
            >
                <Text
                    fontSize="18px"
                    fontWeight="700"
                    color="font"
                >
                    Contenido del curso
                </Text>

                {user?.role?.nombre === UserRolEnum?.ALUMNO &&
                    <Button
                        bg="main"
                        color="purewhite"
                        rounded="10px"
                        fontWeight="700"
                        isDisabled={allCompleted}
                        rightIcon={<Icon as={BiPlay} />}
                        onClick={() => navigate(`lecciones/${leccion?.slug}${addRedirect}`)}
                    >
                        Continuar curso
                    </Button>
                }
            </Flex>

            <Flex
                direction="column"
                gap="14px"
                w="full"
            >
                <AccordionContenido curso={curso} matricula={matricula} />
            </Flex>

        </Flex>
    )
}
