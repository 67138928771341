import { extendTheme, type ThemeConfig } from '@chakra-ui/react'
import "@fontsource/inter"
import "@fontsource/poppins/800.css"
import { SetColors } from './ui/colors/colors';

export const defaultIcon = `<svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="17" y="0.0274948" width="24" height="24" rx="4" transform="rotate(45 17 0.0274948)" fill="%233D3F65" stroke="%233D3F65" stroke-width="2"/>
</svg>`;

const colors = SetColors();

const config: ThemeConfig = {
    initialColorMode: 'light',
    useSystemColorMode: false,
}

const breakpoints = {
    xs: '480px',
    sm: '768px',
    md: '1024px',
    lg: '1228px',
    xl: '1440px',
    '2xl': '1650px'
};

const theme = extendTheme({
    breakpoints,
    config,
    semanticTokens: { colors },
    fonts: {
        body: `'Inter', sans-serif`,
    },
    colors: {
        orange: {
            50: '#e6eaff',
            100: '#c2d4ff',
            200: '#9fbfff',
            300: '#7caaff',
            400: '#5995ff',
            500: '#8694ff',
        },
        black: {
            50: '#ffffff',
            100: '#f2f2f2',
            200: '#d9d9d9',
            300: '#bfbfbf',
            400: '#a6a6a6',
            500: '#8c8c8c',
            600: '#737373',
            700: '#595959',
            800: '#404040',
            900: '#262626'
        }
    },
})

export default theme