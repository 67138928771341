import { Button, Flex, Icon, Text, Textarea, Tooltip, useToast } from "@chakra-ui/react"
import { BsFolder } from "react-icons/bs"
import DropzoneComponent from "../../../../../shared/components/Dropzone/Dropzone"
import { Dispatch, SetStateAction, useState } from "react";
import { useOutWindowContext } from "../../../../../shared/context/outWindow.context";
import { toastNotify } from "../../../../../shared/utils/functions/toastNotify";
import { StatusEnumTypes } from "../../../../../shared/utils/types/StatusEnumTypes";
import { TipoEntregaEnum } from "../../../../../shared/utils/types/EntregableEstadoEnum";
import { LeccionInt } from "../../../../../interfaces/CursosInt";
import { Form as FormikForm, Formik } from 'formik';
import * as Yup from 'yup';

interface Props {
    endPrev: boolean;
    realizarEntrega: (action: { file: File | null, setFile: Dispatch<SetStateAction<File | null>>, value: string, setValue: Dispatch<SetStateAction<string>> }) => void;
    tipo: TipoEntregaEnum
    leccion: LeccionInt;
    loading: boolean;
}

export const EntregarFile = ({
    endPrev,
    realizarEntrega,
    tipo,
    leccion,
    loading
}: Props) => {
    const toast = useToast();
    const { setTimeModal } = useOutWindowContext();
    const [file, setFile] = useState<File | null>(null)
    const [value, setValue] = useState<string>('');
    const validationSchema = Yup.object().shape({
        texto: Yup.string()
    });

    const initialValues = {
        texto: '',
    };

    return (
        <Flex
            direction="column"
            w="100%"
            gap="25px"
        >
            <Flex
                direction="column"
                fontSize="13px"
                fontWeight="400"
                color="font"
                gap="10px"
            >
                <Flex
                    display={tipo === TipoEntregaEnum.ENUNCIADO ? "flex" : "none"}
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Text
                        fontSize="18px"
                        fontWeight="700"
                        color="font"
                    >
                        Entrega del ejercicio:
                    </Text>

                    <Flex
                        py="10px"
                        px="30px"
                        bg="main"
                        rounded="14px"
                        w="fit-content"
                        h="fit-content"
                        gap="10px"
                    >
                        <Icon
                            as={BsFolder}
                            boxSize="24px"
                            color="purewhite"
                        />

                        <Text
                            fontSize="14px"
                            fontWeight="600px"
                            color="purewhite"
                        >
                            Archivo
                        </Text>
                    </Flex>
                </Flex>

                <Text
                    display={tipo === TipoEntregaEnum.REENTREGA ? "block" : "none"}
                    fontSize="18px"
                    fontWeight="700"
                    color="font"
                >
                    Nueva entrega:
                </Text>

                {leccion?.descripcion ?
                    <Text>
                        {leccion?.descripcion}
                    </Text>
                    :
                    <>
                        <Text>
                            Cuando subas un ejercicio deberás nombrar los archivos de la siguiente manera:
                        </Text>

                        <Text
                            fontWeight="600"
                        >
                            nombrecompletoalumno_TemaX_nombrecurso
                        </Text>
                    </>
                }
            </Flex>

            <Formik
                onSubmit={(values) => {
                    realizarEntrega({
                        file,
                        setFile,
                        value: values.texto,
                        setValue
                    });
                }}
                initialValues={initialValues}
                validationSchema={validationSchema}
            >
                {({ handleSubmit, setFieldValue }) => (
                    <FormikForm>
                        <Flex direction="column" gap="10px">

                            <Text>
                                Comentarios:
                            </Text>

                            <Textarea
                                name="texto"
                                minH="150px"
                                resize="none"
                                bg="white"
                                border="1px solid"
                                borderColor="border"
                                _hover={"none"}
                                fontSize="14px"
                                onChange={(e) => {
                                    setFieldValue('texto', e.target.value);
                                    setValue(e.target.value);
                                }}
                            />
                        </Flex>

                        <Flex
                            gap="20px"
                            mt="20px"
                        >
                            <Tooltip
                                rounded="5px"
                                padding="10px"
                                label={
                                    <Text
                                        textAlign="center"
                                        fontSize="16px"
                                    >
                                        {tipo === TipoEntregaEnum.ENUNCIADO ? (
                                            !endPrev
                                                ? "Antes de realizar esta lección debes completar las anteriores."
                                                : !file
                                                    ? "Debes adjuntar un archivo para poder realizar la entrega."
                                                    : "Cuando realices la entrega del ejercicio tu control de inactividad se restablecerá a 10 minutos."
                                        ) : (
                                            !file
                                                ? "Debes adjuntar un archivo para poder realizar la entrega."
                                                : "Cuando realices la entrega del ejercicio tu control de inactividad se restablecerá a 10 minutos."
                                        )}
                                    </Text>
                                }
                                hasArrow
                                placement="bottom"
                            >
                                <Button
                                    py="15px"
                                    px="24px"
                                    h="fit-content"
                                    w="fit-conten"
                                    bg="secondary"
                                    _hover={{ bg: "variant" }}
                                    rounded="14px"
                                    color="purewhite"
                                    fontSize="18px"
                                    fontWeight="700"
                                    isLoading={loading}
                                    _disabled={{ bg: "#A5A8B3", cursor: "not-allowed" }}
                                    isDisabled={tipo === TipoEntregaEnum.ENUNCIADO ? (!endPrev || !file) : !file}
                                    onClick={async (e: React.MouseEvent) => {
                                        e.stopPropagation();
                                        await handleSubmit();
                                        setTimeModal(600000);
                                    }}
                                >
                                    {tipo === TipoEntregaEnum.ENUNCIADO ? "Subir ejercicio" : "Subir nuevo ejercicio"}
                                </Button>
                            </Tooltip>

                            <Tooltip
                                rounded="5px"
                                padding="10px"
                                label={
                                    <Text
                                        textAlign="center"
                                        fontSize="16px"
                                    >
                                        Si vas a realizar ejercicios fuera de la plataforma pulsa aquí para aumentar tu control de inactividad de 10 a 30 minutos. ¡Atención, si actualizas la plataforma el tiempo se restablecerá!
                                    </Text>
                                }
                                hasArrow
                                placement="bottom"
                            >
                                <Button
                                    py="15px"
                                    px="24px"
                                    h="fit-content"
                                    w="fit-conten"
                                    bg="secondary"
                                    _hover={{ bg: "variant" }}
                                    rounded="14px"
                                    color="purewhite"
                                    fontSize="18px"
                                    fontWeight="700"
                                    _disabled={{ bg: "#A5A8B3", cursor: "not-allowed" }}
                                    onClick={() => {
                                        setTimeModal(1800000)
                                        toastNotify(toast, StatusEnumTypes.SUCCESS, "El tiempo de inactividad se ha aumentado a 30 minutos")
                                    }}
                                >
                                    Aumentar inactividad
                                </Button>
                            </Tooltip>
                        </Flex>
                    </FormikForm>
                )}
            </Formik>

            <DropzoneComponent
                file={file}
                setFile={setFile}
            />
        </Flex>
    )
}
