import { Route, Routes } from "react-router-dom"
import { Entregables } from "./views/Index"

export const EntregablesRoutes = () => {
    return (
        <Routes>
            <Route
                index
                element={<Entregables />}
            />
        </Routes>
    )
}