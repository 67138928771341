import { Flex, Icon, Text } from "@chakra-ui/react";
import { EntregablesInt } from "../../../../../interfaces/Entregables";
import { CustomColumnProps } from "../../../../../shared/components/Table/Table";
import { EntregableEstadoEnum } from "../../../../../shared/utils/types/EntregableEstadoEnum";
import { HiOutlineAnnotation, HiOutlineCheck, HiOutlineX } from "react-icons/hi";
import MarkdownPreview from "@uiw/react-markdown-preview"
import { format } from "../../../../../shared/utils/functions/formatDate";

interface Props {
    onRowClick?: (row: any) => void;
}

export const EntregableColumns = ({onRowClick}: Props): CustomColumnProps[] => {
    const columns: CustomColumnProps[] = [
        {
            field: 'leccion',
            header: 'Tarea',
            key: 'leccion',
            body: (rowData: EntregablesInt) =>
                <Text
                    p="16px"
                    fontFamily="inter"
                    color="font"
                    fontSize="14px"
                    fontWeight="400"
                    fontStyle="normal"
                    lineHeight="150%"
                >
                    {rowData?.leccion?.nombre}
                </Text>
        },
        {
            field: "estado",
            header: "Estado",
            key: "estado",
            body: (rowData: EntregablesInt) =>
                <Flex
                    p="16px"
                    justify="center"
                >
                    <Text
                        fontFamily="inter"
                        p="10px 15px"
                        rounded="full"
                        fontSize="12px"
                        fontWeight="500"
                        textTransform="capitalize"
                        border="1px solid"
                        borderColor={
                            rowData?.estado === EntregableEstadoEnum.ERROR ? 'fail'
                                : rowData?.estado === EntregableEstadoEnum.CORRECTO ? 'success_variant'
                                    : '#027DB2'
                        }
                        color={
                            rowData?.estado === EntregableEstadoEnum.ERROR ? 'fail'
                                : rowData?.estado === EntregableEstadoEnum.CORRECTO ? 'success_variant'
                                    : '#027DB2'
                        }
                    >
                        {
                            rowData?.estado === EntregableEstadoEnum.ERROR ? 'incorrecta'
                                : rowData?.estado === EntregableEstadoEnum.CORRECTO ? 'correcto'
                                    : "pendiente"
                        }
                    </Text>
                </Flex>
        },
        {
            field: "observaciones",
            header: "Observaciones",
            key: "observaciones",
            style: {
                width: "20%"
            },
            body: (rowData: EntregablesInt) =>
                <Flex
                    p="16px"
                    justify="center"
                    align="center"
                    gap="10px"
                    onClick={() => onRowClick && onRowClick(rowData)}
                >
                    <Icon
                        as={HiOutlineAnnotation}
                        boxSize="16px"
                        color="secondary"
                    />
                    <MarkdownPreview
                        source={rowData?.correccion?.texto ? 'Ver comentarios' : 'Aún no tienes observaciones'}
                        style={{
                            backgroundColor: "#fff",
                            fontFamily: "Inter",
                            color: "#252F40",
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "150%",
                        }}
                        wrapperElement={{ "data-color-mode": "light" }}
                    />
                </Flex>
        },
        {
            field: "puntuacion",
            header: "Nota media",
            key: "puntuacion",
            body: (rowData: EntregablesInt) =>
                rowData?.correccion?.puntuacion ?
                    <Flex
                        p="16px"
                        textAlign="center"
                        justify="center"
                        align="center"
                        rounded="5px"
                        fontFamily="inter"
                        fontSize="14px"
                        fontWeight="400"
                        gap="3px"
                        color="dark_grey"
                    >
                        <Icon
                            as={rowData?.estado === EntregableEstadoEnum.CORRECTO ? HiOutlineCheck
                                : rowData?.estado === EntregableEstadoEnum.ERROR ? HiOutlineX
                                    : undefined
                            }
                            color={rowData?.estado === EntregableEstadoEnum.CORRECTO ? "success"
                                : rowData?.estado === EntregableEstadoEnum.ERROR ? "fail"
                                    : ""}


                        />
                        {rowData?.correccion?.puntuacion ? `${rowData?.correccion?.puntuacion}/100` : "0/100"}
                    </Flex>
                    :
                    <Text
                        p="16px"
                        fontFamily="inter"
                        fontSize="12"
                        fontWeight="700"
                        lineHeight="150%"
                        textAlign="center"
                    >
                        ---
                    </Text>
        },
        {
            field: "fechaEntrega",
            header: "Fecha de entrega",
            key: "fechaEntrega",
            body: (rowData: EntregablesInt) =>
                <Flex
                    p="16px"
                >
                    <Text
                        fontSize="12px"
                        fontWeight="400"
                        color="font"
                    >
                        {
                            format({
                                date: rowData?.fechaEntrega,
                                customFormat: "dd MMM yyyy HH:mm"
                            })
                        }
                    </Text>
                </Flex>
        },
    ]

    return columns
}