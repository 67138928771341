import { Box, Flex, Icon, Text } from "@chakra-ui/react"
import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { FilterDateInput } from "@imaginagroup/bit-components.ui.filters.filter-date-input"
import { FilterSelect } from "@imaginagroup/bit-components.ui.filters.filter-select"
import { jsDateFormat } from "../../../shared/utils/functions/formatDate";
import { BiTrashAlt } from "react-icons/bi";
import { filterSelectStyles } from "../../../ui/Select/filterSelectStyles";
import { EstadoTypeEnum } from "../../../shared/utils/types/EstadoTypeEnum";

interface Props {
    data?: any[]
    query: {
        estado?: string;
        curso?: string;
        fechaInicioSup?: string;
        fechaFinInf?: string;
    };
    setQuery: Dispatch<SetStateAction<{
        estado?: string;
        curso?: string;
        fechaInicioSup?: string;
        fechaFinInf?: string;
    }>>;
}

export const Filter = ({
    query,
    setQuery,
    data
}: Props) => {
    const [valueCurso, setValueCurso] = useState<{ label: string; value: string; } | null>(null)

    useEffect(() => {
        const curso = cursoValue(query?.curso, data)
        setValueCurso(curso)

    }, [query, data])

    const estadoValue = (value: string | undefined) => {
        if (value === EstadoTypeEnum.ACTIVO) return { value: "0", label: "Activo" }
        if (value === EstadoTypeEnum.PROXIMO) return { value: "1", label: "Próximo" }
        if (value === EstadoTypeEnum.INACTIVO) return { value: "2", label: "Inactivo" }

        return null
    }

    const cursosOptions = (data: any[] | undefined) => {
        if (!data) return []

        const cursos: { nombre: string; id: string; }[] = []
        const options: { label: string; value: string; }[] = []

        data?.forEach((e) => {
            const cursoNombre = e?.grupo?.curso?.nombre
            const cursoId = e?.grupo?.cursoId

            const exists = cursos.some(curso => curso.id === cursoId)

            if (!exists) {
                cursos.push({
                    nombre: cursoNombre,
                    id: cursoId
                })
            }
        })

        cursos?.forEach((c) => options.push({ label: c?.nombre, value: c?.id }))

        return options
    }

    const cursoValue = (value: string | undefined, data: any[] | undefined) => {
        if (!data) return null

        const cursos = cursosOptions(data)

        const find = cursos?.find((c) => c?.value === value)

        return find ? find : null
    }

    return (
        <Flex
            rounded="16px"
            border="1px solid"
            borderColor="#DFE1E6"
            bg="purewhite"
            boxShadow="0px 1px 2px 0px rgba(228, 229, 231, 0.24)"
            p="20px"
            gap="20px"
        >
            <Flex
                direction="column"
                justifyContent="start"
                gap="15px"
                flex="1"
                w="100%"
            >
                <Text
                    color="#666D80"
                    fontSize="14px"
                    fontWeight="500"
                >
                    Estado
                </Text>

                <Box
                    position="relative"
                >
                    <FilterSelect
                        value={estadoValue(query?.estado)}
                        placeholder="Estado"
                        onChange={(e) => {
                            const newQuery = {
                                ...query,
                                estado: e?.value
                            }

                            setQuery(newQuery)
                        }}
                        options={[
                            { value: "0", label: "Activo" },
                            { value: "1", label: "Próximo" },
                            { value: "2", label: "Inactivo" },
                        ]}
                        ui={{
                            filterSelectStyles: filterSelectStyles
                        }}
                    />

                    <Icon
                        position="absolute"
                        right="35px"
                        top="12px"
                        zIndex="999"
                        as={BiTrashAlt}
                        color="font"
                        boxSize="16px"
                        cursor="pointer"
                        _active={{ transform: "scale(0.9)" }}
                        display={query?.estado ? "block" : "none"}
                        onClick={(e: React.MouseEvent) => {
                            e.stopPropagation()

                            let newQuery = {}

                            if (query?.curso) newQuery = { curso: query?.curso }
                            if (query?.fechaInicioSup) newQuery = { fechaInicioSup: query?.fechaInicioSup }
                            if (query?.fechaFinInf) newQuery = { fechaFinInf: query?.fechaFinInf }

                            setQuery(newQuery)
                        }}
                    />
                </Box>
            </Flex>

            <Flex
                direction="column"
                justifyContent="start"
                gap="15px"
                flex="1"
            >
                <Text
                    color="#666D80"
                    fontSize="14px"
                    fontWeight="500"
                >
                    Curso
                </Text>

                <Box
                    position="relative"
                >
                    <FilterSelect
                        value={valueCurso}
                        placeholder="Curso"
                        onChange={(e) => {
                            const newQuery = {
                                ...query,
                                curso: e?.value
                            }

                            setQuery(newQuery)
                        }}
                        options={cursosOptions(data)}
                        ui={{
                            filterSelectStyles: filterSelectStyles
                        }}
                    />

                    <Icon
                        position="absolute"
                        right="35px"
                        top="12px"
                        zIndex="999"
                        as={BiTrashAlt}
                        color="font"
                        boxSize="16px"
                        cursor="pointer"
                        _active={{ transform: "scale(0.9)" }}
                        display={query?.curso ? "block" : "none"}
                        onClick={(e: React.MouseEvent) => {
                            e.stopPropagation()

                            let newQuery = {}

                            if (query?.estado) newQuery = { estado: query?.estado }
                            if (query?.fechaInicioSup) newQuery = { fechaInicioSup: query?.fechaInicioSup }
                            if (query?.fechaFinInf) newQuery = { fechaFinInf: query?.fechaFinInf }

                            setQuery(newQuery)
                        }}
                    />
                </Box>
            </Flex>

            <Flex
                w="100%"
                direction="column"
                justifyContent="start"
                gap="15px"
                flex="2"
            >
                <Text
                    color="#666D80"
                    fontSize="14px"
                    fontWeight="500"
                >
                    Fechas
                </Text>

                <Flex
                    alignItems="center"
                    w="100%"
                    gap="15px"
                >
                    <Box
                        position="relative"
                    >
                        <FilterDateInput
                            locale="es"
                            value={query?.fechaInicioSup ? jsDateFormat({ date: query?.fechaInicioSup, format: "yyyy-MM-dd" }) as string : ""}
                            onChange={(e) => {
                                const newQuery = {
                                    ...query,
                                    fechaInicioSup: jsDateFormat({ date: e, format: "yyyy-MM-dd" }) as string
                                }

                                setQuery(newQuery)
                            }}
                        />

                        <Flex
                            position="absolute"
                            right="27px"
                            top="10px"
                            zIndex="999"
                            boxSize="40px"
                            alignItems="center"
                            justifyContent="center"
                            roundedTopRight="6px"
                            roundedBottomRight="6px"
                            display={query?.fechaInicioSup ? "block" : "none"}
                            cursor={query?.fechaInicioSup ? "pointer" : "not-allowed"}
                            onClick={(e: React.MouseEvent) => {
                                e.stopPropagation()

                                let newQuery = {}

                                if (query?.estado) newQuery = { estado: query?.estado }
                                if (query?.curso) newQuery = { curso: query?.curso }
                                if (query?.fechaFinInf) newQuery = { fechaFinInf: query?.fechaFinInf }

                                setQuery(newQuery)
                            }}
                        >
                            <Icon
                                as={BiTrashAlt}
                                color="font"
                                boxSize="16px"
                            />
                        </Flex>
                    </Box>

                    <Text
                        color="#515561"
                        fontSize="14px"
                        fontWeight="500"
                    >
                        -
                    </Text>

                    <Box
                        position="relative"
                    >
                        <FilterDateInput
                            locale="es"
                            value={query?.fechaFinInf ? jsDateFormat({ date: query?.fechaFinInf, format: "yyyy-MM-dd" }) as string : ""}
                            onChange={(e) => {
                                const newQuery = {
                                    ...query,
                                    fechaFinInf: jsDateFormat({ date: e, format: "yyyy-MM-dd" }) as string
                                }

                                setQuery(newQuery)
                            }}
                        />

                        <Flex
                            position="absolute"
                            right="27px"
                            top="10px"
                            zIndex="999"
                            boxSize="40px"
                            alignItems="center"
                            justifyContent="center"
                            roundedTopRight="6px"
                            roundedBottomRight="6px"
                            display={query?.fechaFinInf ? "block" : "none"}
                            cursor={query?.fechaFinInf ? "pointer" : "not-allowed"}
                            onClick={(e: React.MouseEvent) => {
                                e.stopPropagation()

                                let newQuery = {}

                                if (query?.estado) newQuery = { estado: query?.estado }
                                if (query?.curso) newQuery = { curso: query?.curso }
                                if (query?.fechaInicioSup) newQuery = { fechaInicioSup: query?.fechaInicioSup }

                                setQuery(newQuery)
                            }}
                        >
                            <Icon
                                as={BiTrashAlt}
                                color="font"
                                boxSize="16px"
                            />
                        </Flex>
                    </Box>
                </Flex>
            </Flex>
        </Flex >
    )
}