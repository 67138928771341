import { Route, Routes } from 'react-router-dom'
import Correcciones from './views/Index/Index'

export default function CorreccionesRoutes() {
    return (
        <Routes>
            <Route
                index
                element={<Correcciones />}
            />
        </Routes>
    )
}
