import { Flex, Skeleton, Text } from "@chakra-ui/react"

export const SkeletonAprendizajeCursos = () => {
    return (
        <Flex
            gap="40px"
            w={"100%"}
        >
            <Flex
                direction="column"
                gap="40px"
                w="72%"
            >
                <Flex
                    direction="column"
                    w="full"
                    gap="20px"
                >

                    <Text
                        fontSize="18px"
                        fontWeight="700"
                        color="font"
                    >
                        Cursos
                    </Text>

                    <Flex
                        w="full"
                    >
                        <Flex
                            w="100%"
                            direction="column"
                            gap="20px"
                        >
                            {Array.from({ length: 5 }, (_, i) => i)?.map((item: number) => (
                                <Skeleton
                                    key={item}
                                    minH="155px"
                                    h="155px"
                                    w="100%"
                                    rounded="20px"
                                />
                            ))}
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>

            <Flex
                mt="47px"
                direction="column"
                gap="20px"
                mb="30px"
                w="28%"
            />
        </Flex>
    )
}