import { Flex, Icon, Text, Link } from "@chakra-ui/react"
import { NotasInt } from "../../../interfaces/NotasInt"
import MarkdownPreview from "@uiw/react-markdown-preview"
import { truncate } from "lodash"
import { format } from "../../../shared/utils/functions/formatDate"
import { BiTime } from "react-icons/bi"
import { Link as RouterLink, useLocation, useParams } from "react-router-dom"

interface Props {
    notas: {
        data: NotasInt[]
    };
}

export const ListadoNotas = ({ notas }: Props) => {
    const params = useParams()

    return (
        <Flex
            direction="column"
            gap="20px"
            h="calc(100vh - 165px)"
            css={{
                '&::-webkit-scrollbar': {
                    display: 'none',
                },
                'scrollbarWidth': 'none',
                '-ms-overflow-style': 'none',
                scrollBehavior: 'smooth',
            }}
            overflow="auto"
            w="360px"
        >
            {notas?.data?.map((n: NotasInt) => (
                <Link
                    key={n?.id}
                    to={`${n?.id}`}
                    as={RouterLink}
                    display="flex"
                    flexDirection="column"
                    gap="20px"
                    mx="20px"
                    rounded="10px"
                    border="1px solid"
                    bg="purewhite"
                    borderColor={params["*"] && (params["*"] === n?.id) ? "main" : "#E6E6EA"}
                    p="20px"
                    boxShadow={params["*"] && (params["*"] === n?.id) ? "0px 4px 15px 0px rgba(199, 205, 255, 0.60)" : "none"}
                    cursor="pointer"
                    _hover={{ textDecoration: "none" }}
                >
                    <Text
                        fontSize="18px"
                        fontWeight="600"
                        letterSpacing="-0.9px"
                        color="font"
                    >
                        {n?.titulo}
                    </Text>

                    <MarkdownPreview
                        source={truncate(n?.contenido, { length: 85 })}
                        style={{
                            backgroundColor: "transparent",
                            fontFamily: "Inter",
                            color: "#959596",
                            fontSize: "14px",
                            fontWeight: "400",
                        }}
                        wrapperElement={{ "data-color-mode": "light" }}
                    />

                    <Flex
                        justify="space-between"
                        alignItems="center"
                    >
                        <Text
                            fontSize="12px"
                            fontWeight="400"
                            color="font"
                            bg="variant"
                            rounded="full"
                            px="10px"
                            py="5px"
                        >
                            {truncate(n?.leccion?.nombre, { length: 20 })}
                        </Text>

                        <Flex
                            alignItems="center"
                            gap="4px"
                        >
                            <Icon
                                as={BiTime}
                                boxSize="16px"
                                color={"#959596"}
                            />


                            <Text
                                fontSize="14px"
                                fontWeight="400"
                                color="#959596"
                            >
                                {format({ date: n?.createdAt, isDateTime: true, customFormat: "dd/MM HH:mm" })}
                            </Text>
                        </Flex>
                    </Flex>
                </Link>
            ))}
        </Flex>
    )
}