const stringIsTime = (time: string) => /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(time);

/** Comparamos dos tiempos en el formato "HH:mm".
 *
 *
 * Si timeA === timeB, devolvemos 0
 *
 * Si timeA > timeB, devolvemos 1
 *
 * Si timeA < timeB, devolvemos -1
 */
const compareTimes = (timeA: string, timeB: string): -1 | 0 | 1 => {
  if (!timeA || !stringIsTime(timeA)) throw new Error(`El primer parámetro, ${timeA}, no está en formato HH:mm.`);
  if (!timeB || !stringIsTime(timeB)) throw new Error(`El segundo parámetro, ${timeB}, no está en formato HH:mm.`);

  const [hourA, minuteA] = timeA.split(':');
  const [hourB, minuteB] = timeB.split(':');

  /** Si ambos tiempos son el mismo, devolvemos 0 */
  if (timeA) if (hourA === hourB && minuteA === minuteB) return 0;

  const dateA = new Date(2022, 1, 1, +hourA, +minuteA),
    dateB = new Date(2022, 1, 1, +hourB, +minuteB);

  if (dateA.getTime() > dateB.getTime()) return 1;
  else if (dateA.getTime() < dateB.getTime()) return -1;
  else return 0;
};

export { compareTimes };