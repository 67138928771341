import { Box, Flex, Icon, Image, Skeleton, Text } from "@chakra-ui/react";
import { MatriculasInt } from "../../../interfaces/MatriculasInt";
import { useData } from "../../../shared/hooks/useData";
import { EndpointTypes } from "../../../shared/utils/types/EndpointTypes";
import { EntregablesInt } from "../../../interfaces/Entregables";
import { DateTime } from "luxon";
import { EntregableEstadoEnum } from "../../../shared/utils/types/EntregableEstadoEnum";
import { IoHourglassOutline } from "react-icons/io5";
import { BiCheck, BiX } from "react-icons/bi";
import sinEntregables from "../../../assets/img/entregablesVacio.png"

interface Props {
    matricula: MatriculasInt | undefined;
}

export const CardEntregables = ({
    matricula
}: Props) => {
    const { data: entregables, loading } = useData({
        endpoint: EndpointTypes.ENTREGABLES,
        query: {
            matricula: matricula?.id as string,
            sortBy: 'createdAt',
            order: 'desc',
            limit: 100
        },
        ignoreRequest: !matricula
    })

    return (
        (matricula && !loading) ?
            <Flex
                p="20px"
                border="1px solid"
                borderColor="#DFE1E6"
                rounded="16px"
                bg="purewhite"
                gap="16px"
                direction="column"
                minH="364px"
            >
                <Text
                    color="font"
                    fontSize="16px"
                    fontWeight="500"
                >
                    Últimos entregables
                </Text>

                <Flex
                    direction="column"
                    h="100%"
                >
                    <Flex
                        py="8px"
                        px="16px"
                        bg="#F8F9FB"
                        w="100%"
                    >
                        <Text
                            w="40%"
                            color="#666D80"
                            fontSize="14px"
                            fontWeight="500"
                        >
                            Lección
                        </Text>

                        <Text
                            w="20%"
                            color="#666D80"
                            fontSize="14px"
                            fontWeight="500"
                        >
                            Nota
                        </Text>

                        <Text
                            w="30%"
                            color="#666D80"
                            fontSize="14px"
                            fontWeight="500"
                        >
                            Estado
                        </Text>

                        <Flex
                            w="10%"
                        />
                    </Flex>

                    {entregables?.meta?.total === 0 ?
                        <Flex
                            w="100%"
                            h="100%"
                            justifyContent="center"
                            alignItems="center"
                            gap="20px"
                        >
                            <Image
                                src={sinEntregables}
                                w="285px"
                                h="140px"
                            />

                            <Text
                                color="font"
                                fontSize="21px"
                                fontWeight="600"
                            >
                                Aún no hay entregables
                            </Text>
                        </Flex>
                        :
                        entregables?.data?.slice(0,3)?.map((e: EntregablesInt, index: number) => (
                            <>
                                <Flex
                                    key={e?.id}
                                    py="12px"
                                    px="16px"
                                    w="100%"
                                    align="center"
                                >
                                    <Flex
                                        w="40%"
                                        gap="10px"
                                        direction="column"
                                    >
                                        <Text
                                            fontSize="14px"
                                            fontWeight="600"
                                            color="font"
                                        >
                                            {e?.leccion?.nombre}
                                        </Text>

                                        <Text
                                            color="#666D80"
                                            fontSize="12px"
                                            fontWeight="400"
                                        >
                                            {e?.fechaEntrega && DateTime.fromISO(e.fechaEntrega).setLocale('es').toFormat("dd 'de' MMMM")}
                                            {e?.correccion?.createdAt && ` • ${DateTime.fromISO(e.correccion.createdAt).setLocale('es').toFormat("dd 'de' MMMM")}`}
                                        </Text>
                                    </Flex>

                                    <Text
                                        w="20%"
                                        color="#666D80"
                                        fontSize="12px"
                                        fontWeight="500"
                                    >
                                        {e?.correccion ? `${e?.correccion?.puntuacion?.toFixed(0)}%` : "-"}
                                    </Text>

                                    <Flex
                                        w="30%"
                                    >
                                        <Flex
                                            rounded="6px"
                                            border="1px solid"
                                            borderColor="#DFE1E6"
                                            h="fit-content"
                                            w="fit-content"
                                            py="4px"
                                            px="8px"
                                            color="#666D80"
                                            fontSize="12px"
                                            fontWeight="500"
                                            alignItems="center"
                                            gap="4px"
                                        >
                                            <Icon
                                                as={
                                                    e?.estado === EntregableEstadoEnum?.CORRECTO ? BiCheck :
                                                        e?.estado === EntregableEstadoEnum?.ERROR ? BiX :
                                                            IoHourglassOutline
                                                }
                                                boxSize="14px"
                                                color={
                                                    e?.estado === EntregableEstadoEnum?.CORRECTO ? "#40C4AA" :
                                                        e?.estado === EntregableEstadoEnum?.ERROR ? "fail" :
                                                            "#FF784B"
                                                }
                                            />

                                            <Text>
                                                {
                                                    e?.estado === EntregableEstadoEnum?.CORRECTO ? "Superado" :
                                                        e?.estado === EntregableEstadoEnum?.ERROR ? "No superado" :
                                                            "Pendiente correccion"
                                                }
                                            </Text>
                                        </Flex>
                                    </Flex>

                                    <Flex
                                        w="10%"
                                    />
                                </Flex>

                                <Box
                                    w="100%"
                                    border="1px dashed"
                                    borderColor="#DFE1E6"
                                    display={(index + 1) === entregables?.meta?.total ? "none" : "block"}
                                />
                            </>
                        ))}
                </Flex>
            </Flex>
            :
            <Skeleton
                w="100%"
                rounded="16px"
                h="364px"
            />
    )
}