import { Flex, Icon, Text } from "@chakra-ui/react";
import { HiOutlineBookOpen } from "react-icons/hi";

interface Props {
    label: string;
    value: string | number;
    icon: any;
}


export default function RowProgreso({ label, value, icon }: Props) {
    return (
        <Flex
            alignItems="center"
            justifyContent="space-between"
        >
            <Flex
                gap="12px"
                alignItems="center"
            >
                <Flex
                    bg="variant"
                    p="8px"
                    rounded="full"
                >
                    <Icon
                        boxSize="18px"
                        color="main"
                        as={icon}
                    />
                </Flex>

                <Text
                    fontSize="14px"
                    fontWeight="400"
                    color="font_variant"
                >
                    {label}
                </Text>
            </Flex>

            <Text
                fontSize="16px"
                fontWeight="700"
                color="font"
            >
                {value}
            </Text>
        </Flex>
    )
}
