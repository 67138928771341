import { Box, Flex, Icon, Image, Text } from "@chakra-ui/react";
import { RutaInt } from "../../../interfaces/RutasInt"
import IconFullStack from "../../../assets/img/IconFullStack.png"
import { CursosInt } from "../../../interfaces/CursosInt";
import { defaultIcon } from "../../../theme";
import { HiArrowNarrowRight } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

interface Props {
    ruta: RutaInt;
}

export const CardRuta = ({ ruta }: Props) => {
    const navigate = useNavigate()

    return (
        <Flex
            cursor="pointer"
            transition="all 0.2s"
            _hover={{
                transform: "scale(1.01)",
            }}
            onClick={() => navigate(`${ruta?.slug}`)}
            border="1px solid"
            borderColor="#E6E6EA"
            h="230px"
            w="full"
            gap="22px"
            rounded="20px"
            bg="purewhite"
            direction="column"
        >
            <Flex
                px="32px"
                pt="32px"
            >
                <Flex
                    alignItems="center"
                    gap="24px"
                >
                    {/* <Flex
                        bg="#DCF7FF"
                        rounded="10px"
                        boxSize="64px"
                    >
                        <Image
                            src={IconFullStack}
                        />
                    </Flex> */}

                    <Flex
                        direction="column"
                    >
                        <Text
                            color="font"
                            fontSize="24px"
                            fontWeight="700"
                        >
                            {ruta?.nombre}
                        </Text>

                        <Text
                            color="#A5A8B3"
                            fontSize="16px"
                            fontWeight="600"
                            letterSpacing="-0.16px"
                            lineHeight="100%"
                        >
                            {ruta?.cursos?.length || 0} cursos
                        </Text>
                    </Flex>
                </Flex>
            </Flex>

            <Flex
                alignItems="center"
                pb="32px"
                px="32px"
                pt="10px"
                color="font"
                overflow="auto"
                gap="60px"
                css={{
                    '&::-webkit-scrollbar': {
                        display: 'none',
                    },
                    'scrollbarWidth': 'none',
                    '-ms-overflow-style': 'none',
                    scrollBehavior: 'smooth',
                }}
            >
                {ruta?.cursos?.map((curso: CursosInt, index: number) => (
                    <Flex
                        alignItems="center"
                    >
                        <Flex
                            key={index}
                            align="center"
                            gap="15px"
                            bg="purewhite"
                            shadow={"0px 4.569px 12.185px 0px rgba(74, 58, 255, 0.19)"}
                            py="10px"
                            px="15px"
                            rounded="10px"
                            minW="100%"
                        >
                            <Image
                                src={curso?.icono?.url || `data:image/svg+xml;utf8,${defaultIcon}`}
                                boxSize="35px"
                            />

                            <Text
                                color="#515561"
                                fontSize="15px"
                                fontWeight="400"
                                w="full"
                            >
                                {curso?.nombre}
                            </Text>
                        </Flex>

                        {index < ruta?.cursos?.length - 1 &&
                            <Icon
                                as={HiArrowNarrowRight}
                                mx="15px"
                                boxSize="24px"
                                color="font"
                            />
                        }
                    </Flex>
                ))}
            </Flex>
        </Flex>
    )
}