export enum EntregableEstadoEnum {
    PENDIENTE_ENTREGA = 'pendiente_entrega',
    PENDIENTE_CORRECCION = 'pendiente_correccion',
    CORRECTO = 'correcto',
    ERROR = 'error',
}

export enum ModeEnumEntregable {
    ENTREGA,
    CORRECCION,
    FINALIZADO,
}

export enum TipoEntregaEnum {
    ENUNCIADO = 'enunciado',
    REENTREGA = 'reentrega'
}