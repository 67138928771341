
import { PreguntaForoInt, RespuestaForoInt } from "../../interfaces/ForoInt";
import _axios from "../services/http.service";
import tenantInstance from "../services/tenantInstance.service";

const { tenant, client } = await tenantInstance();

export const addForoTema = async (newTema: any) => {

    return await _axios.post(
        `${tenant}/${client}/foro/temas`,
        newTema,
        {}
    ).then((response: any) => response);
};

export const addForoPregunta = async ({
    data
}: {
    data: {
        grupoId: string | null;
        titulo: string | null;
        contenido: string | null;
        userId: string;
        fijado: boolean;
    }
}) => {

    return await _axios.post(
        `${tenant}/${client}/foro/preguntas`,
        data,
        {}
    ).then((response: any) => response);
};

export const addForoRespuesta = async ({ data }: { data: RespuestaForoInt }) => {

    return await _axios.post(
        `${tenant}/${client}/foro/respuestas`,
        data,
        {}
    ).then((response: any) => response);
};