import { Button, Flex, Icon, Skeleton, SkeletonText, Text } from "@chakra-ui/react";
import { BiDownload, BiMenu, BiPlay } from "react-icons/bi";
import { HiOutlineClock } from "react-icons/hi";

export const SkeletonInformation = () => {
    return (
        <Flex
            w="full"
        >
            <Flex
                direction="column"
                p="40px"
                maxW="500px"
                gap="30px"
            >
                <Flex w="fit">
                    <Skeleton
                        w="100px"
                        h="100px"
                        rounded="20px"
                        mr="-12px"
                        zIndex="10"
                    />

                    <Flex
                        bg="purewhite"
                        p="20px"
                        rounded="20px"
                        border="1px solid"
                        borderColor="border"
                        my="5px"
                    >
                        <Flex
                            direction="column"
                            w="250px"
                        >
                            <Flex
                                w="full"
                                justify="space-between"
                                align="center"
                                gap={"20px"}
                            >
                                <Skeleton w="80%" h="31px" />

                                <Skeleton w="20px" h="20px" />
                            </Flex>

                            <Skeleton h="8px" w="full" mt="5px" />
                        </Flex>
                    </Flex>
                </Flex>

                <Flex
                    direction="column"
                    gap="25px"
                >
                    <Flex
                        direction="column"
                        gap="10px"
                    >
                        <Text
                            fontSize="14px"
                            fontWeight="600"
                            color="font"
                        >
                            CURSO
                        </Text>

                        <Skeleton height="32px" width="80%" />

                        <Flex
                            gap="12px"
                            align="center"
                        >
                            <Flex
                                gap="5px"
                                align="center"
                            >
                                <Icon
                                    as={BiMenu}
                                    boxSize="20px"
                                    color="font"
                                />

                                <Skeleton height="20px" width="80px" />
                            </Flex>

                            <Flex
                                gap="5px"
                                align="center"
                            >
                                <Icon
                                    as={HiOutlineClock}
                                    boxSize="20px"
                                    color="font"
                                />

                                <Skeleton height="20px" width="60px" />
                            </Flex>
                        </Flex>
                    </Flex>

                    <Skeleton height="200px" width="100%" />

                    <Flex
                        gap="25px"
                    >
                        <Button
                            bg="light_grey"
                            color="font"
                            fontSize="14px"
                            rightIcon={
                                <Icon
                                    as={BiDownload}
                                    boxSize="20px"
                                    color="font"
                                />
                            }
                            isDisabled
                        >
                            Descargar CV del tutor
                        </Button>

                        <Button
                            bg="light_grey"
                            color="font"
                            fontSize="14px"
                            rightIcon={
                                <Icon
                                    as={BiDownload}
                                    boxSize="20px"
                                    color="font"
                                />
                            }
                            isDisabled
                        >
                            Ver guía didáctica
                        </Button>
                    </Flex>
                </Flex>

                <Flex
                    direction="column"
                    gap="16px"
                >
                    <Text
                        fontSize="14px"
                        fontWeight="700"
                        color="font"
                    >
                        Tutor del curso
                    </Text>

                    <Flex
                        gap="12px"
                    >
                        <Skeleton
                            rounded="full"
                            w="45px"
                            h="45px"
                        />

                        <Flex
                            direction="column"
                            gap="5px"
                        >
                            <Skeleton height="24px" width="150px" />
                            <Skeleton height="21px" width="120px" />
                        </Flex>
                    </Flex>
                </Flex>


                <Flex
                    direction="column"
                    gap="16px"
                >
                    <Text
                        fontSize="14px"
                        fontWeight="700"
                        color="font"
                    >
                        Requisitos del curso
                    </Text>

                    <Flex
                        direction="column"
                        gap="16px"
                    >
                        <Skeleton
                            rounded="10px"
                            h="83px"
                            w="100%"
                        />

                        <Skeleton
                            rounded="10px"
                            h="83px"
                            w="100%"
                        />

                        <Skeleton
                            rounded="10px"
                            h="83px"
                            w="100%"
                        />
                    </Flex>
                </Flex>
            </Flex>

            <Flex
                w="1px"
                h="100%"
                bg="border"
                py="40px"
            />

            <Flex
                p="40px"
                w="full"
            >
                <Flex
                    direction="column"
                    gap="16px"
                    w="100%"
                >
                    <Flex
                        justify="space-between"
                        align="center"
                        w="full"
                    >
                        <Text
                            fontSize="18px"
                            fontWeight="700"
                            color="font"
                        >
                            Contenido del curso
                        </Text>

                        <Button
                            bg="main"
                            color="purewhite"
                            rounded="10px"
                            fontWeight="700"
                            rightIcon={<Icon as={BiPlay} />}
                            isDisabled
                        >
                            Continuar curso
                        </Button>
                    </Flex>

                    <Flex
                        direction="column"
                        gap="14px"
                        w="full"
                    >
                        {Array.from({ length: 5 }, (_, i) => i)?.map((item: number) => (
                            <Skeleton
                                key={item}
                                h="87px"
                                w="100%"
                                rounded="20px"
                            />
                        ))}
                    </Flex>

                </Flex>
            </Flex>
        </Flex>
    );
}