import { AxiosResponse } from "axios";
import _axios from "../services/http.service";
import tenantInstance from "../services/tenantInstance.service";
import { EndpointTypes } from "../utils/types/EndpointTypes";

const { tenant, client } = await tenantInstance();


export const endExamen = async ({
    id,
    respuestas,
    matriculaId
}: {
    id: string;
    respuestas: string[];
    matriculaId: string;
}) => {

    return await _axios.post(
        `${tenant}/${client}${EndpointTypes.END_EXAMENES}/${id}`,
        {
            respuestas,
            matriculaId
        }
    )
        .then((response) => response)
};
