import { Route, Routes } from "react-router-dom"
import { Dashboard } from "./views/Index/Index"

export const DashboardRoutes = () => {
    return (
        <Routes>
            <Route
                index
                element={<Dashboard />}
            />
        </Routes>
    )
}