import { Route, Routes } from 'react-router-dom'
import Facturacion from './views/Table/Index'

export default function FacturacionRoutes() {
    return (
        <Routes>
            <Route
                index
                element={<Facturacion />}
            />
        </Routes>
    )
}
