import { Route, Routes } from "react-router-dom"
import { InformationEvaluaciones } from "./views/Information/Information"
import { Evaluaciones } from "./views/Index/Index"

export const EvaluacionesRoutes = () => {

    return (
        <Routes>
            <Route
                index
                element={
                    <Evaluaciones />
                }
            />

            <Route
                path='/:slug'
                element={
                    <InformationEvaluaciones />
                }
            />
        </Routes>
    )
}