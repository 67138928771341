import { Button, Flex, Icon, Image, Text, useDisclosure } from "@chakra-ui/react"
import { useParams } from "react-router-dom"
import { useDataId } from "../../../../shared/hooks/useDataId"
import { EndpointTypes } from "../../../../shared/utils/types/EndpointTypes"
import { Topbar } from "../../../../shared/components/Topbar/Topbar"
import { BiCheckSquare, BiRevision, BiRightArrowAlt, BiTimeFive } from "react-icons/bi"
import { InformationExamenSkeleton } from "../../skeletons/Information"
import { fromSecondToMin } from "../../../../shared/utils/functions/formatters"

export const InformationEvaluaciones = () => {
    const { slug } = useParams()

    const { data: examen, loading } = useDataId({
        endpoint: EndpointTypes.EXAMENES,
        id: slug
    })

    return (
        <Topbar
            title={`Examen`}
            searchBar
        >
            {loading ?
                <InformationExamenSkeleton />
                :
                <Flex
                    p="40px"
                    direction="column"
                    gap="30px"
                    h="fit-content"
                >
                    <Flex
                        gap="70px"
                    >
                        <Flex
                            direction="column"
                            flex="1"
                            gap="30px"
                        >
                            <Flex
                                direction="column"
                                gap="18px"
                            >
                                <Text
                                    fontSize="21px"
                                    fontWeight="700"
                                    color="font"
                                >
                                    {examen?.nombre}
                                </Text>

                                <Text
                                    color="font"
                                    fontSize="15px"
                                    fontWeight="400"
                                >
                                    {examen?.descripcion}
                                </Text>
                            </Flex>

                            <Flex
                                direction="column"
                                gap="10px"
                            >
                                <Flex
                                    p="15px"
                                    bg="purewhite" border="1px solid"
                                    borderColor="#E6E6EA"

                                    rounded="20px"
                                    gap="18px"
                                    alignItems="center"
                                >
                                    <Flex
                                        bg="variant"
                                        rounded="10px"
                                        p="10px"
                                        h="fit-content"
                                    >
                                        <Icon
                                            as={BiCheckSquare}
                                            color="main"
                                            boxSize="38px"
                                        />
                                    </Flex>

                                    <Flex
                                        direction="column"
                                        gap="8px"
                                    >
                                        <Text
                                            color="#8C909C"
                                            fontWeight="700"
                                            fontSize="12px"
                                        >
                                            Número de preguntas
                                        </Text>

                                        <Text
                                            color="font"
                                            fontWeight="700"
                                            fontSize="18px"
                                        >
                                            {examen?.preguntas?.length}
                                        </Text>
                                    </Flex>
                                </Flex>

                                <Flex
                                    p="15px"
                                    bg="purewhite"
                                    border="1px solid" borderColor="#E6E6EA"

                                    rounded="20px"
                                    gap="18px"
                                    alignItems="center"
                                >
                                    <Flex
                                        bg="variant"
                                        rounded="10px"
                                        p="10px"
                                        h="fit-content"
                                    >
                                        <Icon
                                            as={BiTimeFive}
                                            color="main"
                                            boxSize="38px"
                                        />
                                    </Flex>

                                    <Flex
                                        direction="column"
                                        gap="8px"
                                    >
                                        <Text
                                            color="#8C909C"
                                            fontWeight="700"
                                            fontSize="12px"
                                        >
                                            Tiempo disponible
                                        </Text>

                                        <Text
                                            color="font"
                                            fontWeight="700"
                                            fontSize="18px"
                                        >
                                            {fromSecondToMin(examen?.duracion).replace('m', '  min')}
                                        </Text>
                                    </Flex>
                                </Flex>

                                <Flex
                                    p="15px"
                                    bg="purewhite"
                                    border="1px solid"
                                    borderColor="#E6E6EA"
                                    rounded="20px"
                                    gap="18px"
                                    alignItems="center"
                                >
                                    <Flex
                                        bg="variant"
                                        rounded="10px"
                                        p="10px"
                                        h="fit-content"
                                    >
                                        <Icon
                                            as={BiRevision}
                                            color="main"
                                            boxSize="38px"
                                        />
                                    </Flex>

                                    <Flex
                                        direction="column"
                                        gap="8px"
                                    >
                                        <Text
                                            color="#8C909C"
                                            fontWeight="700"
                                            fontSize="12px"
                                        >
                                            Intentos restantes
                                        </Text>

                                        <Text
                                            color="font"
                                            fontWeight="700"
                                            fontSize="18px"
                                        >
                                            3/{examen?.numIntentos}
                                        </Text>
                                    </Flex>
                                </Flex>

                            </Flex>
                        </Flex>

                        <Flex
                            flex="1"
                            p="15px"
                            bg="purewhite"
                            rounded="20px"
                            boxShadow='rgba(64, 85, 128, 0.04) 0px 4px 12px'
                        >
                            <Image
                                src={examen?.imagen?.url}
                                w="100%"
                                objectFit="cover"
                                rounded="20px"
                            />
                        </Flex>
                    </Flex>



                    <Flex
                        w="100%"
                        justifyContent="start"
                    >
                        <Button
                            color="purewhite"
                            bg="secondary"
                            fontSize="16px"
                            fontWeight="700"
                            h="fit-content"
                            w="fit-content"
                            rounded="14px"
                            px="20px"
                            py="10px"
                            rightIcon={
                                <Icon as={BiRightArrowAlt} boxSize="24px" />
                            }
                        >
                            Empezar examen
                        </Button>
                    </Flex>
                </Flex >
            }
        </Topbar>
    )
}