import { Flex, Text } from "@chakra-ui/react";
import { ProgressBar } from "../../../../shared/components/Progress/ProgressBar";

interface Props {
  stat: any;
  isPuntuacion?: boolean;
  isActividades?: boolean;
  isEjercicios?: boolean;
}

export default function CardRequisito({ stat, isPuntuacion, isActividades, isEjercicios }: Props) {
  return (
    <Flex
      bg="purewhite"
      rounded="10px"
      p="20px"
      direction="column"
      gap="8px"
      border="1px solid"
      borderColor="border"
      h="83px"
    >
      <Flex
        justify="space-between"
        align="center"
        mt="-7px"
      >
        <Text
          fontSize="13px"
          fontWeight="600"
          color="dark_grey"
          textTransform="uppercase"
        >
          {isPuntuacion && "Tener más de un 75% de nota final"}
          {isActividades && "Completar el 75% de las actividades"}
          {isEjercicios && "Superar el 75% de los ejercicios"}
        </Text>

        <Text
          fontSize="21px"
          fontWeight="700"
          color="font"
        >
          {stat}%
        </Text>
      </Flex>

      <ProgressBar
        progress={stat}
        bg="#F0F0F3"
        bgProgress="main"
        height="8px"
      />
    </Flex>
  )
}
