export const uiSchema = {
    valoracionAspectos: {
        organizacionCurso: {
            informacionCurso: {
                "ui:widget": "radio",
                "ui:options": {
                    inline: true,
                    style: {
                        display: "flex",
                        flexDirection: "row",
                    },
                },
            },
            informacionAlumnos: {
                "ui:widget": "radio",
                "ui:options": {
                    inline: true,
                    style: {
                        display: "flex",
                        flexDirection: "row",
                    },
                },
            }
        },
        contenidoMetodologia: {
            contenido: {
                "ui:widget": "radio",
                "ui:options": {
                    inline: true,
                    style: {
                        display: "flex",
                        flexDirection: "row",
                    },
                },
            },
            combinacion: {
                "ui:widget": "radio",
                "ui:options": {
                    inline: true,
                    style: {
                        display: "flex",
                        flexDirection: "row",
                    },
                },
            }
        },
        duracionHorario: {
            duracion: {
                "ui:widget": "radio",
                "ui:options": {
                    inline: true,
                    style: {
                        display: "flex",
                        flexDirection: "row",
                    },
                },
            },
            horario: {
                "ui:widget": "radio",
                "ui:options": {
                    inline: true,
                    style: {
                        display: "flex",
                        flexDirection: "row",
                    },
                },
            }
        },
        formadores: {
            seguimiento: {
                "ui:widget": "radio",
                "ui:options": {
                    inline: true,
                    style: {
                        display: "flex",
                        flexDirection: "row",
                    },
                },
            },
            temas: {
                "ui:widget": "radio",
                "ui:options": {
                    inline: true,
                    style: {
                        display: "flex",
                        flexDirection: "row",
                    },
                },
            },
        },
        tutores: {
            material: {
                "ui:widget": "radio",
                "ui:options": {
                    inline: true,
                    style: {
                        display: "flex",
                        flexDirection: "row",
                    },
                },
            },
            mediosActualizados: {
                "ui:widget": "radio",
                "ui:options": {
                    inline: true,
                    style: {
                        display: "flex",
                        flexDirection: "row",
                    },
                },
            }
        },
        mediosDidacticos: {
            documentacion: {
                "ui:widget": "radio",
                "ui:options": {
                    inline: true,
                    style: {
                        display: "flex",
                        flexDirection: "row",
                    },
                },
            },
            medios: {
                "ui:widget": "radio",
                "ui:options": {
                    inline: true,
                    style: {
                        display: "flex",
                        flexDirection: "row",
                    },
                },
            }
        },
        instalaciones: {
            instalacion: {
                "ui:widget": "radio",
                "ui:options": {
                    inline: true,
                    style: {
                        display: "flex",
                        flexDirection: "row",
                    },
                },
            },
            medios: {
                "ui:widget": "radio",
                "ui:options": {
                    inline: true,
                    style: {
                        display: "flex",
                        flexDirection: "row",
                    },
                },
            }
        },
        modalidad: {
            material: {
                "ui:widget": "radio",
                "ui:options": {
                    inline: true,
                    style: {
                        display: "flex",
                        flexDirection: "row",
                    },
                },
            },
            tutorias: {
                "ui:widget": "radio",
                "ui:options": {
                    inline: true,
                    style: {
                        display: "flex",
                        flexDirection: "row",
                    },
                },
            }
        },
        evaluacion: {
            pruebas: {
                "ui:widget": "radio",
                "ui:options": {
                    inline: true,
                    style: {
                        display: "flex",
                        flexDirection: "row",
                    },
                },
            },
            acreditacion: {
                "ui:widget": "radio",
                "ui:options": {
                    inline: true,
                    style: {
                        display: "flex",
                        flexDirection: "row",
                    },
                },
            }
        },
        valoracion: {
            incorporacion: {
                "ui:widget": "radio",
                "ui:options": {
                    inline: true,
                    style: {
                        display: "flex",
                        flexDirection: "row",
                    },
                },
            },
            habilidades: {
                "ui:widget": "radio",
                "ui:options": {
                    inline: true,
                    style: {
                        display: "flex",
                        flexDirection: "row",
                    },
                },
            },
            posibilidades: {
                "ui:widget": "radio",
                "ui:options": {
                    inline: true,
                    style: {
                        display: "flex",
                        flexDirection: "row",
                    },
                },
            },
            conocimiento: {
                "ui:widget": "radio",
                "ui:options": {
                    inline: true,
                    style: {
                        display: "flex",
                        flexDirection: "row",
                    },
                },
            },
            desarrollo: {
                "ui:widget": "radio",
                "ui:options": {
                    inline: true,
                    style: {
                        display: "flex",
                        flexDirection: "row",
                    },
                },
            },
        },
        satisfaccion: {
            "ui:widget": "radio",
            "ui:options": {
                inline: true,
                style: {
                    display: "flex",
                    flexDirection: "row",
                },
            },
        },
    }
};
