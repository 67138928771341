import { Button, Flex, Icon, Text, Tooltip } from "@chakra-ui/react"
import { BiCheck, BiCheckCircle, BiEdit } from "react-icons/bi"

interface Props {
    handleCuestionario: () => void;
    isDisabled?: boolean;
    tooltip: string;
    loading?: boolean;
    isCompleted?: boolean;
}
export const CardCuestionario = ({
    isDisabled = false,
    tooltip,
    handleCuestionario,
    loading = false,
    isCompleted = false,
}: Props) => {

    return (
        <Tooltip
            hasArrow
            label={tooltip}
            color="purewhite"
            fontSize="14px"
            fontWeight="500px"
            rounded="8px"
            py="10px"
            px="10px"
            bg="secondary"
            placement="bottom"
            isDisabled={!isDisabled}
        >
            <Flex
                py="16px"
                px="20px"
                rounded="10px"
                border="1px solid"
                borderColor="#EEEFF2"
                boxShadow="0px 1px 2px 0px rgba(228, 229, 231, 0.24)"
                gap="8px"
                opacity={isDisabled ? 0.5 : 1}
                alignItems="center"
                cursor={isDisabled ? "not-allowed" : "default"}
            >
                <Icon
                    as={BiEdit}
                    boxSize="20px"
                    color="font"
                />

                <Text
                    color="font"
                    fontSize="15px"
                    fontWeight="400"
                >
                    Realizar cuestionario
                </Text>

                <Flex
                    ml="auto"
                >
                    {!isCompleted ?
                        <Button
                            color="purewhite"
                            fontSize="12px"
                            fontWeight="500"
                            py="10px"
                            px="16px"
                            bg="main"
                            rounded="6px"
                            h="fit-content"
                            w="80px"
                            isDisabled={isDisabled}
                            isLoading={loading}
                            onClick={(e: React.MouseEvent) => {
                                e.stopPropagation()

                                handleCuestionario()
                            }}
                        >
                            Realizar
                        </Button>
                        :
                        <Icon 
                            as={BiCheck}
                            color="main"
                            boxSize="28px"
                        />
                    }
                </Flex>
            </Flex>
        </Tooltip>
    )
}