import { Route, Routes } from "react-router-dom"
import { Rutas } from "./views/Index/Index"
import { InformationRuta } from "./views/Information/InformationRuta"

export const RutaRouter = () => {
    return (
        <Routes>
            <Route
                index
                element={
                    <Rutas />
                }
            />

            <Route
                path="/:slug"
                element={
                    <InformationRuta />
                }
            />
        </Routes>
    )
}