import { DateTime } from 'luxon';

export const parseDate = (date: Date | undefined): string | null => date ? DateTime.fromISO(date.toISOString()).toFormat('yyyy-MM-dd') : null;

export const format = ({
    date,
    isDateTime = false,
    customFormat,
}: {
    date: string | undefined;
    isDateTime?: boolean;
    customFormat?: string;
}): string | undefined => {
    if (!date) return undefined;

    if (customFormat) {
        return DateTime.fromISO(date).toFormat(customFormat);
    }

    if (isDateTime) {
        return DateTime.fromISO(date).toFormat("yyyy-MM-dd HH:mm:ss");
    }

    return DateTime.fromISO(date).toFormat("yyyy-MM-dd");
}

export const jsDateFormat = ({
    date,
    format,
}: {
    date: string,
    format: string
}): string | undefined => {
    const jsDate = new Date(date);

    return DateTime.fromJSDate(jsDate).toFormat(format);
}