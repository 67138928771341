import { Box, Image, Text, Flex, Icon, Skeleton } from '@chakra-ui/react';
import { FaListUl, FaClock } from 'react-icons/fa';
import { ProgressBar } from '../../../../shared/components/Progress/ProgressBar';
import { CursosInt, LeccionInt } from '../../../../interfaces/CursosInt';
import { MatriculasInt } from '../../../../interfaces/MatriculasInt';
import cursoPortada from "../../../../assets/img/CursoPortada.png"
import { useNavigate } from 'react-router-dom';
import { useData } from '../../../../shared/hooks/useData';
import { EndpointTypes } from '../../../../shared/utils/types/EndpointTypes';
import { useDataId } from '../../../../shared/hooks/useDataId';
import { onContinueLeccion } from '../../../../shared/utils/functions/validateProgress';
import { useState } from 'react';
import { CardContador } from './CardContador';
import { motion } from 'framer-motion';


interface Props {
  curso: CursosInt;
  grupoSlug: string | undefined;
  matricula: MatriculasInt;
}

export const CardCurso = ({
  curso,
  matricula,
  grupoSlug,
}: Props) => {
  const navigate = useNavigate();
  const [showCard, setShowCard] = useState<boolean>(false)

  const { data: progreso } = useData({
    endpoint: EndpointTypes.PROGRESOS,
    query: {
      curso: matricula?.grupo?.cursoId!,
      matricula: matricula?.id!
    },
    ignoreRequest: !matricula
  })

  const { data: infoCurso } = useDataId({
    endpoint: EndpointTypes.CURSOS,
    id: curso?.slug
  })

  const modulo = onContinueLeccion(progreso?.data[0]?.progresoLecciones, infoCurso)?.modulo;
  const leccion = modulo?.lecciones?.find((leccion: LeccionInt) =>
    leccion.slug === onContinueLeccion(progreso?.data[0]?.progresoLecciones, infoCurso).leccion?.slug
  );

  const url = grupoSlug ? `/aprendizaje/${curso?.slug}?grupo=${grupoSlug}` : `/aprendizaje/${curso?.slug}`

  const totalProgreso = (progreso?.data[0]?.leccionesCompletadas / infoCurso?.meta?.totalLecciones) * 100

  const MotionFlex = motion(Flex);

  return (
    <Flex
      position="relative"
    >
      <Flex
        borderWidth="1px"
        rounded="20px"
        bg="purewhite"
        p="18px"
        display="flex"
        gap="20px"
        cursor="pointer"
        transition="all 0.2s"
        _hover={{
          transform: "scale(1.01)",
        }}
        onClick={() => navigate(url)}
        w="full"
        h="fit"
        onMouseEnter={() => setShowCard(true)}
        onMouseLeave={() => setShowCard(false)}
        overflow="auto"
      >

        <Image
          src={curso?.imagen?.url ? curso?.imagen?.url : cursoPortada}
          alt={curso?.nombre}
          w="250px"
          maxH="115px"
          objectFit="cover"
          borderRadius="md"
        />

        <Box
          ml={4}
          flex="1"
        >

          <Flex
            justify="space-between"
            direction="column"
          >

            <Flex
              direction="column"
              gap="5px"
            >

              <Text
                fontSize="18px"
                fontWeight="600"
              >
                {curso?.nombre}
              </Text>

              {totalProgreso > 95 ?
                <Text>
                  Curso finalizado
                </Text>
                :
                leccion?.nombre ?
                  <Text
                    fontSize="14px"
                    fontWeight="600"
                  >
                    <Text as="span" color="font" fontWeight="400">Siguiente lección: </Text>{leccion?.nombre}
                  </Text>
                  :
                  <Skeleton height="21px" borderRadius="5px" w="400px" />

              }



            </Flex>

            {infoCurso?.modulos?.length ?
              <Flex
                justifyContent="space-between"
                gap="40px"
                mt={5}
              >

                <Flex
                  align="center"
                >
                  <Icon
                    as={FaListUl}
                    mr={2}
                  />
                  <Text
                    fontSize="14px"
                    fontWeight="400"
                    color="font_grey"
                  >
                    {infoCurso?.modulos?.length} módulos
                  </Text>
                  <Icon
                    as={FaClock}
                    ml={4}
                    mr={2}
                  />
                  <Text
                    fontSize="14px"
                    fontWeight="400"
                    color="font_grey"
                  >
                    {infoCurso?.duracion} h
                  </Text>
                </Flex>

                <Text
                  color="main"
                  fontSize="15px"
                  fontWeight="600"
                >
                  {parseInt((totalProgreso || 0)?.toFixed(0))}% completo
                </Text>

              </Flex>
              :
              <Skeleton mt={5} height="22.5px" borderRadius="5px" w="160px" />
            }


          </Flex>

          <Flex
            justify="space-between"
            direction="column"
            mt="10px"
          >
            <ProgressBar
              progress={parseInt((totalProgreso || 0)?.toFixed(0))}
              bg="grey"
              bgProgress="main"
              height="10px"
            />
          </Flex>
        </Box>
      </Flex>

      {matricula?.fechaFin &&
        <MotionFlex
          position="absolute"
          left="102%"
          bottom="0"
          top="0"
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: showCard ? 1 : 0, x: showCard ? 0 : -20 }}
          transition={{ duration: 0.3 }}
        >
          <CardContador stats={matricula} />
        </MotionFlex>
      }

    </Flex>
  );
};

export default CardCurso;