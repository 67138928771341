import { Box, Skeleton, VStack, HStack } from "@chakra-ui/react";

export const CursosCardSkeleton = () => (
    <Box
        borderWidth="1px"
        borderRadius="xl"
        overflow="hidden"
        w={{ base: "100%", sm: "280px", md: "320px" }}
        h={{ base: "auto", sm: "420px" }}
        bg="white"
        boxShadow="md"
        transition="all 0.3s"
        _hover={{ transform: 'translateY(-5px)', shadow: 'xl' }}
    >
        <Skeleton
            height={{ base: "200px", sm: "220px" }}
        />

        <VStack
            p={5}
            align="start"
            spacing={3}
            h={{ base: "auto", sm: "220px" }}
        >
            <Skeleton
                height="24px"
                width="90%"
            />

            <Skeleton
                height="24px"
                width="70%"
            />

            <HStack
                spacing={4}
                width="100%"
            >
                <Skeleton
                    height="16px"
                    width="40%"
                />

                <Skeleton
                    height="16px"
                    width="5px"
                />

                <Skeleton
                    height="16px"
                    width="30%"
                />
            </HStack>

            <Skeleton
                height="16px"
                width="40%"
            />

            <Skeleton
                height="34px"
                width="100%"
                mt={2}
            />
        </VStack>
    </Box>
);