import { Box, Button, Checkbox, Flex, Icon, Text, Tooltip } from "@chakra-ui/react"
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useOutExamen } from "../../../../../shared/hooks/useOutExamen";
import { OutExamenModal } from "../../../../../shared/hooks/Components/OutExamenModal";
import { OutAlertModal } from "../../../../../shared/hooks/Components/OutAlertModal";
import { ExitPreventAlertModal } from "../../../components/ExitPreventAlertModal";

interface Props {
    examen: any;
    preguntaActualIndex: number;
    setPreguntaActualIndex: (index: any) => void;
    onEndExamen: (respuesta: string[]) => void;
    isLoading?: boolean;
    decrementSecs: (secs: number) => void;
    secs: number;
    respuestaActual: any | undefined;
    setRespuestaActual: Dispatch<SetStateAction<string | undefined>>;
    respuestas: any[];
    setRespuestas: Dispatch<SetStateAction<string[]>>;
    outExamen: () => void;
    onClose: () => void;
    isOpen: boolean;
}

export const ProgressQuiz = ({
    examen,
    preguntaActualIndex,
    setPreguntaActualIndex,
    onEndExamen,
    isLoading,
    decrementSecs,
    secs,
    respuestaActual,
    setRespuestaActual,
    respuestas,
    setRespuestas,
    outExamen,
    onClose,
    isOpen
}: Props) => {
    const { outWindow, alert, onCloseWindow, onCloseAlert } = useOutExamen();
    const [preguntaActual, setPreguntaActual] = useState<any>();

    useEffect(() => {
        examen?.preguntas &&
            setPreguntaActual(examen?.preguntas[0])
    }, [examen])

    const onNextPregunta = async () => {
        if (
            !respuestas ||
            !respuestaActual ||
            !examen?.preguntas ||
            examen?.preguntas?.length === 0
        ) return;

        if ((examen?.preguntas?.length) > preguntaActualIndex) {
            setRespuestas([
                ...respuestas,
                respuestaActual,
            ]);

            setRespuestaActual(undefined);
            setPreguntaActual(examen?.preguntas ? examen?.preguntas[preguntaActualIndex] : undefined);
            setPreguntaActualIndex((prev: number) => prev + 1);
        }

        if ((examen?.preguntas?.length) === preguntaActualIndex) {
            onEndExamen([...respuestas, respuestaActual])
        }
    };

    useEffect(() => {
        if (outWindow) {
            decrementSecs(60 * 3);
        }
    }, [outWindow])

    useEffect(() => {
        if (secs <= 0) {
            onEndExamen([...respuestas, respuestaActual])
        }
    }, [secs])

    const onConfirm = () => {
        onClose();
        outExamen();
    }

    return (
        <Flex
            direction="column"
            bg="purewhite"
            p="70px"
            mx="40px"
            mt="40px"
            h="full"
            alignItems="center"
        >
            <Flex
                direction="column"
                alignItems="center"
                gap="40px"
            >
                <Text
                    color="#A5A8B3"
                    fontSize="16px"
                    fontWeight="700"
                    textTransform="uppercase"
                >
                    Pregunta {preguntaActualIndex}/{examen?.preguntas?.length}
                </Text>

                <Text
                    color="font"
                    fontSize="18px"
                    fontWeight="700"
                >
                    {preguntaActual?.contenido}
                </Text>

                <Flex
                    direction="column"
                    gap="15px"
                    w="100%"
                    alignItems="center"
                    px="100px"
                >
                    {preguntaActual?.respuestas?.map((respuesta: any, index: number) => (
                        <Flex
                            key={index}
                            p="30px"
                            w="100%"
                            border="1px solid"
                            borderColor={respuestaActual === respuesta?.id ? "main" : "#E6E6EA"}
                            bg="transparant"
                            rounded="15px"
                            _hover={{ border: "1px solid", borderColor: "main" }}
                            gap="20px"
                            cursor="pointer"
                            onClick={() => setRespuestaActual(respuesta?.id)}
                        >
                            <Checkbox
                                value={respuesta?.id}
                                onChange={(e: any) => setRespuestaActual(e.target.value)}
                                isChecked={
                                    respuestaActual === respuesta?.id
                                        ? true
                                        : false
                                }
                            />

                            <Text
                                fontSize="14px"
                                fontWeight="400"
                                color={respuestaActual === respuesta?.id ? "secondary" : "font"}
                                lineHeight="20px"
                            >
                                {respuesta.contenido}
                            </Text>
                        </Flex>
                    ))}
                </Flex>
            </Flex>

            <Flex
                mt="auto"
                w="100%"
                justifyContent="center"
            >
                <Tooltip
                    hasArrow
                    placement="left"
                    bg="pureblack"
                    p="10px"
                    rounded="5px"
                    label={
                        <Text
                            color="purewhite"
                            fontSize="14px"
                            fontWeight="400"
                            lineHeight="20px"
                            textAlign={"center"}
                        >
                            Debes seleccionar una respuesta para continuar
                        </Text>
                    }
                    isDisabled={respuestaActual}
                >
                    <Button
                        mt="20px"
                        color="#FFF"
                        bg="main"
                        fontSize="18px"
                        fontWeight="700"
                        h="fit-content"
                        py="14px"
                        px="24px"
                        rounded="14px"
                        _hover={{ bg: "variant" }}
                        isDisabled={!respuestaActual}
                        cursor="pointer"
                        onClick={onNextPregunta}
                        isLoading={examen?.preguntas?.length === preguntaActualIndex && isLoading}
                    >
                        {
                            (examen?.preguntas?.length === preguntaActualIndex)
                                ? "Finalizar"
                                : "Siguiente pregunta"
                        }
                    </Button>
                </Tooltip>
            </Flex>

            <OutExamenModal
                onClose={onCloseWindow}
                isOpen={outWindow}
            />

            <OutAlertModal
                onClose={onCloseAlert}
                isOpen={alert}
            />

            <ExitPreventAlertModal
                onClose={onClose}
                isOpen={isOpen}
                onConfirm={onConfirm}
            />
        </Flex >
    )
}