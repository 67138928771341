import { Box, Image, Text, Flex, Icon, Skeleton } from '@chakra-ui/react';
import { FaListUl, FaClock } from 'react-icons/fa';
import { CursosInt } from '../../../../interfaces/CursosInt';
import { MatriculasInt } from '../../../../interfaces/MatriculasInt';
import cursoPortada from "../../../../assets/img/CursoPortada.png"
import { format } from '../../../../shared/utils/functions/formatDate';

interface Props {
  curso: CursosInt;
  matricula: MatriculasInt;
}

export const CardCursoProximos = ({
  curso,
  matricula,
}: Props) => {

  return (
    <Flex
      borderWidth="1px"
      rounded="20px"
      bg="purewhite"
      p="18px"
      display="flex"
      gap="20px"
      cursor="pointer"
      transition="all 0.2s"
      _hover={{
        transform: "scale(1.01)",
      }}
      w="100%"
      h="155px"
      overflow="auto"
    >

      <Image
        src={curso?.imagen?.url ? curso?.imagen?.url : cursoPortada}
        alt={curso?.nombre}
        w="250px"
        objectFit="cover"
        borderRadius="md"
      />

      <Box
        ml={4}
        flex="1"
      >

        <Flex
          justify="space-between"
          direction="column"
        >
          <Flex
            direction="column"
            gap="5px"
          >
            <Text
              fontSize="18px"
              fontWeight="600"
            >
              {curso?.nombre}
            </Text>

          </Flex>

          <Flex
            justifyContent="space-between"
            gap="40px"
            mt={"10px"}
          >
            <Flex
              align="center"
            >
              <Icon
                as={FaListUl}
                mr={2}
              />
              <Text
                fontSize="14px"
                fontWeight="400"
                color="font_grey"
              >
                {curso?.meta?.totalModulos} módulos
              </Text>
              <Icon
                as={FaClock}
                ml={4}
                mr={2}
              />
              <Text
                fontSize="14px"
                fontWeight="400"
                color="font_grey"
              >
                {curso?.duracion}
              </Text>
            </Flex>
          </Flex>

          <Flex
            alignItems="center"
            gap="35px"
            mt={"30px"}
          >
            <Flex
              gap="10px"
              alignItems="center"
            >
              <Text
                fontSize="14px"
                fontWeight="400"
                color="main"
              >
                Fecha inicio
              </Text>

              <Text
                fontSize="14px"
                fontWeight="400"
                color="#A5A8B3"
              >
                {format({ date: matricula?.fechaInicio, customFormat: "dd/MM/yyyy" })}
              </Text>
            </Flex>

            <Flex
              gap="10px"
              alignItems="center"
            >
              <Text
                fontSize="14px"
                fontWeight="400"
                color="main"
              >
                Fecha fin
              </Text>

              <Text
                fontSize="14px"
                fontWeight="400"
                color="#A5A8B3"
              >
                {format({ date: matricula?.fechaFin, customFormat: "dd/MM/yyyy" })}
              </Text>
            </Flex>
          </Flex>

        </Flex>
      </Box>
    </Flex>
  );
};

export default CardCursoProximos;