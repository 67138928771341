import { OutWindowContext } from "../context/outWindow.context";
import { useAuthContext } from "../context/user.context";
import { UserRolEnum } from "../utils/types/UserRolEnum";
import { isRoleAllowed } from "../utils/functions/validateRol";
import { OutWindowModal } from "../hooks/Components/OutWindowModal";
import { useOutWindow } from "../hooks/useOutWindow/useOutWindow";

export const OutWindowController = ({ children }: any) => {
    const { user } = useAuthContext();
    const { outWindow, onClose, setTimeModal } = useOutWindow(user?.auth);

    return (
        <OutWindowContext.Provider value={{ outWindow, onClose, setTimeModal }}>
            {children}

            {isRoleAllowed([UserRolEnum.ALUMNO], user?.role?.nombre) &&
                <OutWindowModal isOpen={outWindow} onClose={onClose} />
            }
        </OutWindowContext.Provider>
    );
}