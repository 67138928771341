import { RJSFSchema } from '@rjsf/utils';

export const schema: RJSFSchema = {
    title: "Cuestionario para la evaluación de la calidad de las acciones formativas en el marco del sistema de formación para el empleo",
    type: "object",
    required: [
        "edad",
        "sexo",
        "titulacionActual",
        "lugarTrabajo",
        "categoriaProfesional",
        "otraTitulacion",
        "nivelIdioma",
        "horarioCurso",
        "porcentajeJornadaLaboral",
        "tamanoEmpresa",
    ],
    properties: {
        edad: { type: "string", title: "1. Edad" },
        sexo: {
            type: "string",
            title: "2. Sexo",
            enum: ["1. Mujer", "2. Varón", "No contesta"],
        },
        titulacionActual: {
            type: "string",
            title: "3. Titulación Actual",
            enum: [
                "1. Sin titulación",
                "1.1. Certificado de Profesionalidad Nivel 1",
                "1.1.1. Educación Primaria",
                "1.2. Formaciòn Profesional Básica",
                "2. Titulación de graduado E.S.O./Graduado escolar",
                "2.1. Certificado de Profesionalidad Nivel 2",
                "3. Título de Bachiller",
                "4. Título de Técnico/ FP grado medio",
                "4.1. Título Profesional enseñanza música-danza",
                "4.2. Certificado de Profesionalidad Nivel 3",
                "5. Título de Técnico Superior/ FP grado superior",
                "6. E. universitarios 1 ciclo (Diplomatura)",
                "6.1. Grados universitarios de hasta 240 créditos",
                "7. E. universitarios 2 ciclo (Licenciatura-Máster)",
                "7.1. Grados universitarios de más 240 créditos",
                "7.3. Máster oficiales universitarios",
                "7.4. Especialidad en CC. salud (residentes)",
                "8. E. universitarios 3 ciclo (Doctor)",
                "9. Título de Doctor",
                "No contesta",
            ],
        },
        otraTitulacion: {
            type: "string",
            title: "3.10. Otra titulacion",
            enum: [
                "1. Carnet profesional / Profesiones reguladas",
                "2. Nivel de idioma",
                "3. Otra",
                "No contesta",
            ],

        },
        nivelIdioma: {
            title: "3.10.2 Nivel más alto conseguido en cualquier idioma",
            type: "string",
            enum: [
                "Nivel de idioma A1 del MCER",
                "Nivel de idioma A2 del MCER",
                "Nivel de idioma B1 del MCER",
                "Nivel de idioma B2 del MCER",
                "Nivel de idioma C1 del MCER",
                "Nivel de idioma C2 del MCER",
                "No contesta"
            ]
        },
        especificarOtraTitulacion: {
            type: "string",
            title: "3.10.3. Especificar otra titulación",
        },
        lugarTrabajo: {
            type: "string",
            title: "4. Lugar de trabajo (indicar PROVINCIA)",
            enum: [
                "Álava",
                "Albacete",
                "Alicante",
                "Almería",
                "Asturias",
                "Ávila",
                "Badajoz",
                "Barcelona",
                "Burgos",
                "Cáceres",
                "Cádiz",
                "Cantabria",
                "Castellón",
                "Ciudad Real",
                "Córdoba",
                "Cuenca",
                "Gerona",
                "Granada",
                "Guadalajara",
                "Guipúzcoa",
                "Huelva",
                "Huesca",
                "Islas Baleares",
                "Jaén",
                "La Coruña",
                "La Rioja",
                "Las Palmas",
                "León",
                "Lérida",
                "Lugo",
                "Madrid",
                "Málaga",
                "Murcia",
                "Navarra",
                "Orense",
                "Palencia",
                "Pontevedra",
                "Salamanca",
                "Santa Cruz de Tenerife",
                "Segovia",
                "Sevilla",
                "Soria",
                "Tarragona",
                "Teruel",
                "Toledo",
                "Valencia",
                "Valladolid",
                "Vizcaya",
                "Zamora",
                "Zaragoza"
              ]
        },
        categoriaProfesional: {
            type: "string",
            title: "5. Categoría profesional",
            enum: [
                "Directivo/a",
                "Mando Intermedio",
                "Técnico/a",
                "Trabajador/a cualificado/a",
                "Trabajador/a de baja cualificación",
                "Otra categoría",
                "No contesta",
            ],
        },
        especificarOtraCategoriaProfesional: {
            type: "string",
            title: "5.6. Especificar otra categoría",
        },
        horarioCurso: {
            type: "string",
            title: "6. Horario del curso",
            enum: [
                "Dentro de la jornada laboral",
                "Fuera de la jornada laboral",
                "Ambas",
                "No contesta",
            ],
        },
        porcentajeJornadaLaboral: {
            type: "string",
            title: "6.1. Porcentaje de la jornada laboral que abarca el curso",
            enum: ["Menos del 25%", "Entre el 25% y 50%", "Más del 50%", "No contesta"],
        },
        tamanoEmpresa: {
            type: "string",
            title: "7. Tamaño de la empresa del participante",
            enum: [
                "De 1 a 9 empleos",
                "De 10 a 49 empleos",
                "De 50 a 99 empleos",
                "De 100 a 250 empleos",
                "De más de 250 empleos",
                "No contesta",
            ],
        },
        valoracionAspectos: {
            title: "Valore los aspectos del curso utilizando una escala del 1 al 4:",
            description: "1 Completamente en desacuerdo, 2 En desacuerdo, 3 De acuerdo, 4 Completamente de acuerdo.",
            type: "object",
            required: [
                "organizacionCurso",
                "contenidoMetodologia",
                "duracionHorario",
                "formadores",
                "tutores",
                "mediosDidacticos",
                "instalaciones",
                "modalidad",
                "evaluacion",
                "valoracion",
                "satisfaccion",
            ],
            properties: {
                organizacionCurso: {
                    title: "1. Organizacion del curso",
                    type: "object",
                    properties: {
                        informacionCurso: {
                            title:
                                "1.1. El curso ha estado bien organizado (información, cumplimiento fechas y horarios, entrega material)",
                            type: "string",
                            enum: ["1", "2", "3", "4"],
                        },
                        informacionAlumnos: {
                            title:
                                "1.2. El número de alumnos del grupo ha sido adecuado para el desarrollo del curso",
                            enum: ["1", "2", "3", "4"],
                        },
                    },
                    required: ["informacionCurso", "informacionAlumnos"],
                },
                contenidoMetodologia: {
                    title: "2. Contenidos y metodología de impartición",
                    type: "object",
                    properties: {
                        contenido: {
                            title:
                                "2.1. Los contenidos del curso han respondido a mis necesidades formativas",
                            type: "string",
                            enum: ["1", "2", "3", "4"],
                        },
                        combinacion: {
                            title:
                                "2.2. Ha habido una combinación adecuada de teoría y aplicación práctica",
                            enum: ["1", "2", "3", "4"],
                        },
                    },
                    required: ["contenido", "combinacion"],
                },
                duracionHorario: {
                    title: "3. Duración y horario",
                    type: "object",
                    properties: {
                        duracion: {
                            title:
                                "3.1. La duración del curso ha sido suficiente según los objetivos y contenidos del mismo",
                            type: "string",
                            enum: ["1", "2", "3", "4"],
                        },
                        horario: {
                            title: "3.2. El horario ha favorecido la asistencia al curso",
                            enum: ["1", "2", "3", "4"],
                        },
                    },
                    required: ["duracion", "horario"],
                },
                formadores: {
                    title: "4.1.F. Formadores",
                    type: "object",
                    properties: {
                        seguimiento: {
                            title:
                                "4.1.1.F. La forma de impartir el curso ha facilitado el aprendizaje",
                            type: "string",
                            enum: ["1", "2", "3", "4"],
                        },
                        temas: {
                            title:
                                "4.1.2.F. Conocen los temas impartidos en profundidad",
                            enum: ["1", "2", "3", "4"],
                        },
                    },
                    required: ["seguimiento", "temas"],
                },
                tutores: {
                    title: "4.1.T. Tutores",
                    type: "object",
                    properties: {
                        material: {
                            title:
                                "4.1.1.T. La forma de tutorizar el curso ha facilitado el aprendizaje",
                            type: "string",
                            enum: ["1", "2", "3", "4"],
                        },
                        mediosActualizados: {
                            title:
                                "4.1.2.T. Conocen los temas impartidos en profundidad",
                            enum: ["1", "2", "3", "4"],
                        },
                    },
                    required: ["material", "mediosActualizados"],
                },
                mediosDidacticos: {
                    title: "5. Medios didácticos (guías, manuales, fichas…)",
                    type: "object",
                    properties: {
                        documentacion: {
                            title:
                                "5.1. La documentación y materiales entregados son comprensibles y adecuados",
                            type: "string",
                            enum: ["1", "2", "3", "4"],
                        },
                        medios: {
                            title: "5.2. Los medios didácticos están actualizados",
                            enum: ["1", "2", "3", "4"],
                        },
                    },
                    required: ["documentacion", "medios"],
                },
                instalaciones: {
                    title:
                        "6. Instalaciones y medios técnicos (pizarra, pantalla, proyector, TV, vídeo, ordenador, programas, máquinas, herramientas...)",
                    type: "object",
                    properties: {
                        instalacion: {
                            title:
                                "6.1. El aula, el taller o las instalaciones han sido apropiadas para el desarrollo del curso",
                            type: "string",
                            enum: ["1", "2", "3", "4"],
                        },
                        medios: {
                            title:
                                "6.2. Los medios técnicos han sido adecuados para desarrollar el contenido del curso(ordenadores, pizarra, proyector, TV, máquinas)",
                            enum: ["1", "2", "3", "4"],
                        },
                    },
                    required: ["instalacion", "medios"],
                },
                modalidad: {
                    title:
                        "7. Sólo cuando el curso se ha realizado en la modalidad a distancia, teleformación o mixta",
                    type: "object",
                    properties: {
                        material: {
                            title:
                                "7.1. Las guías tutoriales y los materiales didácticos han permitido realizar fácilmente el curso(impresos, aplicaciones telemáticas)",
                            type: "string",
                            enum: ["1", "2", "3", "4"],
                        },
                        tutorias: {
                            title:
                                "7.2. Se ha contado con medios de apoyo suficientes(tutorías individualizadas, correo y listas de distribución, teleconferencia, biblioteca virtual, buscadores…)",
                            enum: ["1", "2", "3", "4"],
                        },
                    },
                    required: ["material", "tutorias"],
                },
                evaluacion: {
                    title: "8. Mecanismos para la evaluación del aprendizaje",
                    type: "object",
                    properties: {
                        pruebas: {
                            title:
                                "8.1. Se ha dispuesto de pruebas de evaluación y autoevaluación que me permiten conocer el nivel de aprendizaje alcanzado",
                            type: "string",
                            enum: ["Si", "No"],
                        },
                        acreditacion: {
                            title:
                                "8.2. El curso me permite obtener una acreditación donde se reconoce mi cualificación",
                            enum: ["Si", "No"],
                        },
                    },
                    required: ["pruebas", "acreditacion"],
                },
                valoracion: {
                    title: "9. Valoración general del curso",
                    type: "object",
                    properties: {
                        incorporacion: {
                            title:
                                "9.1. Puede contribuir a mi incorporación al mercado de trabajo",
                            type: "string",
                            enum: ["1", "2", "3", "4"],
                        },
                        habilidades: {
                            title:
                                "9.2. Me ha permitido adquirir nuevas habilidades/capacidades que puedo aplicar al puesto de trabajo",
                            enum: ["1", "2", "3", "4"],
                        },
                        posibilidades: {
                            title:
                                "9.3. Ha mejorado mis posibilidades para cambiar de puesto de trabajo en la empresa o fuera de ella",
                            enum: ["1", "2", "3", "4"],
                        },
                        conocimiento: {
                            title:
                                "9.4. He ampliado conocimientos para progresar en mi carrera profesional",
                            enum: ["1", "2", "3", "4"],
                        },
                        desarrollo: {
                            title: "9.5. Ha favorecido mi desarrollo personal",
                            enum: ["1", "2", "3", "4"],
                        },
                    },
                    required: ["incorporacion", "habilidades", "posibilidades", "conocimiento", "desarrollo"],
                },
                satisfaccion: {
                    title: "10. Grado de satisfacción general con el curso",
                    enum: ["1", "2", "3", "4"],
                },
                sugerencia: {
                    type: "string",
                    title:
                        "11. Si desea realizar cualquier sugerencia u observación, por favor, utilice el espacio reservado a continuación",
                },
            },
        },
    },
};
