import { Box, Flex, Text } from "@chakra-ui/react"

export const CardSinModulo = () => {

    return (
        <Flex
            w="50%"
            p="20px"
            border="1px solid"
            borderColor="main"
            rounded="16px"
            bg="purewhite"
            direction="column"
            gap="24px"
            h="255px"
        >
            <Text
                fontSize="16px"
                fontWeight="500"
                color="font"
            >
                No existen módulos
            </Text>

            <Flex
                direction="column"
                gap="14px"
            >
                <Text
                    color="font"
                    fontSize="20px"
                    fontWeight="600"
                >
                    Aquí se mostrará el módulo a continuar
                </Text>

                <Box
                    mt="10px"
                    w="100%"
                    h="1px"
                    border="1px dashed"
                    borderColor="#DFE1E6"
                />

                <Text
                    color="#808897"
                    fontSize="14px"
                    fontWeight="500"
                    w="100%"
                    alignItems="center"
                    h="fit-content"
                    py="10px"
                >
                    ¡Prepárate! Estás a un paso de iniciar tu nuevo aprendizaje.
                </Text>
            </Flex>
        </Flex>
    )
}