import { Box, Flex, Progress, Skeleton, Text } from "@chakra-ui/react"
import { useData } from "../../../shared/hooks/useData"
import { EndpointTypes } from "../../../shared/utils/types/EndpointTypes"
import { MatriculasInt } from "../../../interfaces/MatriculasInt"
import { useDataId } from "../../../shared/hooks/useDataId";
import { onContinueLeccion } from "../../../shared/utils/functions/validateProgress";

interface Props {
    matricula: MatriculasInt | undefined;
}

export const CardCursoFinalizado = ({
    matricula
}: Props) => {

    const { data: curso } = useDataId({
        endpoint: EndpointTypes.CURSOS,
        id: matricula?.grupo?.curso?.slug,
        ignoreRequest: !matricula
    })

    return (
        (matricula) ?
            <Flex
                w="50%"
                p="20px"
                border="1px solid"
                borderColor="main"
                rounded="16px"
                bg="purewhite"
                direction="column"
                gap="24px"
                h="255px"
            >
                <Text
                    fontSize="16px"
                    fontWeight="500"
                    color="font"
                >
                    Curso finalizado
                </Text>

                <Flex
                    direction="column"
                    gap="14px"
                >
                    <Text
                        color="font"
                        fontSize="20px"
                        fontWeight="600"
                    >
                        Ya puede descargar su diploma.
                    </Text>

                    <Progress
                        w="100%"
                        bg="#EEEFF2"
                        maxW="100%"
                        rounded="full"
                        max={100}
                        h={"6px"}
                        value={100}
                        sx={{ '& > div': { background: "main" } }}
                    />

                    <Text
                        color="#666D80"
                        fontSize="14px"
                        fontWeight="400"
                    >
                        {curso?.meta?.totalModulos} Módulos completados
                    </Text>

                    <Box
                        mt="10px"
                        w="100%"
                        h="1px"
                        border="1px dashed"
                        borderColor="#DFE1E6"
                    />

                    <Text
                        color="#808897"
                        fontSize="14px"
                        fontWeight="500"
                        w="100%"
                        alignItems="center"
                        h="fit-content"
                        py="10px"
                    >
                        ¡Buen trabajo!
                    </Text>
                </Flex>
            </Flex>
            :
            <Skeleton
                w="50%"
                h="255px"
                rounded="16px"
            />
    )
}