import { Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useToast } from "@chakra-ui/react";
import { Formik, Form as FormikForm } from 'formik';
import * as Yup from "yup"
import { FormTextEditor } from "../../../shared/components/FormElements/FormTextEditor";
import { FormSelect } from "@imaginagroup/bit-components.ui.form-select";
import { FormInput } from "@imaginagroup/bit-components.ui.form-input";
import { formSelectStyles } from "../../../ui/Select/formSelectStyles";
import { useEffect, useState } from "react";
import { MatriculasInt } from "../../../interfaces/MatriculasInt";
import { useAuthContext } from "../../../shared/context/user.context";
import { addForoPregunta } from "../../../shared/middlewares/foro.middlewate";
import { useNavigate } from "react-router-dom";
import { toastNotify } from "../../../shared/utils/functions/toastNotify";
import { handleErrors } from "../../../shared/utils/functions/handleErrors";
import { StatusEnumTypes } from "../../../shared/utils/types/StatusEnumTypes";

interface Props {
    onClose: () => void;
    isOpen: boolean
    matriculas: {
        data: MatriculasInt[]
    }
    Refresh: () => void;
}

export default function ModalPregunta({ onClose, isOpen, matriculas, Refresh }: Props) {
    const { user } = useAuthContext();
    const toast = useToast();
    const navigate = useNavigate()
    const [cursos, setCursos] = useState<{ label: string; value: string; }[]>([])

    useEffect(() => {
        if (matriculas) {
            const cursosOpt: { label: string; value: string; }[] = []

            matriculas?.data?.map((m: MatriculasInt) => {
                cursosOpt.push({
                    label: m?.grupo?.curso?.nombre + " - " + m?.grupo?.nombre,
                    value: m?.grupoId!
                })
            })

            setCursos(cursosOpt)
        }
    }, [matriculas])

    const initialValues = {
        grupoId: null,
        titulo: null,
        contenido: null,
    };

    const validationSchema = Yup.object({
        grupoId: Yup.string()
            .required('Debe seleccionar un curso')
            .typeError('Debe seleccionar un curso'),
        titulo: Yup.string()
            .required('El titulo es obligatorio')
            .typeError('El titulo es obligatorio'),
        contenido: Yup.string()
            .required('El contenido es obligatorio')
            .typeError('El contenido es obligatorio'),
    });

    const submitForm = async (
        values: {
            grupoId: string | null;
            titulo: string | null;
            contenido: string | null;
        }
    ) => {
        if (!user?.id) return;

        const newPregunta = {
            grupoId: values.grupoId,
            titulo: values.titulo,
            contenido: values.contenido,
            userId: user?.id,
            fijado: false,
        }

        addForoPregunta({
            data: newPregunta
        })
            .then((response) => {
                const id = response?.data?.data?.id

                navigate(`${id}`);
                Refresh()
                onClose();
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
    };

    return (
        <Modal
            size="full"
            onClose={onClose}
            isOpen={isOpen}
            motionPreset='slideInRight'
        >
            <ModalOverlay />
            <ModalContent
                p="20px"
            >
                <ModalHeader
                    fontSize="24px"
                    fontWeight="700"
                    color="font"
                >
                    Escribe tu pregunta
                    <Flex
                        bg="border"
                        w="full"
                        h="1px"
                        px="20px"
                        mt="20px"
                    />
                </ModalHeader>
                <ModalCloseButton />

                <Formik
                    onSubmit={submitForm}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                >
                    {(formik) => {
                        const { handleSubmit } = formik;

                        return (
                            <FormikForm
                                onSubmit={handleSubmit}
                                style={{
                                    height: "100%"
                                }}
                            >
                                <ModalBody
                                >
                                    <Flex
                                        direction="column"
                                        gap="14px"
                                    >
                                        <Flex
                                            gap="40px"
                                            alignItems="center"
                                        >
                                            <Text
                                                fontSize="16px"
                                                fontWeight="600"
                                                whiteSpace="nowrap"
                                                w="150px"
                                                textAlign="end"
                                                mb="18px"
                                            >
                                                Título pregunta
                                            </Text>

                                            <FormInput
                                                name="titulo"
                                                placeholder="Escribe un título para la nota"
                                                ui={{
                                                    styleInput: {
                                                        rounded: "10px",
                                                        border: "1px solid #E2E8F0",
                                                        bg: "purewhite",
                                                        p: "12px 15px",
                                                        color: "font"
                                                    },
                                                    stylePlaceholder: {
                                                        color: "#A5A8B3",
                                                        fontSize: "16px",
                                                        fontWeight: "500",
                                                        lineHeight: "160%",
                                                        letterSpacing: "0.08px"
                                                    }
                                                }}
                                            />
                                        </Flex>

                                        <Flex
                                            gap="40px"
                                            align="center"
                                        >
                                            <Text
                                                fontSize="16px"
                                                fontWeight="600"
                                                whiteSpace="nowrap"
                                                w="150px"
                                                textAlign="end"
                                                mb="18px"
                                            >
                                                Curso
                                            </Text>

                                            <FormSelect
                                                name="grupoId"
                                                options={cursos}
                                                placeholder="Selecciona el curso al cual quieres asociar la nota"
                                                ui={{
                                                    formSelectStyles: formSelectStyles
                                                }}
                                            />
                                        </Flex>

                                        <Flex
                                            gap="40px"
                                            align="center"
                                        >
                                            <Text
                                                fontSize="16px"
                                                fontWeight="600"
                                                whiteSpace="nowrap"
                                                w="150px"
                                                textAlign="right"
                                                alignSelf="flex-start"
                                                mt="8px"
                                            >
                                                Contenido
                                            </Text>

                                            <FormTextEditor
                                                height="200px"
                                                name='contenido'
                                                onChange={formik.setFieldValue}
                                                placeholder="Escribe una respuesta"
                                                styles={{
                                                    border: "1px solid",
                                                    borderColor: "#E2E8F0",
                                                    borderRadius: "8px",
                                                    padding: "0",
                                                    fontSize: "15px",
                                                    fontWeight: "400",
                                                    lineHeight: "150%",
                                                    minHeight: "300px",
                                                    background: "#FFF",
                                                }}
                                            />
                                        </Flex>
                                    </Flex>
                                </ModalBody>

                                <ModalFooter
                                >
                                    <Button
                                        bg="main"
                                        rounded="10px"
                                        color="purewhite"
                                        type="submit"
                                    >
                                        Subir pregunta
                                    </Button>
                                </ModalFooter>
                            </FormikForm>
                        );
                    }}
                </Formik>
            </ModalContent>
        </Modal >
    )
}
