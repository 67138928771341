
import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Text } from "@chakra-ui/react"

interface Props {
    onClose: () => void;
    isOpen: boolean;
}

export const ModalAlertCurso = ({ onClose, isOpen }: Props) => {
    return (
        <Modal
            size="2xl"
            onClose={onClose}
            isOpen={isOpen}
            isCentered
        >
            <ModalContent
                p={0}
                rounded="10px"
                bg="#FFFFFF"
                shadow={"0px 4px 10px rgba(0, 0, 0, 0.1)"}
            >
                <ModalHeader
                    px="20px"
                    py="10px"
                    textAlign="center"
                >
                    Curso finalizado
                </ModalHeader>

                <ModalBody
                    px="20px"
                    py="10px"
                >
                    <Text
                        color="font"
                        fontSize="16px"
                        fontWeight="500"
                        textAlign="center"
                    >
                        Has finalizado el curso, el mismo tiene un examen final. No olvides realizarlo, puedes acceder a él desde la información del curso.
                    </Text>
                </ModalBody>

                <ModalFooter>
                    <Button
                        bg="main"
                        color="white"
                        w="fit-content"
                        h="fit-content"
                        fontSize="16px"
                        fontWeight="700"
                        px="20px"
                        py="10px"
                        rounded="8px"
                        onClick={onClose}
                    >
                        Cerrar
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
