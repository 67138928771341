import { Flex, Icon, Image, Text } from '@chakra-ui/react'
import { HiCheckCircle } from 'react-icons/hi'
import MarkdownPreview from "@uiw/react-markdown-preview"
import { truncate } from 'lodash';
import { useNavigate } from 'react-router-dom';

interface Props {
    topLine?: boolean;
    dashedLine?: boolean;
    curso?: any;
}

export default function RutaCurso({ topLine, dashedLine, curso }: Props) {
    const navigate = useNavigate();

    return (
        <Flex
            gap="20px"
            onClick={() => navigate(`/aprendizaje/${curso?.slug}`)}
            w="100%"
        >
            <Flex
                direction="column"
                alignItems="center"
                gap="0"
            >
                {!topLine ?
                    <Flex
                        w="2px"
                        h="38%"
                        bg="main"
                    />
                    :
                    <Flex
                        w="2px"
                        h="38%"
                    />
                }

                <Icon
                    as={HiCheckCircle}
                    color="main"
                    boxSize="32px"
                />

                {dashedLine ?
                    <Flex
                        w="1px"
                        h="full"
                        bg="main"
                        borderLeft="2px dashed"
                        borderColor="#F8F8F9"
                    />
                    :
                    <Flex
                        w="2px"
                        h="full"
                        bg="main"
                    />
                }
            </Flex>

            <Flex
                w="100%"
                h="202px"
                rounded="20px"
                bg="purewhite"
                border="1px solid"
                borderColor={"#E6E6EA"}
                mt={!topLine ? "20px" : "0"}
                cursor="pointer"
                transition="all 0.2s"
                _hover={{
                    transform: "scale(1.02)",
                }}
            >
                <Flex
                    direction="column"
                    p="24px"
                    gap="16px"
                    w="100%"
                >
                    <Flex
                        bg="main"
                        color="purewhite"
                        rounded="5px"
                        w="fit-content"
                        p="5px 8px"
                    >
                        {curso?.meta?.modulos_count} módulos | {curso?.meta?.pivot_duracion}h
                    </Flex>

                    <Flex
                        gap="10px"
                        direction="column"
                    >
                        <Text
                            fontSize="18px"
                            fontWeight="700"
                            color="#121625"
                        >
                            {curso?.nombre}
                        </Text>

                        <MarkdownPreview
                            source={truncate(curso?.descripcion, { length: 200 })}
                            style={{
                                fontSize: "14px",
                                fontWeight: "400",
                            }}
                            wrapperElement={{ "data-color-mode": "light" }}
                        />
                    </Flex>
                </Flex>

                <Image
                    src={curso?.imagen?.url}
                    w="152px"
                    objectFit="cover"
                    roundedRight="20px"
                />
            </Flex>
        </Flex>
    )
}
