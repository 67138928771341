import { Flex, Image, Text } from "@chakra-ui/react";
import avatarDefault from "../../../../assets/img/avatarDefault.png"
import { useNavigate } from "react-router-dom";
import { PreguntaForoInt } from "../../../../interfaces/ForoInt";

interface Props {
    preguntas: any
}

export default function CardActividad({ preguntas }: Props) {
    const navigate = useNavigate()

    return (
        <Flex
            direction="column"
            gap="20px"
        >
            <Text
                color="font"
                fontSize="14px"
                fontWeight="600"
            >
                Actividad foro
            </Text>

            {preguntas?.data?.length < 0 ?

                <Flex
                    direction="column"
                    gap="10px"
                    bg="purewhite"
                    p="20px"
                    rounded="20px"
                    border="1px solid"
                    borderColor="border"
                    h="fit-content"
                    align="center"
                >
                    Aún no hay actividad en el foro
                </Flex>

                :
                preguntas?.data?.map((pregunta: PreguntaForoInt, index: number) => (
                    <Flex
                        direction="column"
                        gap="10px"
                        bg="purewhite"
                        p="20px"
                        rounded="20px"
                        border="1px solid"
                        borderColor="border"
                        onClick={() => navigate(`/foro/${pregunta?.id}`)}
                        transition="all 0.2s"
                        _hover={{
                            transform: "scale(1.02)",
                        }}
                        cursor="pointer"
                        h="114px"
                    >
                        <Flex
                            gap="20px"
                            alignItems="center"
                        >
                            <Flex
                                bg="variant"
                                rounded="10px"
                                h="60px"
                                w="60px"
                                p="5px"
                            >
                                <Image
                                    src={avatarDefault}
                                    objectFit="cover"
                                    rounded="5px"
                                    alt="avatar"
                                />
                            </Flex>

                            <Flex
                                direction="column"
                                gap="8px"
                            >
                                <Flex
                                    direction="column"
                                    gap="2px"
                                >
                                    <Text
                                        fontSize="14px"
                                        fontWeight="700"
                                        color="font"
                                    >
                                        {pregunta?.user?.nombreCompleto}
                                    </Text>

                                    <Text
                                        fontSize="12px"
                                        fontWeight="400"
                                        color="font"
                                    >
                                        {pregunta?.tema?.grupo?.curso?.nombre}
                                    </Text>
                                </Flex>

                                <Text
                                    fontSize="14px"
                                    fontWeight="400"
                                    color="font_grey"
                                    isTruncated
                                    maxW={{ base: "50px", md: "100px", xl: "200px" }}
                                >
                                    {pregunta?.titulo}
                                </Text>
                            </Flex>
                        </Flex>
                    </Flex>
                ))
            }
        </Flex>
    )
}
