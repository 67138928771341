import { Flex, CircularProgress, CircularProgressLabel, SkeletonCircle, Text, Skeleton } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { MatriculasInt } from "../../../../interfaces/MatriculasInt";
import { DateTime } from "luxon";

export enum TypeTimeEnum {
    TIME = "time",
    MONTHS = "months",
    DAYS = "days",
    HOURS = "hours",
    MINUTES = "minutes",
    SECONDS = "seconds",
}

interface Props {
    matricula: MatriculasInt | undefined;
    typeTime: TypeTimeEnum;
}

export const CardTiempo = ({
    matricula,
    typeTime
}: Props) => {
    const [time, setTime] = useState<{
        time: number
        months: number
        days: number
        hours: number
        minutes: number
        seconds: number
    }>()

    useEffect(() => {
        if (!matricula?.fechaFin) return
        const t = getCount(matricula.fechaFin);

        setTime(t);

    }, [matricula])

    const diasTotalesGrupo = matricula?.fechaInicio && matricula?.fechaFin
        ? DateTime.fromISO(matricula.fechaFin).diff(DateTime.fromISO(matricula.fechaInicio), 'days').days
        : 0;

    const diasAvanzadosGrupo = matricula?.fechaInicio
        ? DateTime.now().diff(DateTime.fromISO(matricula.fechaInicio), 'days').days
        : 0;


    const getCount = (fechaFin: any) => {
        if (!fechaFin) return;

        const inicio: any = new Date();
        const fin: any = new Date(fechaFin);
        const time: any = (fin - inicio + 1000) / 1000;

        const months: number = Math.floor(time / (30 * 24 * 60 * 60));
        const days: number = Math.floor((time % (30 * 24 * 60 * 60)) / (24 * 60 * 60));
        const hours: number = Math.floor((time % (24 * 60 * 60)) / (60 * 60));
        const minutes: number = Math.floor((time % (60 * 60)) / 60);
        const seconds: number = Math.floor(time % 60);

        return {
            time,
            months,
            days,
            hours,
            minutes,
            seconds
        };
    };

    const labels: Record<TypeTimeEnum, { singular: string; plural: string; }> = {
        [TypeTimeEnum.TIME]: {
            singular: "Tiempo",
            plural: "Tiempo"
        },
        [TypeTimeEnum.MONTHS]: {
            singular: "Mes",
            plural: "Meses"
        },
        [TypeTimeEnum.DAYS]: {
            singular: "Día",
            plural: "Días"
        },
        [TypeTimeEnum.HOURS]: {
            singular: "Hora",
            plural: "Horas"
        },
        [TypeTimeEnum.MINUTES]: {
            singular: "Minuto",
            plural: "Minutos"
        },
        [TypeTimeEnum.SECONDS]: {
            singular: "Segundo",
            plural: "Segundos"
        },
    }

    return (
        time ?
            <Flex
                alignItems="center"
                w="100%"
                justifyContent="space-between"
                px="20px"
                py="14px"
                border="1px solid"
                borderColor={"#EEEFF2"}
                boxShadow="0px 1px 2px 0px rgba(228, 229, 231, 0.24)"
                rounded="10px"
            >
                <Text
                    color="#666D80"
                    fontSize="16px"
                    fontWeight="400"
                >
                    {time[typeTime] == 1 ? labels[typeTime]?.singular : labels[typeTime]?.plural}
                </Text>


                <CircularProgress
                    capIsRound
                    value={(diasAvanzadosGrupo / diasTotalesGrupo) * 100}
                    color="main"
                    trackColor="#EAECFF"
                    size="52px"
                >
                    <CircularProgressLabel
                        fontSize="17px"
                        fontWeight="600"
                        color="font"
                    >
                        {time[typeTime]}
                    </CircularProgressLabel>
                </CircularProgress>
            </Flex>
            :
            <Skeleton
                w="100%"
                h="82px"
                rounded="10px"
            />
    )
}