import tenantInstance from "../services/tenantInstance.service";
import _axios from "../services/http.service";

export const addFirmaSesion = async (data: { leccionId: string, firma: File }) => {
    const { tenant, client } = await tenantInstance();

    const form = new FormData()

    form.append('leccionId', data.leccionId)
    form.append('firma', data.firma)

    return await _axios.post(
        `${tenant}/${client}/firmas`,
        form,
        {}
    ).then((response: any) => response);
};

export const addAsistencia = async ({
    leccionId,
    grupoId,
    asistencias
}: {
    leccionId: string;
    grupoId: string;
    asistencias: { [key: string]: boolean };
}) => {
    const { tenant, client } = await tenantInstance();

    const data = {
        leccionId,
        grupoId,
        asistentes: {
            ...asistencias
        }
    }

    return await _axios.post(
        `${tenant}/${client}/asistencias`,
        data,
        {}
    ).then((response: any) => response);
};

export const updateAsistencia = async ({
    asistenciaId,
    leccionId,
    grupoId,
    asistencias
}: {
    asistenciaId: string;
    leccionId: string;
    grupoId: string;
    asistencias: { [key: string]: boolean };
}) => {
    const { tenant, client } = await tenantInstance();

    const data = {
        leccionId,
        grupoId,
        asistentes: {
            ...asistencias
        }
    }

    return await _axios.put(
        `${tenant}/${client}/asistencias/${asistenciaId}`,
        data,
        {}
    ).then((response: any) => response);
};