
import { Box, ChakraProvider, Flex, useDisclosure } from '@chakra-ui/react'
import theme from './theme';
import "primereact/resources/themes/viva-light/theme.css";
import "primereact/resources/primereact.min.css";
import { BrowserRouter as Router } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { UpdateUserInt, UserInt } from './interfaces/UserInt';
import { RouterController } from './shared/components/RouterController/RouterController';
import { ProgressController } from './shared/controllers/ProgressController';
import { AuthContext } from './shared/context/user.context';
import ScrollToTop from './shared/helpers/ScrollToTop';
import { UrlContext, UrlInt } from './shared/context/url.context';
import { UserRolEnum } from './shared/utils/types/UserRolEnum';
import { OutWindowController } from './shared/controllers/OutWindowController';
import * as Sentry from "@sentry/browser";
import { MatriculasInt } from './interfaces/MatriculasInt';
import { pdfjs } from 'react-pdf';
import { toastNotify } from './shared/utils/functions/toastNotify';
import { StatusEnumTypes } from './shared/utils/types/StatusEnumTypes';
import { isRoleAllowed } from './shared/utils/functions/validateRol';
import { useTenantInfo } from './shared/hooks/useTenantInfo';
import { iconoTenant } from './shared/middlewares/tenant.middleware';
import { useData } from './shared/hooks/useData';
import { EndpointTypes } from './shared/utils/types/EndpointTypes';
import { Sidebar } from './shared/components/Sidebar/Sidebar';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();

function App() {
  useEffect(() => {
    const url: UrlInt = window.origin;

    setUrl(url)
  }, [])

  const login = (
    jwt: string,
    user: UserInt,
    navigate: (path: string) => void
  ) => {

    const perfilUser: UserInt = {
      auth: true,
      tracking: true,
      id: user?.id,
      email: user?.email,
      username: user?.username ? user.username : '',
      avatar: {
        url: user?.avatar?.url ? user.avatar?.url : '',
      },
      nombre: user?.nombre,
      apellidos: user?.apellidos,
      localidad: user?.localidad ? user.localidad : '',
      telefono: user?.telefono ? user.telefono : '',
      dni: user?.dni ? user.dni : '',
      role: {
        nombre: user?.role?.nombre ? user?.role?.nombre : ''
      },
      empresa: user?.empresa ? user?.empresa : {},
      horarioLaboral: user?.horarioLaboral ? user?.horarioLaboral : null,
      tutorFreelance: user?.tutorFreelance ? user?.tutorFreelance : null,
    }

    localStorage.setItem('token', jwt)
    localStorage.setItem('perfil', JSON.stringify(perfilUser))

    setUser(perfilUser);

    navigate('/')
  };

  const logout = async (
    message: string,
    navigate: (path: string) => void,
    toast: any,
  ) => {

    localStorage.removeItem('token');
    localStorage.removeItem('perfil');

    setUser({
      auth: false,
      tracking: false,
      id: null,
      email: null,
      username: null,
      avatar: null,
      nombre: null,
      apellidos: null,
      localidad: null,
      telefono: null,
      dni: null,
      role: null,
      empresa: {},
      horarioLaboral: null,
      tutorFreelance: null
    });

    navigate('/login')
    toastNotify(toast, StatusEnumTypes.INFO, message)
  };

  const refreshUser = async (updateUser: UpdateUserInt) => {
    for (const [key, value] of Object.entries(updateUser)) {
      if (key) {
        setUser((prev: UserInt) => ({
          ...prev,
          [key]: value
        }));

        localStorage.setItem('perfil', JSON.stringify(user))
      }
    }
  };

  const perfilUser: UserInt = JSON.parse(localStorage.getItem('perfil') || '{}');
  const [user, setUser] = useState<UserInt>({
    auth: localStorage.getItem('token') ? true : false,
    tracking: perfilUser?.tracking ? perfilUser.tracking : true,
    id: perfilUser?.id ? perfilUser.id : null,
    email: perfilUser?.email ? perfilUser.email : null,
    username: perfilUser?.username ? perfilUser.username : null,
    avatar: perfilUser?.avatar ? {
      url: perfilUser.avatar?.url
    } : null,
    nombre: perfilUser?.nombre ? perfilUser.nombre : null,
    apellidos: perfilUser?.apellidos ? perfilUser.apellidos : null,
    localidad: perfilUser?.localidad ? perfilUser.localidad : null,
    telefono: perfilUser?.telefono ? perfilUser.telefono : null,
    dni: perfilUser?.dni ? perfilUser.dni : null,
    role: perfilUser?.role ? {
      nombre: perfilUser?.role?.nombre
    } : null,
    empresa: perfilUser?.empresa ? perfilUser?.empresa : {},
    horarioLaboral: perfilUser?.horarioLaboral ? perfilUser?.horarioLaboral : null,
    tutorFreelance: perfilUser?.tutorFreelance ? perfilUser?.tutorFreelance : null
  });

  const { isOpen: isOpenFundae, onClose: onCloseFundae, onOpen: onOpenFundae } = useDisclosure();

  useEffect(() => {
    localStorage.setItem('perfil', JSON.stringify(user))

  }, [user])

  const [url, setUrl] = useState<UrlInt>()

  useEffect(() => {
    if (isRoleAllowed([UserRolEnum.ALUMNO], user?.role?.nombre) && user?.auth)
      matriculas?.data?.map((matricula: MatriculasInt) => {
        if (matricula?.grupo?.fundae === true) {
          user?.horarioLaboral === null || user?.horarioLaboral === undefined
            ? onOpenFundae()
            : null
        }
      })
  }, [user])

  const { data: matriculas } = useData({
    endpoint: EndpointTypes.MATRICULAS_ALUMNOS,
    ignoreRequest: !user?.auth || user?.role?.nombre !== UserRolEnum.ALUMNO,
  });

  useEffect(() => {
    if (user?.email && user?.id && user?.username)
      Sentry.setUser({
        id: user?.id,
        username: user?.username,
        email: user?.email,
      })
  }, [user?.email, user?.id, user?.username])

  const { handleGetInfo, handleSetInfo } = useTenantInfo()

  const icono = handleGetInfo({ key: "icono" });
  const nombre = handleGetInfo({ key: "nombre" });
  const colores = handleGetInfo({ key: "colores" });

  useEffect(() => {
    if (icono) {
      let link: any = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      link.href = icono;
    }
  }, [icono]);

  useEffect(() => {
    iconoTenant()
      .then((response) => {
        if (response?.icono && response?.icono !== icono)
          handleSetInfo({ key: "icono", value: response?.icono })
        if (response?.nombre && response?.nombre !== nombre)
          handleSetInfo({ key: "nombre", value: response?.nombre })
        if (response?.colores && response?.colores !== colores)
          handleSetInfo({ key: "colores", value: response?.colores })
      })
      .catch((error) => console.error(error))
  }, [])

  return (
    <div>
      <Router basename='/'>
        <AuthContext.Provider value={{ user, setUser, login, logout, refreshUser }} >
          <UrlContext.Provider value={url}>
            <ProgressController>
              <ChakraProvider theme={theme}>
                <OutWindowController>
                  <ScrollToTop />

                  <Flex>
                    {user.auth && <Sidebar />}

                    <Box flex="1">
                      <RouterController />
                    </Box>
                  </Flex>

                  {/* <ModalFundae isOpen={isOpenFundae} onClose={onCloseFundae} /> */}
                </OutWindowController>
              </ChakraProvider>
            </ProgressController>
          </UrlContext.Provider>
        </AuthContext.Provider>
      </Router>
    </div>
  )
}

export default App
