import { Modal, ModalHeader, ModalOverlay, ModalContent, Text, ModalBody, Icon, ModalCloseButton, Flex, Box, Button, ModalFooter } from "@chakra-ui/react";
import { BsPersonCheckFill } from "react-icons/bs";
import { LeccionInt } from "../../../../interfaces/CursosInt";
import ReactSignatureCanvas from "react-signature-canvas";
import SignatureCanvas from 'react-signature-canvas'
import { useRef } from "react";
import { base64ToFile } from "../../../../shared/utils/functions/base64ToFile";
import { addFirmaSesion } from "../../../../shared/middlewares/asistencias.middleware";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    leccion: LeccionInt | null;
    Refresh: () => void;
}

export const ModalFirma = ({ isOpen, onClose, leccion, Refresh }: Props) => {
    const sigCanvas = useRef<SignatureCanvas | null>(null);

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            isCentered
            size="2xl"
            closeOnOverlayClick={false}
        >
            <ModalOverlay />

            <ModalContent
                rounded="14px"
                maxH="95vh"
            >
                <ModalHeader
                    roundedTop="14px"
                    bg="pureblack"
                    px="20px"
                    py="12px"
                    display="flex"
                    alignItems="center"
                    gap="12px"
                >
                    <Icon
                        as={BsPersonCheckFill}
                        color="purewhite"
                        boxSize="24px"
                    />

                    <Text
                        fontSize="14px"
                        fontWeight="700"
                        color="purewhite"
                        letterSpacing="-0.14px"
                    >
                        Confirmar asistencia
                    </Text>

                    <ModalCloseButton
                        color="purewhite"
                    />
                </ModalHeader>

                <ModalBody
                    overflowY="auto"
                    overflowX="hidden"
                    css={{
                        '&::-webkit-scrollbar': {
                            w: '7px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            borderRadius: '20px',
                            bg: `light_grey`,
                        },
                    }}
                    display="flex"
                    flexDirection="column"
                >
                    <Flex
                        direction="column"
                        gap="20px"
                        mt="20px"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Text
                            textAlign="center"
                            w="70%"
                            fontSize="16px"
                            fontWeight="600"
                            color="font"
                            letterSpacing="-0.16px"
                        >
                            Declaro y firmo responsablemente que he asistido al 100% de la sesión indicada:
                        </Text>

                        <Flex
                            w="100%"
                            justifyContent="center"
                            alignItems="center"
                            direction="column"
                        >
                            <Flex
                                h="fit-content"
                                w="fit-content"
                                border="1px solid"
                                borderColor="main"
                                overflow="hidden"
                                rounded="10px"
                            >
                                <ReactSignatureCanvas
                                    penColor='black'
                                    canvasProps={{
                                        width: 600,
                                        height: 200,
                                        className: 'sigCanvas'
                                    }}
                                    ref={sigCanvas}
                                />
                            </Flex>
                        </Flex>
                    </Flex>
                </ModalBody>

                <ModalFooter
                    justifyContent="space-around"
                    alignItems="center"
                >
                    <Button
                        bg="main"
                        color="purewhite"
                        fontWeight="700"
                        fontSize="16px"
                        lineHeight="22px"
                        letterSpacing="-0.408px"
                        rounded="10px"
                        h="fit-content"
                        w="fit-content"
                        px="16px"
                        py="10px"
                        onClick={(e: React.MouseEvent) => {
                            e.stopPropagation()

                            sigCanvas.current?.clear()
                        }}
                    >
                        Limpiar
                    </Button>

                    <Button
                        bg="main"
                        color="purewhite"
                        fontWeight="700"
                        fontSize="16px"
                        lineHeight="22px"
                        letterSpacing="-0.408px"
                        rounded="10px"
                        h="fit-content"
                        w="fit-content"
                        px="35px"
                        py="10px"
                        onClick={(e: React.MouseEvent) => {
                            e.stopPropagation()

                            const dataUrl = sigCanvas.current?.toDataURL("image/png")

                            const file = base64ToFile(dataUrl!, "firma.png")

                            addFirmaSesion({
                                leccionId: leccion?.id!,
                                firma: file
                            })
                                .then(() => Refresh())
                                .finally(() => onClose())
                        }}
                    >
                        Firmar
                    </Button>
                </ModalFooter>
            </ModalContent >
        </Modal >
    )
}
