import { useEffect, useState } from "react";
import { HorarioLaboral } from "../../../utils/types/TipoJornadaLaboralEnum";
import { Flex, Icon, IconButton, Box } from "@chakra-ui/react";
import { BiCheckSquare, BiSquare, BiTrash, BiPlus } from "react-icons/bi";
import { TimePicker } from "./TimePicker";

interface Props {
    label: string;
    isDisabled?: boolean;
    isDefaultChecked: boolean;
    times: HorarioLaboral[];
    onChange: (newTimes: HorarioLaboral[]) => void;
}

export const HorarioLaboralItem = ({
    label = 'Lunes',
    times = [],
    isDisabled: _isDisabled = false,
    isDefaultChecked = false,
    onChange = () => { },
}: Props): JSX.Element => {
    const [isChecked, setIsChecked] = useState<boolean>(isDefaultChecked);
    const [isDisabled, setIsDisabled] = useState<boolean>(_isDisabled);

    useEffect(() => {
        setIsChecked(isDefaultChecked);
    }, [isDefaultChecked]);

    useEffect(() => {
        setIsDisabled(_isDisabled);
    }, [_isDisabled]);

    const addTime = () => {
        const newTimes: HorarioLaboral[] = [...times];

        const lastTime = newTimes[newTimes.length - 1]?.fin || '14:00';
        let endTime = '';

        if (lastTime.includes(":")) {
            const startTime = Number(lastTime.split(':')[0]) + 1;
            endTime = startTime?.toFixed(0) + ':00';
        }

        newTimes.push({ inicio: endTime || '', fin: '21:00' });

        if (newTimes.length >= 3) setIsDisabled(true || _isDisabled);

        onChange(newTimes);
    };

    const deleteTime = (id: number) => {
        const newTimes: HorarioLaboral[] = [...times];

        newTimes.splice(id, 1);

        if (newTimes.length < 2) setIsDisabled(false || _isDisabled);

        onChange(newTimes);
    };

    const onUpdateTime = (id: number, newTime: HorarioLaboral) => {
        const newTimes: HorarioLaboral[] = [...times];

        newTimes[id] = newTime;

        onChange(newTimes);
    };

    const onCheck = () => {
        if (isChecked) {
            // Si ya está marcado el día, borramos todos los tiempos y cerramos check.

            onChange([]);
            setIsChecked(false);
        } else {
            // Si no estaba marcado, creamos nuevo tiempo y abrimos.

            onChange([{ inicio: '08:00', fin: '14:00' }]);
            setIsChecked(true);
        }
    };

    return (
        <Flex
            gap="5px"
            align="start"
            bg="purewhite"
            border="1px solid"
            borderColor="#E6E6EA"
            rounded="10px"
            p="15px"
        >
            <Flex gap="15px" mt="8px" align="center">
                <Icon
                    as={isChecked ? BiCheckSquare : BiSquare}
                    minW="24px"
                    boxSize="24px"
                    cursor="pointer"
                    color={isChecked ? 'primary' : 'gray_5'}
                    onClick={onCheck}
                />

                <Box minW="80px" w="80px" color="font" fontWeight="600" fontSize="15px" lineHeight="17px">
                    {label}
                </Box>
            </Flex>


            {isChecked ? (
                <Flex w="100%" direction="column" gap="12px" ml="200px">
                    {times?.map((time, index) => (
                        <Flex alignItems="center" gap="12px" minH="40px" key={index}>
                            <TimePicker
                                value={time.inicio}
                                max={time.fin}
                                placeholder={'08:00'}
                                onChange={(event) => onUpdateTime(index, { inicio: event, fin: times[index].fin })}
                            />

                            <TimePicker
                                value={time.fin}
                                min={time.inicio}
                                placeholder={'21:00'}
                                onChange={(event) => onUpdateTime(index, { inicio: times[index].inicio, fin: event })}
                            />

                            <IconButton
                                p="0px"
                                bg="none"
                                maxH="40px"
                                maxW="40px"
                                boxSize="40px"
                                aria-label="Delete time"
                                _hover={{ bg: 'gray_2' }}
                                onClick={() => deleteTime(index)}
                                icon={<Icon as={BiTrash} cursor="pointer" boxSize="20px" />}
                            />
                        </Flex>
                    ))}
                </Flex>
            ) : (
                <Flex
                    ml="200px"
                    w="100%"
                    justifyContent="center"
                    alignItems="center"
                    minH="40px"
                    px="15px"
                    rounded="5px"
                    fontWeight="500"
                    fontSize="14px"
                    lineHeight="17px"
                    color={"fail"}
                    bg={label === "Sabado" || label === "Domingo" ? "fail_bg" : "transparent"}
                    border={label === "Sabado" || label === "Domingo" ? "none" : "1px solid"}
                    borderColor="fail_bg"
                >
                    Sin jornada laboral
                </Flex>
            )}

            {isChecked && (
                <IconButton
                    p="0px"
                    bg="none"
                    maxH="40px"
                    maxW="40px"
                    boxSize="40px"
                    onClick={addTime}
                    isDisabled={isDisabled}
                    _hover={isDisabled ? {} : { bg: 'gray_2' }}
                    icon={<Icon as={BiPlus} color="dark" boxSize="20px" />}
                    aria-label="Add time"
                />
            )}
        </Flex>
    );
};