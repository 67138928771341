import { Flex, Skeleton } from "@chakra-ui/react"

export const InformationExamenSkeleton = () => {
    return (
        <Flex
            p="40px"
            direction="column"
            gap="60px"
            h="fit-content"
            w="100%"
        >
            <Flex
                gap="70px"
                w="100%"
            >
                <Flex
                    direction="column"
                    flex="1"
                    gap="30px"
                >
                    <Flex
                        direction="column"
                        gap="18px"
                    >
                        <Skeleton
                            h="33px"
                            w="100%"
                            rounded="4px"
                        />

                        <Skeleton
                            h="68px"
                            w="100%"
                            rounded="4px"
                        />
                    </Flex>

                    <Flex
                        direction="column"
                        gap="10px"
                    >
                        <Skeleton
                            h="97px"
                            w="100%"
                            rounded="10px"
                        />

                        <Skeleton
                            h="97px"
                            w="100%"
                            rounded="10px"
                        />

                        <Skeleton
                            h="97px"
                            w="100%"
                            rounded="10px"
                        />
                    </Flex>
                </Flex>

                <Flex
                    flex="1"
                    p="15px"
                    bg="purewhite"
                    rounded="20px"
                >
                    <Skeleton
                        h="430px"
                        w="100%"
                        rounded="10px"
                    />
                </Flex>
            </Flex>



            <Flex
                w="100%"
                justifyContent="start"
            >
                <Skeleton
                    w="231px"
                    h="54px"
                    rounded="14px"
                />
            </Flex>

        </Flex >
    )
}