export enum EndpointTypes {
    ANUNCIOS = "/anuncios",
    CURSOS = "/cursos",
    LECCIONES = "/lecciones",
    MODULOS = "/modulos",
    NOTAS = "/notas",
    NOTIFICACIONES = "/notificaciones",
    ENTREGABLES = "/entregables",
    ASIGNACIONES = "/asignaciones",
    LIQUIDACIONES = "/liquidaciones",
    GRUPOS = "/grupos",
    GRUPOS_TUTOR = "/gruposTutor",
    MENSAJERIA = "/mensajeria/mensajes",
    MENSAJERIA_CONVERSACION = "/mensajeria/conversaciones",
    USUARIOS = "/users",
    FEEDBACK_CONFIG = "/feedbackConfigs",
    PROGRESOS = "/progresos",
    EXAMENES = "/examenes",
    END_EXAMENES = "/examenes/checkExamen",
    INFO_TENANT = "/tenant/info",
    RUTAS = "/rutas",
    CORRECCIONES = "/correcciones",
    TEMA = "/foro/temas",
    PREGUNTAS = "/foro/preguntas",
    RESPUESTAS = "/foro/respuestas",
    MATRICULAS = "/matriculas",
    MATRICULAS_ALUMNOS = "/matriculasAlumno",
    MATRICULAS_PROXIMAS = "/proximasMatriculasAlumno",
    MATRICULAS_TUTOR = "/matriculasTutor",
    DASHBOARD = "/statsDashboard",
    SESIONES_DASHBOARD = "/statsSesionesDashboard",
    CURSOS_NOTAS = "/cursosConNotas",
    FIRMAS = "/firmas",
    ASISTENCIAS = "/asistencias",
    EVALUACIONES = "/evaluaciones"
}
