import {
    Box,
    Container,
    Heading,
    Text,
    Image,
    VStack,
    HStack,
    Button,
    Badge,
    SimpleGrid,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Avatar,
    Stat,
    StatLabel,
    StatNumber,
    MergeWithAs,
    ChakraProps,
    ImageProps,
    SimpleGridProps,
} from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons'
import { useState, ForwardRefExoticComponent, DetailedHTMLProps, ElementType, HTMLAttributes, ImgHTMLAttributes } from 'react';
import { useParams, Link } from 'react-router-dom'
import { motion, AnimatePresence } from 'framer-motion';
import { Seo } from '../../../../shared/components/Seo/Seo.tsx';
import { ModulosInt } from '../../../../interfaces/CursosInt.ts';
import { useDataId } from '../../../../shared/hooks/useDataId.tsx';
import { EndpointTypes } from '../../../../shared/utils/types/EndpointTypes.ts';
import { LoadingOverlay } from '../../components/LoadingOverlay.tsx';
import { InscripcionModal } from '../../components/InscripcionModal.tsx';
import defaultPortada from "../../../../assets/img/defaultPortada.png"

const MotionBox = motion(Box as string | ForwardRefExoticComponent<MergeWithAs<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, any, Omit<ChakraProps, never>, ElementType>>)
const MotionImage = motion(Image as string | ForwardRefExoticComponent<MergeWithAs<DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>, any, ImageProps, ElementType>>)
const MotionSimpleGrid = motion(SimpleGrid as string | ForwardRefExoticComponent<MergeWithAs<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, any, SimpleGridProps, ElementType>>);


export const InformationCursoPublico = () => {
    const { slug } = useParams<{ slug: string }>();
    const [isOpen, setIsOpen] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);

    const { data: curso, loading } = useDataId({
        endpoint: EndpointTypes.CURSOS,
        id: slug,
        isPublic: true
    })

    if (loading) return <LoadingOverlay />;
    if (!curso) return null;

    const seoTitle = `Curso de ${curso.nombre || 'Curso sin nombre'} | Nombre de tu plataforma`;
    const seoDescription = (curso.descripcion || '').substring(0, 160);

    return (
        <>
            <Seo title={seoTitle} description={seoDescription} />
            <Container
                maxW="container.xl"
                py={10}
            >
                <VStack
                    spacing={12}
                    align="stretch"
                >
                    <Box>
                        <Button
                            as={Link}
                            to="/public/cursos"
                            leftIcon={<ArrowBackIcon />}
                            colorScheme="main"
                            variant="outline"
                            borderRadius="md"
                        >
                            Volver al listado de cursos
                        </Button>
                    </Box>


                    <MotionSimpleGrid
                        columns={{ base: 1, md: 2 }}
                        spacing={10}
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        <MotionBox
                            whileHover={{ scale: 1.05 }}
                            transition={{ duration: 0.2 }}
                        >
                            <MotionImage
                                src={curso?.imagen && curso?.imagen.url ? curso?.imagen.url : defaultPortada}
                                alt={curso?.nombre || 'Imagen del curso'}
                                borderRadius="xl"
                                boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
                                width="100%"
                                height="auto"
                                objectFit="cover"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ delay: 0.2, duration: 0.5 }}
                            />
                        </MotionBox>

                        <MotionBox
                            initial={{ opacity: 0, x: 50 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ delay: 0.3, duration: 0.5 }}
                        >
                            <Heading as="h1" size="2xl" mb={4} color="font">
                                {curso?.nombre || 'Curso sin nombre'}
                            </Heading>

                            <HStack spacing={4} mb={4}>
                                <Badge colorScheme="green" fontSize="md" px={3} py={1} borderRadius="md">Nuevo</Badge>
                                {curso?.tutorizado && <Badge colorScheme="blue" fontSize="md" px={3} py={1} borderRadius="md">Tutorizado</Badge>}
                            </HStack>

                            <Text fontSize="3xl" color="main" fontWeight="bold" mb={6}>
                                Duración: {curso?.duracion} horas
                            </Text>

                            <Box color="font" fontSize="lg" mb={8}>
                                <div dangerouslySetInnerHTML={{ __html: curso?.descripcion }} />
                            </Box>

                            {/* Botones de Acción */}
                            <motion.div
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                            >
                                <Button bg="main" color="white" size="lg" width="100%" onClick={() => setIsOpen(true)} borderRadius="md" _hover={{ opacity: 0.8 }}>
                                    Inscribirse Ahora
                                </Button>
                            </motion.div>
                        </MotionBox>
                    </MotionSimpleGrid>

                    {/* Estadísticas del Curso */}
                    <MotionBox
                        bg="gray.50"
                        p={8}
                        borderRadius="xl"
                        boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.5, duration: 0.5 }}
                    >
                        <Heading as="h2" size="xl" mb={6} textAlign="center" color="font">
                            Estadísticas del Curso
                        </Heading>
                        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={8}>
                            <Stat bg="white" p={6} borderRadius="lg" boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)">
                                <StatLabel fontSize="lg" color="font">Total de Módulos</StatLabel>
                                <StatNumber fontSize="4xl" color="main">{curso?.meta.totalModulos}</StatNumber>
                            </Stat>
                            <Stat bg="white" p={6} borderRadius="lg" boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)">
                                <StatLabel fontSize="lg" color="font">Total de Lecciones</StatLabel>
                                <StatNumber fontSize="4xl" color="main">{curso?.meta.totalLecciones}</StatNumber>
                            </Stat>
                            <Stat bg="white" p={6} borderRadius="lg" boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)">
                                <StatLabel fontSize="lg" color="font">Total de Entregables</StatLabel>
                                <StatNumber fontSize="4xl" color="main">{curso?.meta.totalEntregables}</StatNumber>
                            </Stat>
                        </SimpleGrid>
                    </MotionBox>

                    {/* Contenido del Curso */}
                    <Box>
                        <Heading as="h2" size="xl" mb={6} color="font">
                            Contenido del Curso
                        </Heading>
                        <Accordion allowMultiple>
                            {curso?.modulos.map((modulo: ModulosInt) => (
                                <AccordionItem key={modulo.id} mb={4}>
                                    <AccordionButton bg="gray.100" _hover={{ bg: "gray.200" }} p={4} borderRadius="md">
                                        <Box flex="1" textAlign="left" fontWeight="bold" color="font">
                                            {modulo.nombre}
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                    <AnimatePresence>
                                        <motion.div
                                            initial={{ opacity: 0, height: 0 }}
                                            animate={{ opacity: 1, height: "auto" }}
                                            exit={{ opacity: 0, height: 0 }}
                                            transition={{ duration: 0.3, ease: "easeInOut" }}
                                        >
                                            <AccordionPanel pb={4} pt={4}>
                                                <VStack align="stretch" spacing={3}>
                                                    {modulo.lecciones.map((leccion) => (
                                                        <HStack key={leccion.id} bg="gray.50" p={3} borderRadius="md">
                                                            <Text fontWeight="bold" color="font">{leccion.nombre}</Text>
                                                        </HStack>
                                                    ))}
                                                </VStack>
                                            </AccordionPanel>
                                        </motion.div>
                                    </AnimatePresence>
                                </AccordionItem>
                            ))}
                        </Accordion>
                    </Box>

                    {/* Opiniones de los Clientes */}
                    <Box>
                        <Heading as="h2" size="lg" mt={10} mb={4} color="font">
                            Opiniones de los Clientes
                        </Heading>
                        <VStack spacing={5} align="stretch">
                            <HStack spacing={4}>
                                <Avatar name="Juan Pérez" src="https://ui-avatars.com/api/?name=Jon+Snow&background=0D8ABC&color=fff&size=128" />
                                <Box>
                                    <Text fontWeight="bold" color="font">Juan Pérez</Text>
                                    <Text color="gray.500">"Excelente curso, muy completo y bien explicado. Altamente recomendado."</Text>
                                </Box>
                            </HStack>
                            <HStack spacing={4}>
                                <Avatar name="María López" src="https://ui-avatars.com/api/?name=Marie+Curie&background=0D8ABC&color=fff&size=128" />
                                <Box>
                                    <Text fontWeight="bold" color="font">María López</Text>
                                    <Text color="gray.500">"Me ayudó a entender conceptos complejos de una manera muy sencilla."</Text>
                                </Box>
                            </HStack>
                        </VStack>
                    </Box>

                    {/* Preguntas Frecuentes */}
                    <Box>
                        <Heading as="h2" size="lg" mt={10} mb={4} color="font">
                            Preguntas Frecuentes
                        </Heading>
                        <Accordion allowToggle>
                            <AccordionItem>
                                <AccordionButton>
                                    <Box flex="1" textAlign="left" color="font">
                                        ¿Cuánto tiempo tengo acceso al curso?
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                                <AccordionPanel pb={4} color="font">
                                    Tienes acceso de por vida al curso, lo que significa que puedes verlo cuantas veces desees.
                                </AccordionPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionButton>
                                    <Box flex="1" textAlign="left" color="font">
                                        ¿Puedo obtener un certificado?
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                                <AccordionPanel pb={4} color="font">
                                    Sí, al finalizar el curso obtendrás un certificado de participación.
                                </AccordionPanel>
                            </AccordionItem>
                        </Accordion>
                    </Box>

                    {/* Modal de Inscripción */}
                    <InscripcionModal
                        isOpen={isOpen}
                        onClose={() => setIsOpen(false)}
                        slug={slug!}
                        onSubmitSuccess={() => {
                            setIsOpen(false);
                            setShowConfirmation(true);
                            setTimeout(() => setShowConfirmation(false), 5000);
                        }}
                    />

                    {showConfirmation && (
                        <Box
                            position="fixed"
                            top="50%"
                            left="50%"
                            transform="translate(-50%, -50%)"
                            bg="green.100"
                            color="green.800"
                            p={4}
                            borderRadius="md"
                            boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
                            zIndex="tooltip"
                        >
                            <Text fontWeight="bold">
                                Gracias por tu interés. En breve nos pondremos en contacto contigo.
                            </Text>
                        </Box>
                    )}

                </VStack>
            </Container>
        </>
    );
}