import { Flex, Image, Link } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

interface Props {
    data: string;
}

export const LogoEmpresa = ({ data }: Props) => {

    return (
        <Flex
            p="15px"
            w="100%"
        >
            <Link
                as={RouterLink}
                to="/"
                display="flex"
                alignItems="center"
                _hover={{ textDecoration: "none" }}
                w="100%"
                boxSize="55px"
                justifyContent="center"
            >
                {
                    data ?
                        <Image
                            w="100%"
                            h="100%"
                            src={data}
                            alt='img/logo'
                            objectFit="contain"
                            rounded="8px"
                        />
                        :
                        <Image
                            w="100%"
                            h="100%"
                            src={`data:image/svg+xml;utf8,${defaultIcon}`}
                            alt='img/logo'
                            objectFit="contain"
                            rounded="8px"
                        />
                }
            </Link>
        </Flex>
    )
}

export const defaultIcon = `<svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="17" y="0.0274948" width="24" height="24" rx="4" transform="rotate(45 17 0.0274948)" fill="%233D3F65" stroke="%233D3F65" stroke-width="2"/>
</svg>`