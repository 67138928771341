import { Flex, Text, Image } from '@chakra-ui/react';
import entregable from '../../../assets/img/entregablesVacio.png';
import foro from "../../../assets/img/foroVacio.png"
import matriculas from "../../../assets/img/espacioVacio.png"
import notas from "../../../assets/img/notasVacias.png"
import mensajes from "../../../assets/img/ImagenConversacion.png";
import correcciones from "../../../assets/img/correccionesVacio.png";
import cursos from "../../../assets/img/cursosVacios.png";
import actividades from "../../../assets/img/notificacionesForoVacias.png"
import anuncios from "../../../assets/img/anunciosVacios.png"


export enum TypeCardSinInformacion {
    FORO = "foro",
    MATRICULAS = "matriculas",
    ENTREGABLES = "entregables",
    CORRECCIONES = "correcciones",
    CURSOS = "cursos",
    ACTIVIDADES = "actividades",
    NOTAS = "notas",
    MENSAJES = "mensajes",
    STATS = "stats",
    LIQUIDACIONES = "liquidaciones",
    GRUPOS = "grupos",
    ANUNCIOS = "anuncios",
    DASHBOARD = "dashboard",
    RUTAS = "rutas",
    EXAMENES = "examenes",
    EVALUACIONES = "evaluaciones"
}
interface Props {
    mensaje?: string;
    titulo?: string;
    type: TypeCardSinInformacion;
    isCard?: boolean;
    isRow?: boolean;
    fullRounded?: boolean;
    withBorder?: boolean;
}

export const CardSinInformacion = ({
    mensaje,
    titulo,
    type,
    isCard = false,
    isRow = false,
    fullRounded,
    withBorder
}: Props) => {
    return (
        <Flex
            flexDirection={isRow ? "row" : "column"}
            alignItems="center"
            justify="center"
            gap={isCard ? "20px" : "40px"}
            bg="purewhite"
            py={isCard ? "0" : "50px"}
            roundedRight="20px"
            rounded={fullRounded ? "20px" : ""}
            w="full"
            border={withBorder ? "1px solid" : ""}
            borderColor="border"
        >

            <Image
                w={isCard ? "180px" : "300px"}
                src={defaultData[type]?.image}
            />

            <Flex
                gap="10px"
                flexDirection="column"
            >

                <Text
                    align="center"
                    fontSize={
                        isCard
                            ? "18px" : "24px"
                    }
                    fontWeight="600"
                    color="font"
                    whiteSpace="nowrap">

                    {titulo ? titulo : defaultData[type]?.titulo}

                </Text>

                <Text
                    align="center"
                    fontSize={
                        isCard
                            ? "14px" : "14px"
                    }
                    fontWeight="400"
                    color="font"
                >
                    {mensaje ? mensaje : defaultData[type]?.mensaje}
                </Text>

            </Flex>
        </Flex>
    );
}

const defaultData: Record<TypeCardSinInformacion, {
    mensaje: string;
    titulo: string;
    image: string;
}> = {
    [TypeCardSinInformacion.ENTREGABLES]: {
        mensaje: "Aquí podrás consultar el estado de tus entregas.",
        titulo: "Este espacio está vacío",
        image: entregable
    },
    [TypeCardSinInformacion.FORO]: {
        mensaje: "Aún no hay publicaciones aquí, pero crea y una duda y contribuye al debate.",
        titulo: "Comparte tus dudas",
        image: foro
    },
    [TypeCardSinInformacion.MATRICULAS]: {
        mensaje: "Actualmente no tienes ninguna matrícula assignada.",
        titulo: 'No hay matrículas por el momento',
        image: matriculas
    },
    [TypeCardSinInformacion.CORRECCIONES]: {
        mensaje: 'Aquí podrás consultar el estado de tus correciones.',
        titulo: 'Este espacio está vacío',
        image: correcciones
    },
    [TypeCardSinInformacion.CURSOS]: {
        mensaje: 'Aquí aparecerán tus cursos activos.',
        titulo: 'Este espacio aún no tiene datos',
        image: cursos
    },
    [TypeCardSinInformacion.ACTIVIDADES]: {
        mensaje: 'En este lugar podrás encontras la actividad más reciente en los foros.',
        titulo: 'Aquí no hay nada por el momento',
        image: actividades
    },
    [TypeCardSinInformacion.NOTAS]: {
        titulo: "El bloc de notas está vacío",
        mensaje: "Este espacio está listo para tus ideas. Agrega notas en tus lecciones y hazlo tuyo.",
        image: notas
    },
    [TypeCardSinInformacion.MENSAJES]: {
        mensaje: 'Navega por las conversaciones para interaccionar con tus tutores.',
        titulo: 'Explora tus mensajes',
        image: mensajes
    },
    [TypeCardSinInformacion.STATS]: {
        mensaje: 'Aquí podrás encontrar información sobre tus cursos activos',
        titulo: 'Sin datos disponibles',
        image: correcciones
    },
    [TypeCardSinInformacion.LIQUIDACIONES]: {
        mensaje: 'Aquí podrás encontrar información sobre tus liquidaciones.',
        titulo: 'Sin liquidaciones pendientes',
        image: matriculas,
    },
    [TypeCardSinInformacion.GRUPOS]: {
        mensaje: 'Aquí podrás encontrar información sobre tus grupos.',
        titulo: 'Sin grupos disponibles',
        image: matriculas,
    },
    [TypeCardSinInformacion.ANUNCIOS]: {
        mensaje: 'Aquí podras encontrar información relacionada con los cursos en los que estás matriculado.',
        titulo: 'No hay anuncios en este momento',
        image: anuncios,
    },
    [TypeCardSinInformacion.DASHBOARD]: {
        mensaje: 'Aquí podras encontrar el resumen tus avances en los cursos en los que estás matriculado.',
        titulo: 'No hay datos disponibles',
        image: anuncios,
    },
    [TypeCardSinInformacion.RUTAS]: {
        mensaje: 'Aquí podras encontrar tus rutas de aprendizaje.',
        titulo: 'No hay datos disponibles',
        image: correcciones,
    },
    [TypeCardSinInformacion.EXAMENES]: {
        mensaje: 'Aquí podras encontrar tus exámenes disponibles',
        titulo: 'No hay datos disponibles',
        image: correcciones,
    },
    [TypeCardSinInformacion.EVALUACIONES]: {
        mensaje: 'Aquí podras encontrar tus evaluaciones realizadas',
        titulo: 'No hay evaluaciones realizadas',
        image: correcciones,
    }
}