import { Box, Button, Flex, Icon, Image, Img, Text } from "@chakra-ui/react"
import { BiCheckSquare, BiRevision, BiSolidSad, BiTimeFive } from "react-icons/bi";
import { ExamenInt } from "../../../interfaces/ExamenInt";

interface Props {
    onStartExamen: () => void;
    examen: ExamenInt;
}

export const ExamenSuspendido = ({ onStartExamen, examen }: Props) => {

    return (
        <Flex
            direction="column"
            gap="40px"
            alignItems="center"
            justifyContent="center"
            h="full"
        >
            {examen?.curso?.icono?.url &&
                <Flex
                    position="relative"
                >
                    <Flex
                        h="125px"
                        w="125px"
                        rounded="20px"
                        overflow="hidden"
                        bg="purewhite"
                        boxShadow="-4px -4px 20px 0px rgba(0, 0, 0, 0.01), 0px 4px 20px 0px rgba(0, 0, 0, 0.01)"
                    >
                        <Image
                            objectFit={"cover"}
                            src={examen?.curso?.icono?.url}
                        />
                    </Flex>

                    <Flex
                        rounded="100%"
                        boxSize="55px"
                        bg="fail"
                        position="absolute"
                        justifyContent="center"
                        alignItems="center"
                        border="5px solid"
                        borderColor="purewhite"
                        bottom="-22px"
                        right="-22px"
                        zIndex="999"
                    >
                        <Icon
                            as={BiSolidSad}
                            color="white"
                            boxSize="30px"
                        />
                    </Flex>
                </Flex>
            }

            <Flex
                direction="column"
                justifyContent="center"
                alignItems="center"
                gap="20px"
            >
                <Text
                    fontSize="24px"
                    lineHeight="29.05px"
                    fontWeight="700"
                >
                    Vaya...
                </Text>

                <Text
                    textAlign="center"
                    fontSize="15px"
                    lineHeight="29.05px"
                    fontWeight="500"
                    w="50%"
                >
                    No has logrado superar el examen pero no te rindas, todavía te quedan 2 intentos para realizar la certificación de SpringBoot Avanzado. Aprovecha y mejora tus conocimientos antes de reintentarlo.
                </Text>
            </Flex>

            <Flex
                gap="30px"
            >
                <Flex
                    p="15px"
                    bg="purewhite" border="1px solid"
                    borderColor="#E6E6EA"

                    rounded="20px"
                    gap="18px"
                    alignItems="center"
                >
                    <Flex
                        bg="variant"
                        rounded="10px"
                        p="10px"
                        h="fit-content"
                    >
                        <Icon
                            as={BiCheckSquare}
                            color="main"
                            boxSize="38px"
                        />
                    </Flex>

                    <Flex
                        direction="column"
                        gap="8px"
                    >
                        <Text
                            color={"#8C909C"}
                            fontWeight="700"
                            fontSize={"14px"}
                        >
                            Número de preguntas
                        </Text>

                        <Text
                            color={"font"}
                            fontWeight="700"
                            fontSize="24px"
                        >
                            {examen?.preguntas?.length}
                        </Text>
                    </Flex>
                </Flex>

                <Flex
                    p="15px"
                    bg="purewhite"
                    border="1px solid" borderColor="#E6E6EA"

                    rounded="20px"
                    gap="18px"
                    alignItems="center"
                >
                    <Flex
                        bg="variant"
                        rounded="10px"
                        p="10px"
                        h="fit-content"
                    >
                        <Icon
                            as={BiTimeFive}
                            color="main"
                            boxSize="38px"
                        />
                    </Flex>

                    <Flex
                        direction="column"
                        gap="8px"
                    >
                        <Text
                            color={"#8C909C"}
                            fontWeight="700"
                            fontSize={"14px"}
                        >
                            Tiempo disponible
                        </Text>

                        <Text
                            color={"font"}
                            fontWeight="700"
                            fontSize="24px"
                        >
                            10m 43s
                        </Text>
                    </Flex>
                </Flex>

                <Flex
                    p="15px"
                    bg="purewhite"
                    border="1px solid"
                    borderColor="#E6E6EA"
                    rounded="20px"
                    gap="18px"
                    alignItems="center"
                >
                    <Flex
                        bg="variant"
                        rounded="10px"
                        p="10px"
                        h="fit-content"
                    >
                        <Icon
                            as={BiRevision}
                            color="main"
                            boxSize="38px"
                        />
                    </Flex>

                    <Flex
                        direction="column"
                        gap="8px"
                    >
                        <Text
                            color={"#8C909C"}
                            fontWeight="700"
                            fontSize={"14px"}
                        >
                            Intentos restantes
                        </Text>

                        <Text
                            color={"font"}
                            fontWeight="700"
                            fontSize="24px"
                        >
                            3/3
                        </Text>
                    </Flex>
                </Flex>

            </Flex>

            <Flex
                gap="20px"
            >
                <Button
                    color="#FFF"
                    fontSize="18px"
                    fontWeight="700"
                    bg="secondary"
                    w="fit-content"
                    h="fit-content"
                    rounded="14px"
                    py="15px"
                    px="24px"
                    _hover={{ bg: "variant" }}
                    cursor="pointer"
                    rightIcon={
                        <Icon
                            as={BiRevision}
                            boxSize="24px"
                        />
                    }
                >
                    Intentar de nuevo
                </Button>
            </Flex>
        </Flex>
    )
}