export enum LeccionTipoEnum {
    FICHERO = 'fichero',
    EMBEBIDO = 'embebido',
    TEXTO = 'texto',
    ENTREGABLE = 'entregable',
    URL = 'url',
    SESION_VIVO = 'sesion_vivo'
}

export enum ComponenteLeccionEnum {
    VIDEO = 'video',
    PDF_VIEWER = 'pdf_viewer',
    MARKDOWN_PREVIEW = 'markdown_preview',
    IFRAME = 'iframe',
    LINK = 'link',
    IMAGEN = 'imagen',
    ENTREGABLE = 'entregable',
    SESION_VIVO = 'sesion_vivo'
}

export enum LeccionModeEnum {
    BLOQUEADA = 'blocked',
    CARGANDO = 'loading',
    SIGUIENTE = 'next',
    END = 'end'
}

export enum SubtipoLeccion {
    FICHERO = 'fichero',
    EMBEBIDO = 'embebido',
    TEXTO = 'texto',
}

export enum PreviewTipoEnum {
    TEORIA = 'teoria',
    ENTREGA = 'entrega'
}