import { Box, Flex, Icon, Modal, ModalContent, ModalOverlay, Text, useDisclosure } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useOutWindowContext } from "../../../../shared/context/outWindow.context";
import { LeccionInt } from "../../../../interfaces/CursosInt";
import { HiOutlinePaperClip } from "react-icons/hi";
import { capitalizeFirst } from "../../../../shared/utils/functions/textTransform";
import { BsArrowsFullscreen } from "react-icons/bs";
import { PreviewTipoEnum } from "../../../../shared/utils/types/LeccionTipoEnum";
import { LiveWorksheet } from "./LiveWorksheet";

interface Props {
    leccion: LeccionInt;
    tipo?: PreviewTipoEnum;
}

const transformUrlSlides = (url: string | null | undefined) => {
    if (!url) return ""

    if (url?.includes('slides')) {
        return url.replace("/embed", "/fullscreen")
    }

    return url;
}

export const IframeViewer = ({
    leccion,
    tipo = PreviewTipoEnum.TEORIA
}: Props) => {
    const { outWindow } = useOutWindowContext();
    const { onOpen: onOpenFullScreen, onClose: onCloseFullScreen, isOpen: isOpenFullScreen } = useDisclosure();
    const [iframeHeight, setIframeHeight] = useState<number>(0);

    const exitFullScreen = () => {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        }
    };

    useEffect(() => {
        if (outWindow)
            exitFullScreen();
    }, [outWindow])

    useEffect(() => {
        const updateIframeHeight = () => {
            const windowHeight = window.innerHeight;
            const newHeight = windowHeight - 410;
            setIframeHeight(newHeight > 0 ? newHeight : 0);
        };

        updateIframeHeight();
        window.addEventListener('resize', updateIframeHeight);

        return () => {
            window.removeEventListener('resize', updateIframeHeight);
        };
    }, []);

    return (
        <Flex
            p={
                tipo === PreviewTipoEnum.TEORIA && !leccion?.contenido?.includes("liveworksheets")
                    ? "40px"
                    : tipo === PreviewTipoEnum.TEORIA && leccion?.contenido?.includes("liveworksheets")
                        ? "40px 0px 40px 0px"
                        : "0"
            }
            border={tipo === PreviewTipoEnum.TEORIA ? "1px solid" : "none"}
            w="100%"
            borderColor="#E6E6EA"
            direction="column"
            rounded="20px"
            gap="40px"
            bg="purewhite"
            justifyContent="center"
        >
            <Flex
                direction="column"
                display={tipo === PreviewTipoEnum.TEORIA ? "flex" : "none"}
                px={tipo === PreviewTipoEnum.TEORIA && leccion?.contenido?.includes("liveworksheets") ? "40px" : "0"}
            >
                <Text
                    color="font"
                    fontSize="24px"
                    fontStyle="normal"
                    fontWeight="700"
                >
                    {leccion?.nombre}
                </Text>

                <Flex
                    gap="10px"
                    alignItems="center"
                >
                    <Icon
                        as={HiOutlinePaperClip}
                        color="#A5A8B3"
                        boxSize="20px"
                    />

                    <Text
                        color="#A5A8B3"
                        fontSize="15px"
                        fontWeight="400"
                        lineHeight="160%"
                        letterSpacing="0.075px"
                    >
                        Lección
                    </Text>
                </Flex>
            </Flex>

            {leccion?.contenido?.includes("liveworksheets") ?
                <Flex
                    w="100%"
                    justifyContent="center"
                    alignItems="center"
                >
                    <LiveWorksheet
                        url={leccion?.contenido}
                    />
                </Flex>
                :
                <Flex
                    w="100%"
                    direction="column"
                    justifyContent="center"
                    gap="5px"
                    alignItems="center"
                >
                    <iframe
                        id="iframe-contenido"
                        width="100%"
                        height={`${iframeHeight}px`}
                        src={transformUrlSlides(leccion?.contenido) || ''}
                        style={{ overflow: 'hidden', border: 'none' }}
                    />

                    <Flex
                        bg="#444444"
                        w="100%"
                        justifyContent="end"
                        py="5px"
                        px="10px"
                        rounded="4px"
                    >
                        <Icon
                            as={BsArrowsFullscreen}
                            boxSize="20px"
                            mr="10px"
                            color="purewhite"
                            cursor="pointer"
                            onClick={(e: React.MouseEvent) => {
                                e.stopPropagation();

                                onOpenFullScreen();
                            }}
                        />
                    </Flex>
                </Flex>
            }

            <ModalFullScreen
                isOpen={isOpenFullScreen}
                onClose={onCloseFullScreen}
                leccion={leccion}
            />
        </Flex>
    );
};


interface PropsModal {
    isOpen: boolean;
    onClose: () => void;
    leccion: LeccionInt | undefined;
}

const ModalFullScreen = ({ isOpen, onClose, leccion }: PropsModal) => {
    const totalHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

    return (
        <Modal
            size="full"
            isOpen={isOpen}
            onClose={onClose}
        >
            <ModalOverlay />

            <ModalContent>
                <Flex
                    w="100%"
                    direction="column"
                    justifyContent="center"
                    gap="5px"
                    alignItems="center"
                >
                    <iframe
                        width="100%"
                        height={totalHeight - 35}
                        src={transformUrlSlides(leccion?.contenido)}
                        style={{ overflow: 'hidden', border: 'none' }}
                    />

                    <Flex
                        bg="#444444"
                        w="100%"
                        justifyContent="end"
                        py="5px"
                        px="10px"
                        rounded="4px"
                    >
                        <Icon
                            as={BsArrowsFullscreen}
                            boxSize="20px"
                            mr="10px"
                            color="purewhite"
                            cursor="pointer"
                            onClick={(e: React.MouseEvent) => {
                                e.stopPropagation();

                                onClose();
                            }}
                        />
                    </Flex>
                </Flex>
            </ModalContent>
        </Modal>
    )
}