import { Badge, Flex, Text } from "@chakra-ui/react";
import { CustomColumnProps } from "../../../../../shared/components/Table/Table";
import { format } from "../../../../../shared/utils/functions/formatDate";
import { LiquidacionesInt } from "../../../../../interfaces/LiquidacionesInt";

export const LiquidacionesColumns = (): CustomColumnProps[] => {
    const columns: CustomColumnProps[] = [
        {
            header: 'Nº de Factura',
            field: 'numeroFactura',
            key: 'numeroFactura',
            body: (rowData: LiquidacionesInt) =>
                <Text
                    p="16px"
                    fontFamily="inter"
                    color="font"
                    fontSize="14px"
                    fontWeight="400"
                    fontStyle="normal"
                    lineHeight="150%"
                >
                    {rowData?.numeroFactura || "--"}
                </Text>
        },
        {
            header: 'Pagado',
            field: 'liquidada',
            key: 'liquidada',
            body: (rowData: any) => (
                <Flex>
                    <Badge
                        color={rowData?.liquidada ? "success" : "fail"}
                        bg="transparent"
                        border="1px solid"
                        borderColor={rowData?.liquidada ? "success" : "fail"}
                        fontSize="10px"
                        fontWeight="500"
                        letterSpacing="0.2px"
                        textTransform="uppercase"
                        borderRadius="5px"
                        p="5px 10px"
                    >
                        {rowData?.liquidada ? "Si" : "No"}
                    </Badge>
                </Flex>
            )
        },
        {
            header: 'Empresa Factura',
            field: 'formacion',
            key: 'formacion',
            sortable: true,
            body: (rowData: any) => (
                <Flex>
                    <Badge
                        color="purewhite"
                        bg={rowData?.empresaFactura?.nombre === "Procademy" ? "secondary" : "#081338"}
                        fontSize="10px"
                        fontWeight="500"
                        letterSpacing="0.2px"
                        textTransform="uppercase"
                        borderRadius="5px"
                        p="5px 10px"
                    >
                        {rowData?.empresaFactura?.nombre}
                    </Badge>
                </Flex>
            )
        },
        {
            header: 'Importe total',
            field: 'importeTotal',
            key: 'importeTotal',
            body: (rowData: LiquidacionesInt) =>
                <Text
                    p="16px"
                    fontFamily="inter"
                    color="font"
                    fontSize="14px"
                    fontWeight="400"
                    fontStyle="normal"
                    lineHeight="150%"
                >
                    {rowData?.meta?.importeTotal || 0}€
                </Text>
        },
        {
            header: 'Fecha de pago',
            field: 'fechaPago',
            key: 'fechaPago',
            body: (rowData: LiquidacionesInt) =>
                <Flex
                    p="16px"
                >
                    <Text
                        fontSize="12px"
                        fontWeight="400"
                        color="font"
                    >
                        {rowData?.fechaPago ?
                            format({
                                date: rowData?.fechaPago,
                                customFormat: "dd MMM yyyy"
                            })
                            :
                            "--"
                        }
                    </Text>
                </Flex>
        },
    ]

    return columns
}