import { Flex, Icon, Text } from "@chakra-ui/react"
import { capitalizeFirst } from "../../../../shared/utils/functions/textTransform"
import { HiOutlineClipboard } from "react-icons/hi"
import { LeccionInt } from "../../../../interfaces/CursosInt"
import { ComponenteLeccionEnum, PreviewTipoEnum } from "../../../../shared/utils/types/LeccionTipoEnum";
import { PDFViewer } from "../PreviewItems/PDFViewer";
import { Video } from "../PreviewItems/Video";
import { IframeViewer } from "../PreviewItems/IframeViewer";
import { ImageViewer } from "../PreviewItems/ImageViewer";
import { MarkdownViewer } from "../PreviewItems/Markdown";

interface Props {
    leccion: LeccionInt;
    componentSubtype: ComponenteLeccionEnum | undefined;
}

export const EnunciadoTutor = ({
    leccion,
    componentSubtype
}: Props) => {
    return (
        <Flex
            p="40px"
            border="1px solid"
            borderColor="#E6E6EA"
            direction="column"
            rounded="20px"
            gap="25px"
            bg="purewhite"
            justifyContent="center"
        >
            <Flex
                direction="column"
                mb="6px"
            >
                <Text
                    color="font"
                    fontSize="24px"
                    fontStyle="normal"
                    fontWeight="700"
                >
                    {leccion?.nombre}
                </Text>

                <Flex
                    gap="10px"
                    alignItems="center"
                >
                    <Icon
                        as={HiOutlineClipboard}
                        color="#A5A8B3"
                        boxSize="20px"
                    />

                    <Text
                        color="#A5A8B3"
                        fontSize="15px"
                        fontWeight="400"
                        lineHeight="160%"
                        letterSpacing="0.075px"
                    >
                        Entregable
                    </Text>
                </Flex>
            </Flex>

            <Flex
                w="100%"
            >
                {(componentSubtype === ComponenteLeccionEnum.PDF_VIEWER && leccion?.contenido) &&
                    <PDFViewer
                        leccion={leccion}
                        tipo={PreviewTipoEnum.ENTREGA}
                    />
                }

                {(componentSubtype === ComponenteLeccionEnum.VIDEO && leccion?.contenido) &&
                    <Video
                        leccion={leccion}
                        tipo={PreviewTipoEnum.ENTREGA}
                    />
                }

                {(componentSubtype === ComponenteLeccionEnum.IFRAME && leccion?.contenido) &&
                    <IframeViewer
                        leccion={leccion}
                        tipo={PreviewTipoEnum.ENTREGA}
                    />
                }

                {(componentSubtype === ComponenteLeccionEnum.IMAGEN && leccion?.contenido) &&
                    <ImageViewer
                        leccion={leccion}
                        tipo={PreviewTipoEnum.ENTREGA}
                    />
                }

                {(componentSubtype === ComponenteLeccionEnum.MARKDOWN_PREVIEW && leccion?.contenido) &&
                    <MarkdownViewer
                        leccion={leccion}
                        tipo={PreviewTipoEnum.ENTREGA}
                    />
                }
            </Flex>
        </Flex>
    )
}