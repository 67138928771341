import { Flex, Icon, Text } from "@chakra-ui/react";
import MarkdownPreview from '@uiw/react-markdown-preview';
import { LeccionInt } from "../../../../interfaces/CursosInt";
import { HiOutlineDocumentText } from "react-icons/hi";
import { capitalizeFirst } from "../../../../shared/utils/functions/textTransform";
import { PreviewTipoEnum } from "../../../../shared/utils/types/LeccionTipoEnum";

interface Props {
    leccion: LeccionInt | null;
    tipo?: PreviewTipoEnum;
}

export const MarkdownViewer = ({
    leccion,
    tipo = PreviewTipoEnum.TEORIA
}: Props) => {
    return (
        <Flex
            p={tipo === PreviewTipoEnum.TEORIA ? "40px" : "0"}
            border={tipo === PreviewTipoEnum.TEORIA ? "1px solid" : "none"}
            w="100%"
            borderColor="#E6E6EA"
            direction="column"
            rounded="20px"
            gap="40px"
            bg="purewhite"
            justifyContent="center"
        >
            <Flex
                direction="column"
                display={tipo === PreviewTipoEnum.TEORIA ? "flex" : "none"}
            >
                <Text
                    color="font"
                    fontSize="24px"
                    fontStyle="normal"
                    fontWeight="700"
                >
                    {leccion?.nombre}
                </Text>

                <Flex
                    gap="10px"
                    alignItems="center"
                >
                    <Icon
                        as={HiOutlineDocumentText}
                        color="#A5A8B3"
                        boxSize="20px"
                    />

                    <Text
                        color="#A5A8B3"
                        fontSize="15px"
                        fontWeight="400"
                        lineHeight="160%"
                        letterSpacing="0.075px"
                    >
                        Lección
                    </Text>
                </Flex>
            </Flex>

            <Flex
                w="100%"
                alignItems={"start"}
                justifyContent={"center"}
                overflow="auto"
                className="scroll-bar"
                h={tipo === PreviewTipoEnum.TEORIA ? "calc(100vh - 375px)" : "auto"}
                maxH={tipo === PreviewTipoEnum.TEORIA ? "0" : "700px"}
            >
                <MarkdownPreview
                    source={leccion?.contenido || ''}
                    style={{
                        fontSize: '15px',
                        fontWeight: "400",
                        color: "#121625",
                        lineHeight: "140%",
                        width: '100%',
                        maxWidth: '100%',
                        backgroundColor: "transparent"
                    }}
                    wrapperElement={{
                        "data-color-mode": "light"
                    }}
                />
            </Flex>
        </Flex>
    );
};