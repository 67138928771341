import _axios from "../services/http.service";
import tenantInstance from "../services/tenantInstance.service";

export const addInscripcion = async ({ data, slug }: {
    data: {
        nombre: string | null;
        apellidos: string | null;
        email: string | null;
        telefono: string | null;
        dni: string | null;
    },
    slug: string;
}) => {
    const { tenant } = await tenantInstance();

    return await _axios.post(
        `${tenant}/public/inscripcion/${slug}`,
        data,
        {}
    );
};